<template>
  <el-tabs v-model="activeTab">
    <el-tab-pane
      class="settings-panel"
      v-for="permissionItem in filteredPermissionItems"
      :key="permissionItem.name"
      :name="permissionItem.name"
    >
      <span slot="label" class="tab-selector">
        {{ $t(permissionItem.title) }}
        <div v-if="hasErrors(permissionItem.name)" class="error-icon">
          <font-awesome-icon
            size="sm"
            :icon="['fal', 'exclamation-triangle']"
          />
        </div>
      </span>
      <component
        class="tab-body"
        :is="tabComponent(permissionItem.name)"
        :role-permissions="rolePermissions"
        :permission-item="permissionItem"
        :errors="errors"
        :company-preferences="companyPreferences"
        @update-role-permissions="handlePermissionsUpdate"
        @check-role-permissions="handleCheckUpdate"
      />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { mapGetters } from 'vuex';
import RoleDrawerAnalyticsTab from './RoleDrawerAnalyticsTab';
import RoleDrawerFeedbacksTab from './RoleDrawerFeedbacksTab';
import RoleDrawerKnowledgeBaseTab from './RoleDrawerKnowledgeBaseTab';
import RoleDrawerNotificationsTab from './RoleDrawerNotificationsTab';
import RoleDrawerSettingsTab from './RoleDrawerSettingsTab';
import RoleDrawerAcademyTab from './RoleDrawerAcademyTab';

export default {
  name: 'roles-drawer-main',
  components: {
    RoleDrawerAnalyticsTab,
    RoleDrawerFeedbacksTab,
    RoleDrawerKnowledgeBaseTab,
    RoleDrawerNotificationsTab,
    RoleDrawerSettingsTab,
    RoleDrawerAcademyTab,
  },
  props: {
    rolePermissions: {
      type: Array,
      default: () => [],
    },
    permissionItems: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    companyPreferences: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeTab: 'KnowledgeBase',
    };
  },
  computed: {
    ...mapGetters(['hasCompanyPreferenceWithValue']),
    filteredPermissionItems() {
      return this.permissionItems
        .filter((item) => {
          if (!item.companyPreferences) return true;
          return item.companyPreferences.every((preference) =>
            this.hasCompanyPreferenceWithValue(preference),
          );
        })
        .map((item) => {
          return {
            ...item,
            subItems: item.subItems.filter((subItem) => {
              if (!subItem.companyPreferences) return true;
              return subItem.companyPreferences.every((preference) =>
                this.hasCompanyPreferenceWithValue(preference),
              );
            }),
          };
        });
    },
  },
  methods: {
    tabComponent(name) {
      return `RoleDrawer${name}Tab`;
    },
    handleCheckUpdate({
      permissions,
      subItemPermissions,
      scope,
      isScopeToggled,
    }) {
      let newPermissions = this.removePermissions(
        this.rolePermissions,
        subItemPermissions,
      );
      newPermissions = this.addPermissions(
        newPermissions,
        permissions,
        scope,
        isScopeToggled,
      );
      this.$emit('update-focused-role', {
        key: 'customRolePermissions',
        value: newPermissions,
      });
    },
    handlePermissionsUpdate({ permissions, value, scope, isScopeToggled }) {
      const newPermissions = !value
        ? this.removePermissions(this.rolePermissions, permissions)
        : this.addPermissions(
            this.rolePermissions,
            permissions,
            scope,
            isScopeToggled,
          );
      this.$emit('update-focused-role', {
        key: 'customRolePermissions',
        value: newPermissions,
      });
    },
    removePermissions(rolePermissions, permissions) {
      return rolePermissions.filter(
        (rolePermission) =>
          !permissions.includes(rolePermission.rolePermission),
      );
    },
    addPermissions(
      rolePermissions,
      permissions,
      scope = {},
      isScopeToggled = false,
    ) {
      return this.removePermissions(rolePermissions, permissions).concat(
        permissions.map((permission) => ({
          rolePermission: permission,
          scope,
          isScopeToggled,
        })),
      );
    },
    hasErrors(tabName) {
      return this.errors[tabName] && Object.keys(this.errors[tabName]).length;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .el-tabs__item {
  color: $grey-5-mayday;
}
:deep() .el-tabs__item.is-active {
  color: $blue-mayday;
}

.tab-selector {
  display: flex;
  gap: 8px;
}

.error-icon {
  color: $red-mayday;
}

.tab-body {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
