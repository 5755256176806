<template>
  <el-card class="p-3 pt-2">
    <div>
      <h1>
        Notifications
        <span class="mx-3">|</span>
        {{ total }}
      </h1>
    </div>

    <p class="section-description">
      <small>{{ $t('notification-center.notif-page-subtitle') }}</small>
    </p>

    <div class="divider"></div>

    <div class="row my-2">
      <div class="col-8">
        <el-tag
          class="mr-2 mb-1"
          v-for="group in targetGroups"
          :key="group.id"
          >{{ group.name }}</el-tag
        >
      </div>
      <div class="col-4">
        <el-input
          :placeholder="
            this.$t('notification-center.notif-page-input-placeholder')
          "
          v-model="searchInput"
          @focus="handlePageChange(1)"
          @input="searchDebounce"
        ></el-input>
      </div>
    </div>

    <div v-if="total">
      <NotificationCenterMainRow
        :items="notifications"
        @select-details="$emit('select-details', $event)"
        @update-notification="$emit('update-notification', $event)"
        @delete-notification="$emit('delete-notification', $event)"
      />

      <el-pagination
        class="mt-4 d-flex justify-content-center"
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        hide-on-single-page
        :current-page="currentPage"
        @prev-click="handlePageChange"
        @next-click="handlePageChange"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>

    <div
      v-if="!total && searchInput.length"
      class="mt-4 d-flex justify-content-center"
    >
      <span
        >Aucun résultat pour la recherche :
        <strong>{{ searchInput }}</strong></span
      >
    </div>
  </el-card>
</template>

<script>
import debounce from 'lodash.debounce';
import NotificationCenterMainRow from './NotificationCenterMainRow';

export default {
  name: 'notification-center-main',
  components: {
    NotificationCenterMainRow,
  },
  props: {
    notifications: {
      type: Array,
      required: true,
    },
    targetGroups: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      searchInput: '',
      searchDebounce: debounce(
        () =>
          this.$emit('filters-update', {
            field: 'searchInput',
            value: this.searchInput,
          }),
        500,
      ),
    };
  },
  methods: {
    handlePageChange(newPage) {
      this.$emit('filters-update', { field: 'currentPage', value: newPage });
    },
  },
};
</script>

<style lang="scss" scoped>
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.section-description {
  color: $grey-5-mayday;
}
.divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
