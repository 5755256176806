export const PERMISSION_ITEMS = [
  {
    name: 'KnowledgeBase',
    title: 'settings.permissions.role-dropdown.knowledge-base.title',
    hasErrorState: true,
    subItems: [
      {
        name: 'Read',
        title: 'settings.permissions.role-dropdown.knowledge-base.read',
        switch: 'settings.permissions.role-drawer.knowledge-base.read-switch',
        description:
          'settings.permissions.role-drawer.knowledge-base.read-description',
        disabled: true,
        relatedPermissions: ['READ_CONTENT'],
      },
      {
        name: 'Edit',
        title: 'settings.permissions.role-dropdown.knowledge-base.edit',
        switch: 'settings.permissions.role-drawer.knowledge-base.edit-switch',
        description:
          'settings.permissions.role-drawer.knowledge-base.edit-description',
        error: 'settings.permissions.role-drawer.knowledge-base.edit-error',
        warning: 'settings.permissions.role-drawer.knowledge-base.edit-warning',
        type: 'Scoped',
        relatedPermissions: [
          'CREATE_CONTENT',
          'UPDATE_CONTENT',
          'DELETE_CONTENT',
        ],
      },
      {
        name: 'Verify',
        title: 'settings.permissions.role-dropdown.knowledge-base.verify',
        switch: 'settings.permissions.role-drawer.knowledge-base.verify-switch',
        description:
          'settings.permissions.role-drawer.knowledge-base.verify-description',
        type: 'Scoped',
        error: 'settings.permissions.role-drawer.knowledge-base.verify-error',
        warning:
          'settings.permissions.role-drawer.knowledge-base.verify-warning',
        relatedPermissions: ['VERIFY_CONTENT'],
      },
    ],
  },
  {
    name: 'Notifications',
    title: 'settings.permissions.role-dropdown.notifications.title',
    subItems: [
      {
        name: 'Manage',
        title: 'settings.permissions.role-dropdown.notifications.manage',
        switch: 'settings.permissions.role-drawer.notifications.manage-switch',
        description:
          'settings.permissions.role-drawer.notifications.manage-description',
        relatedPermissions: ['EDIT_AGENT_NOTIFICATION'],
      },
    ],
  },
  {
    name: 'Feedbacks',
    title: 'settings.permissions.role-dropdown.feedbacks.title',
    subItems: [
      {
        name: 'Receive',
        title: 'settings.permissions.role-dropdown.feedbacks.receive',
        switch: 'settings.permissions.role-drawer.feedbacks.receive-switch',
        description:
          'settings.permissions.role-drawer.feedbacks.receive-description',
        relatedPermissions: ['IS_REACHABLE'],
      },
      {
        name: 'Receive Global',
        title: 'settings.permissions.role-dropdown.feedbacks.receive-global',
        switch:
          'settings.permissions.role-drawer.feedbacks.receive-global-switch',
        description:
          'settings.permissions.role-drawer.feedbacks.receive-global-description',
        relatedPermissions: ['IS_GLOBALLY_REACHABLE'],
      },
      {
        name: 'Receive Academy',
        title: 'settings.permissions.role-dropdown.feedbacks.receive-academy',
        switch:
          'settings.permissions.role-drawer.feedbacks.receive-academy-switch',
        description:
          'settings.permissions.role-drawer.feedbacks.receive-academy-description',
        relatedPermissions: ['IS_ACADEMY_REACHABLE'],
      },
      {
        name: 'Receive Global Academy',
        title:
          'settings.permissions.role-dropdown.feedbacks.receive-global-academy',
        switch:
          'settings.permissions.role-drawer.feedbacks.receive-global-academy-switch',
        description:
          'settings.permissions.role-drawer.feedbacks.receive-global-academy-description',
        relatedPermissions: ['IS_ACADEMY_GLOBALLY_REACHABLE'],
      },
    ],
  },
  {
    name: 'Analytics',
    title: 'settings.permissions.role-dropdown.analytics.title',
    subItems: [
      {
        name: 'Read',
        title: 'settings.permissions.role-dropdown.analytics.read',
        switch: 'settings.permissions.role-drawer.analytics.read-switch',
        description:
          'settings.permissions.role-drawer.analytics.read-description',
        type: 'Scoped',
        relatedPermissions: ['READ_ANALYTICS_DASHBOARDS'],
      },
      {
        name: 'Download',
        title: 'settings.permissions.role-dropdown.analytics.download',
        switch: 'settings.permissions.role-drawer.analytics.download-switch',
        description:
          'settings.permissions.role-drawer.analytics.download-description',
        relatedPermissions: ['READ_ANALYTICS_EXPORTS'],
        companyPreferences: ['ANALYTICS_ENABLE_EXPORTS'],
      },
      {
        name: 'Read Academy',
        title: 'settings.permissions.role-dropdown.analytics.read-academy',
        switch:
          'settings.permissions.role-drawer.analytics.read-academy-switch',
        description:
          'settings.permissions.role-drawer.analytics.read-academy-description',
        relatedPermissions: ['READ_ACADEMY_DASHBOARDS'],
        companyPreferences: ['ALLOW_ACADEMY'],
      },
    ],
  },
  {
    name: 'Settings',
    title: 'settings.permissions.role-dropdown.settings.title',
    subItems: [
      {
        name: 'Manage',
        title: 'settings.permissions.role-dropdown.settings.manage',
        switch: 'settings.permissions.role-drawer.settings.manage-switch',
        description:
          'settings.permissions.role-drawer.settings.manage-description',
        relatedPermissions: ['EDIT_COMPANY_USERS'],
      },
    ],
  },
  {
    name: 'Academy',
    title: 'settings.permissions.role-dropdown.modules.title',
    companyPreferences: ['ALLOW_ACADEMY'],
    subItems: [
      {
        name: 'Read',
        title: 'settings.permissions.role-dropdown.modules.read',
        switch: 'settings.permissions.role-drawer.modules.read-switch',
        description:
          'settings.permissions.role-drawer.modules.read-description',
        relatedPermissions: ['READ_MODULE'],
        companyPreferences: ['ALLOW_ACADEMY'],
      },
      {
        name: 'Edit',
        title: 'settings.permissions.role-dropdown.modules.edit',
        switch: 'settings.permissions.role-drawer.modules.edit-switch',
        description:
          'settings.permissions.role-drawer.modules.edit-description',
        type: 'Multiple',
        relatedPermissions: ['CREATE_MODULE', 'UPDATE_MODULE', 'DELETE_MODULE'],
        companyPreferences: ['ALLOW_ACADEMY'],
      },
      {
        name: 'Assign',
        title: 'settings.permissions.role-dropdown.modules.assign',
        switch: 'settings.permissions.role-drawer.modules.assign-switch',
        description:
          'settings.permissions.role-drawer.modules.assign-description',
        relatedPermissions: ['ASSIGN_MODULE'],
        companyPreferences: ['ALLOW_ACADEMY'],
        // TEMP - HIDE FTM
        // type: 'Scoped',
        // scopedType: 'Group',
      },
      {
        name: 'Restrict',
        title: 'settings.permissions.role-dropdown.modules.restrict',
        switch: 'settings.permissions.role-drawer.modules.restrict-switch',
        description:
          'settings.permissions.role-drawer.modules.restrict-description',
        relatedPermissions: ['RESTRICT_MODULE'],
        companyPreferences: ['ALLOW_ACADEMY'],
        hidden: true,
      },
      {
        name: 'Present',
        title: 'settings.permissions.role-dropdown.modules.present',
        switch: 'settings.permissions.role-drawer.modules.present-switch',
        description:
          'settings.permissions.role-drawer.modules.present-description',
        relatedPermissions: ['PRESENT_MODULE'],
        companyPreferences: ['ALLOW_ACADEMY'],
        hidden: true,
      },
    ],
  },
];
