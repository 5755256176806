export const parseMentions = (body) => {
  if (!body) return [];
  const domParser = new DOMParser().parseFromString(body, 'text/html');

  const inlineMentions = domParser.querySelectorAll('.inline-mention');
  const resolveMentions = Array.from(inlineMentions).map((el) => {
    return {
      targetId: el.attributes['data-node-id'].value,
      targetType: el.attributes['data-node-type'].value,
    };
  });

  return [...new Set(resolveMentions)];
};
