<template>
  <div v-loading="companyTasksLoading">
    <div class="d-flex flex-row justify-content-between task-header sticky-top">
      <div class="routers d-flex flex-row">
        <div class="router-wrapper d-flex align-items-center">
          <router-link class="router-link" to="board">{{
            $t('task-view.board')
          }}</router-link>
          <el-select
            v-if="availableViews && availableViews.length > 0"
            :placeholder="$t('task-view.view-placeholder')"
            :value="selectedView"
            class="views-select"
            clearable
            size="small"
            @change="handleViewChange"
          >
            <el-option
              v-for="view in availableViews"
              :key="view.id"
              :label="view.name"
              :value="view.id"
            >
              {{ view.name }}
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center">
        <el-tag v-if="archiveDisplayed" class="warning-tag" type="danger">{{
          $t('task-view.filter.warning-archive')
        }}</el-tag>
      </div>
      <div class="options d-flex flex-row align-items-center">
        <div
          v-if="selectedTaskIds.length > 0"
          class="d-flex flex-row align-items-center ml-1"
        >
          <el-button
            class="empty-select"
            type="primary"
            plain
            @click="emptySelected"
          >
            {{ $t('task-view.empty-select') }}
          </el-button>
          <el-button
            v-if="archiveDisplayed"
            class="delete-select mr-2"
            type="primary"
            @click="restoreSelected"
          >
            {{
              $tc('task-view.restore-select', selectedTaskIds.length, {
                count: selectedTaskIds.length,
              })
            }}
          </el-button>
          <el-button
            v-else
            class="delete-select mr-2"
            type="primary"
            @click="deleteSelected"
          >
            {{
              $tc('task-view.delete-select', selectedTaskIds.length, {
                count: selectedTaskIds.length,
              })
            }}
          </el-button>
        </div>
        <filters-popover
          :filters="this.filters"
          :selected-filters="parsedRouteQuery"
          :loading="filtersLoading"
          @update-filter="updateFilter"
          @empty-filters-search="resetFilters"
        />
      </div>
    </div>

    <router-view
      @update-selected-tasks="handleUpdateSelectedTasks"
      @empty-filters="resetFilters"
      @fetch-next-page="handleNextPage"
      :selected-task-ids="selectedTaskIds"
    ></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import FiltersPopover from 'components/Filters/Popover.vue';
import debounce from 'lodash.debounce';

export default {
  name: 'task-view',
  data() {
    return {
      selectedTaskIds: [],
      filtersLoading: false,
      parsedFilters: {},
      availableViews: [],
      selectedView: null,
    };
  },
  components: {
    FiltersPopover,
  },
  async created() {
    await Promise.all([
      this.getAssignableUsers(['EDIT_KNOWLEDGE_BASE']),
      this.getCompanyUserLabelCategories(),
      this.getCustomRoles(),
      this.getKnowledges(),
      this.getContributors(),
      this.getSettingsKnowledges(),
      this.getCompanyTaskStatus(this.parsedRouteQuery),
    ]);
    this.availableViews = await this.getTaskViews();
    console.log(this.availableViews);
  },
  async beforeDestroy() {
    await this.clearTaskStore(false);
  },
  methods: {
    debouncedApplyFilters: debounce(async function () {
      await this.getCompanyTaskStatus(this.parsedRouteQuery);
    }, 500),
    handleUpdateSelectedTasks({ taskId, action }) {
      const copy = [...this.selectedTaskIds];

      if (action === 'add') {
        copy.push(taskId);
        this.selectedTaskIds = copy;
      } else {
        this.selectedTaskIds = copy.filter(
          (selectedId) => selectedId !== taskId,
        );
      }
    },
    deleteSelected() {
      this.bulkSetIsArchive({ ids: this.selectedTaskIds, isArchive: true });
      this.selectedTaskIds = [];
    },
    restoreSelected() {
      this.bulkSetIsArchive({ ids: this.selectedTaskIds, isArchive: false });
      this.selectedTaskIds = [];
    },
    emptySelected() {
      this.selectedTaskIds = [];
    },
    updateFilter(e) {
      const selectedFilters = { ...this.$route.query };
      this.emptySelected();
      selectedFilters[e.path] = e.values;

      // Convert all filter values to strings
      for (const key in selectedFilters) {
        if (Array.isArray(selectedFilters[key])) {
          if (selectedFilters[key].length > 0)
            selectedFilters[key] = selectedFilters[key].join(',');
          else delete selectedFilters[key];
        } else if (selectedFilters[key] === false) delete selectedFilters[key];
      }
      // Remove all query parameters
      this.$router.replace({
        query: null,
      });
      // Add the updated query parameters if there are any
      if (Object.keys(selectedFilters).length) {
        this.$router.replace({
          query: { ...selectedFilters },
        });
      }
      this.debouncedApplyFilters();
    },
    resetFilters() {
      this.$router.replace({ query: {} });
      this.selectedView = null;
      this.emptySelected();
      this.debouncedApplyFilters();
    },
    handleNextPage(e) {
      this.getCompanyTasksPage({
        statusId: e,
        filters: this.parsedRouteQuery,
      });
    },
    handleViewChange(e) {
      if (e) {
        this.selectedView = e;
        this.$router.replace({
          query: { viewId: e },
        });
      } else {
        this.selectedView = null;
        this.$router.replace({
          query: null,
        });
      }
      this.debouncedApplyFilters();
    },

    ...mapActions('taskModule', [
      'bulkSetIsArchive',
      'clearTaskStore',
      'getBacklogTasksCount',
      'getAssignableUsers',
      'getCompanyTaskStatus',
      'getCompanyTasksPage',
      'getTaskViews',
    ]),
    ...mapActions('adminModule', [
      'getSettingsKnowledges',
      'getCompanyUserLabelCategories',
      'getCustomRoles',
      'getContributors',
    ]),
    ...mapActions('knowledgeModule', ['getKnowledges']),
  },
  computed: {
    filters() {
      return {
        roleAssignee: {
          key: 'roleAssignee',
          path: 'customRoleIds',
          label: this.$t('task-view.filter.role'),
          options: this.roles.map((role) => ({
            label: role.name,
            value: role.id,
          })),
          type: 'Array',
          input: 'checkbox',
          icon: ['fal', 'medal'],
          searchable: true,
        },
        knowledge: {
          key: 'knowledge',
          path: 'knowledgeIds',
          label: this.$t('task-view.filter.knowledge'),
          options: this.adminKnowledges.map((knowledge) => ({
            label: knowledge.label,
            value: knowledge.id,
          })),
          type: 'Array',
          input: 'checkbox',
          icon: ['fal', 'folder'],
          searchable: true,
        },
        userAssignee: {
          key: 'userAssignee',
          path: 'assigneeIds',
          label: this.$t('task-view.filter.assignee'),
          options: this.assignableUsers.map((user) => ({
            label: user.username,
            value: user.id,
          })),
          type: 'Array',
          input: 'checkbox',
          icon: ['fal', 'user'],
          dataType: 'user',
          searchable: true,
        },
        initialReceiver: {
          key: 'initialReceiver',
          path: 'initialReceiverIds',
          label: this.$t('task-view.filter.initial-assignee'),
          options: [
            {
              label: this.$t('task-view.filter.assignee'),
              path: 'initialAssigneeIds',
              color: '#228df1',
              icon: 'user',
              type: 'Array',
              options: this.assignableUsers.map((user) => ({
                label: user.username,
                value: user.id,
              })),
              dataType: 'user',
            },
            {
              label: this.$t('task-view.filter.role'),
              path: 'initialRoleIds',
              type: 'Array',
              color: '#228df1',
              icon: 'medal',
              options: this.roles.map((role) => ({
                label: role.name,
                value: role.id,
              })),
              dataType: 'user',
            },
          ],
          type: 'Array',
          values: [],
          input: 'checkbox',
          icon: ['fal', 'user-clock'],
          searchable: true,
          grouped: true,
        },
        createdAt: {
          key: 'createdAt',
          path: 'createdAt',
          label: this.$t('task-view.filter.date'),
          options: [{ label: 'Start' }, { label: 'End' }],
          type: 'Int',
          values: [],
          input: 'date',
          icon: ['fal', 'calendar'],
        },
        archives: {
          key: 'archives',
          path: 'archived',
          label: this.$t('task-view.filter.archives'),
          options: [
            {
              label: this.$t('task-view.filter.display-archive'),
            },
          ],
          type: 'Boolean',
          input: 'switch',
          icon: ['fal', 'trash'],
        },
      };
    },
    parsedRouteQuery() {
      const authorizedFilters = [
        'customRoleIds',
        'knowledgeIds',
        'assigneeIds',
        'initialAssigneeIds',
        'initialRoleIds',
        'createdAt',
        'archived',
        'viewId',
      ];
      if (Object.keys(this.$route.query).length === 0) {
        return {};
      }
      let parsedQuery = {};
      for (let key in this.$route.query) {
        if (!authorizedFilters.includes(key)) continue;
        let value = this.$route.query[key];
        if (value == 'true') {
          parsedQuery[key] = true;
        } else if (value === 'false') {
          delete parsedQuery[key];
        } else if (typeof value === 'string') {
          parsedQuery[key] = value.split(',');
        } else {
          parsedQuery[key] = value;
        }
      }
      return parsedQuery;
    },
    archiveDisplayed() {
      return this.parsedRouteQuery.archived === true;
    },
    ...mapGetters('adminModule', ['roles', 'adminKnowledges']),
    ...mapGetters('taskModule', ['assignableUsers']),
    ...mapState('taskModule', ['companyTasksLoading']),
  },
};
</script>

<style lang="scss" scoped>
.task-header {
  background-color: white;
  padding: 15px;
  border-bottom: 2px solid $grey-4-mayday;
  overflow-x: auto;
  max-width: 100%;
}

.options-icon {
  color: $grey-5-mayday;
  width: 21px;
  height: 21px;
  &:hover {
    color: $blue-mayday;
    cursor: pointer;
  }
}

.routers {
  .router-wrapper {
    height: 34px;
  }
  .router-link-active {
    background-color: rgba($blue-mayday, 0.1);
    border-radius: 4px;
    color: $blue-mayday !important;
    padding: 5px;
  }
  .router-link {
    width: fit-content;
    height: 100%;
    color: $grey-4-mayday;
  }
}

:deep() .el-button--primary.is-plain {
  height: 34px;
  padding: 9px 15px;
}

:deep() .el-button--primary {
  height: 34px;
  padding: 9px 15px;
}

.warning-tag {
  height: 34px;
}

.views-select {
  width: 200px;
  height: 34px;
  margin-left: 12px;
  :deep(.el-input) {
    .el-input__inner {
      height: 34px;
    }
  }
}
</style>
