<template>
  <el-card class="sticky-top mt-2">
    <div class="row">
      <div class="col-10">
        {{ data.metadata.label }}
      </div>
      <div class="col-2">
        <font-awesome-icon
          @click="$emit('close')"
          class="closable-icon"
          :icon="['fal', 'times']"
        ></font-awesome-icon>
      </div>
    </div>

    <div>
      <span class="collapse-title-text">
        <font-awesome-icon
          :icon="['fa', 'calendar-day']"
          class="mr-1"
        ></font-awesome-icon>
        {{ applyDateParse(data.createdAt) }}
      </span>
    </div>
    <div>
      <span class="collapse-title-text">
        <font-awesome-icon
          :icon="['fa', 'user-edit']"
          class="mr-1"
        ></font-awesome-icon>
        {{ getUsername(data.authorId) }}
      </span>
    </div>

    <el-tabs v-model="activeTab" v-loading="isLoading">
      <el-tab-pane
        :label="
          $t('notification-center.notif-page-detail-read-OK', {
            readCount: readers.length,
          })
        "
        name="read"
        class="notification-center-details"
      >
        <div v-if="readers.length > 0">
          <div v-for="reader in readers" :key="reader.id">
            <div>
              <span class="details-title"
                ><small>{{ reader.username }}</small></span
              >
            </div>
            <span class="details-text-grey">
              <font-awesome-icon :icon="['fad', 'users']"></font-awesome-icon>
              {{ getGroupName(reader.companyGroupId) }}
            </span>
            <div class="detail-user-divider my-2"></div>
          </div>
        </div>
        <div v-else class="d-flex justify-content-center">
          <small>{{
            $t('notification-center.notif-page-detail-read-empty-state')
          }}</small>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :label="
          $t('notification-center.notif-page-detail-read-KO', {
            readCount: notReaders.length,
          })
        "
        name="unread"
        style=""
        class="notification-center-details"
      >
        <div v-if="notReaders.length > 0">
          <div v-for="user in notReaders" :key="user.id">
            <div>
              <span class="details-title"
                ><small>{{ user.username }}</small></span
              >
            </div>
            <span class="details-text-grey">
              <font-awesome-icon :icon="['fad', 'users']"></font-awesome-icon>
              {{ getGroupName(user.companyGroupId) }}
            </span>
            <div class="detail-user-divider my-2"></div>
          </div>
        </div>
        <div v-else class="d-flex justify-content-center">
          <small>{{
            $t('notification-center.notif-page-detail-read-empty-state-check')
          }}</small>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NotificationCenterDetails',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    unread: {
      type: Object,
      default: () => {
        return { users: [] };
      },
    },
  },
  created() {
    this.getReadersDetail();
  },
  computed: {
    ...mapGetters('notificationModule', ['getUsername']),
    ...mapGetters('adminModule', ['groups']),
  },
  data() {
    return {
      activeTab: 'read',
      isLoading: false,
      readers: [],
      notReaders: [],
    };
  },
  methods: {
    async getReadersDetail() {
      this.isLoading = true;
      const groupIds = this.data.groups.map((group) => group.id);
      const users = this.groups
        .filter((group) => groupIds.includes(group.id))
        .flatMap((group) =>
          group.users.map((user) => ({ ...user, companyGroupId: group.id })),
        );
      const { readers, notReaders } = users.reduce(
        (acc, user) => {
          const userRead = this.data.userReads.find(
            (userRead) => Number(userRead.userId) === Number(user.id),
          );
          if (userRead) {
            acc.readers.push({ ...user, createdAt: userRead.createdAt });
          } else {
            acc.notReaders.push(user);
          }
          return acc;
        },
        { readers: [], notReaders: [] },
      );
      this.readers = readers.sort((a, b) => a.createdAt > b.createdAt);
      this.notReaders = notReaders.sort((a, b) =>
        a.username.localeCompare(b.username, 'fr', { sensitivity: 'base' }),
      );
      this.isLoading = false;
    },
    applyDateParse(date) {
      let options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      let formatDate = new Date(date).toLocaleDateString(
        this.$i18n.locale,
        options,
      );
      return formatDate.charAt(0).toUpperCase() + formatDate.slice(1);
    },
    getGroupName(id) {
      const group = this.groups.find((g) => g.id == id);
      return group ? group.name : 'Pas de groupe';
    },
  },
  watch: {
    data() {
      this.getReadersDetail();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-tabs__nav {
  .el-tabs__active-bar {
    background-color: #32325d;
  }
  .is-active {
    font-weight: bold;
    color: #32325d;
  }
  .el-tabs__item:hover {
    color: #32325d;
  }
}
</style>

<style lang="scss" scoped>
.vertical-divider {
  border-left: 1px solid $grey-5-mayday;
}
.collapse-title-text {
  font-weight: bold;
  color: $grey-6-mayday;
  font-size: 12px;
}
.detail-user-divider {
  border-bottom: 1px solid $grey-4-mayday;
}
.closable-icon {
  cursor: pointer;
  color: $grey-4-mayday;
}
.closable-icon:hover {
  color: $grey-5-mayday;
}
.details-text-grey {
  color: $grey-5-mayday;
  font-size: 12px;
}
.details-title {
  font-weight: bold;
}
.notification-center-details {
  max-height: 60vh;
  overflow: auto;
}
</style>
