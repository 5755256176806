<template>
  <div
    class="editor-with-attachments w-100"
    @keydown.esc.exact="handleExitNewComment"
    @keydown.enter.exact.capture="handleNewComment"
  >
    <div class="editor-new-comment flex-row">
      <div class="input-editor w-100">
        <MessageEditor
          ref="message-editor"
          :body="body"
          :editable="editable"
          @input="body = $event"
        />
      </div>
      <div class="flex align-items-center">
        <button
          @click="insertMentions"
          class="editor-send-wrapper editor-send-wrapper__enable mr-1"
        >
          <font-awesome-icon
            :icon="['fas', 'at']"
            class="editor-send editor-send__enable"
          />
        </button>
        <AddAttachment
          v-if="showAttachments"
          :disabled="fileInputDisable"
          :attachment-loading="attachmentLoading"
          @new-attachment="handleFileChange"
          @disable-click="
            showDisableFileUpload(
              'task-view.task-focus-modal.upload-error.disable-file-upload',
            )
          "
          ref="add-attachment"
          class="editor-send-wrapper mr-1"
        >
          <template v-slot:slot-button>
            <div
              class="editor-send-wrapper"
              :class="
                attachmentLoading
                  ? 'editor-send-wrapper__disable'
                  : 'editor-send-wrapper__enable'
              "
            >
              <font-awesome-icon
                :icon="['fas', 'paperclip']"
                class="editor-send"
                :class="
                  attachmentLoading
                    ? 'editor-send__disable'
                    : 'editor-send__enable'
                "
              ></font-awesome-icon>
            </div>
          </template>
        </AddAttachment>

        <div
          class="editor-send-wrapper"
          :class="
            isSendable
              ? 'editor-send-wrapper__enable'
              : 'editor-send-wrapper__disable'
          "
        >
          <font-awesome-icon
            :icon="['fas', 'paper-plane']"
            class="editor-send"
            :class="isSendable ? 'editor-send__enable' : 'editor-send__disable'"
            @click="handleNewComment"
          ></font-awesome-icon>
        </div>
      </div>
    </div>
    <AttachmentList
      :attachments="attachments"
      :isInput="true"
      :displayDisableFileUpload="displayDisableFileUpload"
      :errorText="errorText"
      @disable-click="showDisableFileUpload()"
      @delete-attachment="handleDeleteAttachment"
    ></AttachmentList>
  </div>
</template>
<script>
import AddAttachment from '@/components/Attachments/AddAttachment';
import AttachmentList from '@/components/Attachments/AttachmentList';
import AttachmentsMixin from '@/mixins/AttachmentsMixin';
import MessageEditor from '@/components/Editors/MessageEditor';
import { parseMentions } from 'utils/parseMentions';

export default {
  name: 'input-editor',
  components: {
    AddAttachment,
    AttachmentList,
    MessageEditor,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    showAttachments: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: '',
      options: {
        fontOptions: true,
        structurationOptions: true,
        mentionOptions: true,
      },
      body: '',
      showEditor: true,
    };
  },
  mixins: [AttachmentsMixin()],
  computed: {
    attachmentsSendable() {
      let sendable = true;
      if (this.attachments.length) {
        sendable = this.attachments
          .map((at) => !at.error || !at.loading)
          .every((el) => el);
      }
      return sendable && !this.attachmentLoading;
    },
    isSendable() {
      if (this.attachments.length && this.attachmentsSendable) return true;
      if (!this.attachments.length && this.body !== '') return true;
      return false;
    },
  },
  methods: {
    insertMentions() {
      const maydayEditor = this.$refs['message-editor'];
      if (maydayEditor) return maydayEditor.insertMentions();
    },
    handleNewComment() {
      if (this.attachmentLoading) return;
      if (this.body != '' || this.attachments.length > 0) {
        this.$emit('handle-new-comment', {
          comment: this.body,
          ...(this.showAttachments
            ? { attachments: this.attachments.map((at) => at.url) }
            : {}),
          mentions: parseMentions(this.body),
        });
        this.body = '';
      }
      this.EditNewComment = false;
    },
    handleExitNewComment() {
      this.body = '';
    },
  },
  watch: {
    editable() {
      this.editor.setOptions({ editable: this.editable });
    },
    reset() {
      this.setEditorContent('');
    },
  },
};
</script>
<style lang="scss" scoped>
:deep() p {
  font-size: 14px;
  margin: 0 !important;
}
.input-editor {
  padding: 0;
  :deep() .velmld-parent {
    position: unset !important;
    width: 100%;
  }

  :deep() .mayday-editor {
    padding: 0px;
  }
}

:deep() .mayday-editor__drag-icon-container {
  z-index: 9999;
}
.editor-new-comment {
  min-height: 24px;
  width: 100%;
  border: 0.5px solid $grey-4-mayday;
  background-color: $grey-1-mayday;
  border-radius: 2px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  padding: 4px 2px 4px 8px;
  justify-content: space-between;
}

.editor-send-wrapper {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &__enable:hover {
    background-color: rgba($blue-mayday, 0.1);
    cursor: pointer;
  }
  &__disable:hover {
    cursor: not-allowed;
  }
}

.editor-send-wrapper:hover {
  .editor-send__enable {
    color: $blue-mayday;
  }
}

.editor-send {
  color: $grey-5-mayday;
  width: 12px;
  height: 12px;
}

.disable-file-upload-message {
  font-size: 9px;
  color: $red-mayday;
}

button {
  background: transparent;
  border: none;
  outline: none;
}
</style>
