<template>
  <div>
    <component
      v-for="subItem in permissionItem.subItems"
      :is="switchComponent(subItem.type)"
      :key="subItem.title"
      :sub-item="subItem"
      :is-opened="isOpened(subItem.relatedPermissions)"
      @switch-change="$emit('update-role-permissions', $event)"
    />
  </div>
</template>

<script>
import RoleDrawerSimpleSwitch from './RoleDrawerSimpleSwitch';
import RoleDrawerScopedSwitch from './RoleDrawerScopedSwitch';

export default {
  name: 'role-drawer-notifications-tab',
  components: {
    RoleDrawerSimpleSwitch,
    RoleDrawerScopedSwitch,
  },
  props: {
    rolePermissions: {
      type: Array,
      default: () => [],
    },
    permissionItem: {
      type: Object,
      required: true,
    },
  },
  methods: {
    switchComponent(type) {
      return `RoleDrawer${type || 'Simple'}Switch`;
    },
    isOpened(relatedPermissions) {
      return this.rolePermissions.some((permission) =>
        relatedPermissions.includes(permission.rolePermission),
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
