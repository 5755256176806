<template>
  <div v-loading="!accessType || loading">
    <DrawerRowTitle
      v-if="!knowledgeIsPublic"
      :title="$t('knowledge.drawers.content-view-setting.settings.radio.title')"
      :tooltip="
        $t('knowledge.drawers.content-view-setting.settings.radio.tooltip')
      "
    />
    <ContentViewSettingsCallout
      v-if="!isPublished && (accessType === 'PUBLIC' || knowledgeIsPublic)"
      trad="knowledge.drawers.content-view-setting.settings.radio"
      icon="exclamation-triangle"
      type="warning"
    />
    <ContentViewSettingsCallout
      v-if="knowledgeIsPublic"
      trad="knowledge.drawers.content-view-setting.settings.radio"
      icon="lightbulb"
      type="info"
    />
    <div v-if="!knowledgeIsPublic" class="overflow-hidden">
      <not-allowed-tooltip
        :user-permissions="userPermissions"
        permission-key="canUpdateContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <el-radio-group v-model="radio" @change="handleAccessType">
            <ContentViewSettingsRadio
              name="PRIVATE"
              :checked="accessType"
              icon="lock-alt"
              :is-published="isPublished"
              :disable-tooltip="true"
              :has-permissions="hasPermissions"
            />
            <ContentViewSettingsRadio
              v-if="FOR_LATER && companyHostname"
              name="LINK"
              :checked="accessType"
              icon="key"
              :is-published="isPublished"
              :has-permissions="hasPermissions"
            />

            <ContentViewSettingsRadio
              v-if="companyHostname"
              name="PUBLIC"
              :checked="accessType"
              icon="globe-americas"
              :is-published="isPublished"
              :has-permissions="hasPermissions"
            />
          </el-radio-group>
        </template>
      </not-allowed-tooltip>
    </div>

    <ContentViewSettingsEmbedDesk />

    <ContentViewSettingsGroups
      v-if="accessType === 'PRIVATE' && !knowledgeIsPublic"
      :group-ids="groupIds"
      :possible-user-groups="userGroups"
      :access="isGranularAccessRestrictions"
      @update-group-ids="$emit('update-group-ids', $event)"
      class="mb-2"
    />

    <ContentViewSettingsLink
      v-if="accessType === 'LINK'"
      :publicData="localPublicData"
      @update-public-description="updatePublicDescription"
      @add-token="addToken"
      @revoke-token="revokeToken"
    />

    <ContentViewSettingsImageCover
      v-if="accessType === 'PRIVATE' && !knowledgeIsPublic"
      :is-public="false"
      :cover="cover"
      :userPermissions="userPermissions"
      @open-cover-modal="$emit('open-cover-modal', $event)"
      @delete-cover="$emit('delete-cover', $event)"
    />

    <ContentViewSettingsPublic
      v-if="accessType === 'PUBLIC' || knowledgeIsPublic"
      :knowledgeIsPublic="knowledgeIsPublic"
      :publicData="localPublicData"
      :cover="cover"
      :user-permissions="userPermissions"
      @update-public-description="updatePublicDescription"
      @open-cover-modal="$emit('open-cover-modal')"
      @delete-cover="$emit('delete-cover')"
    />
    <ContentViewSettingsCompanyFavorite
      v-if="hasCompanyFavoritePreference"
      :isCompanyFavorite="isCompanyFavorite"
      :isLoading="companyFavoriteLoading"
      :user-permissions="userPermissions"
      @update-company-favorite="$emit('update-company-favorite', $event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ContentViewSettingsGroups from '../ContentViewSettingsComponents/ContentViewSettingsGroups';
import ContentViewSettingsPublic from '../ContentViewSettingsComponents/ContentViewSettingsPublic';
import ContentViewSettingsLink from '../ContentViewSettingsComponents/ContentViewSettingsLink';
import ContentViewSettingsRadio from '../ContentViewSettingsComponents/ContentViewSettingsRadio';
import ContentViewSettingsCallout from '../ContentViewSettingsComponents/ContentViewSettingsCallout';
import ContentViewSettingsEmbedDesk from '../ContentViewSettingsComponents/ContentViewSettingsEmbedDesk';
import ContentViewSettingsCompanyFavorite from '../ContentViewSettingsComponents/ContentViewSettingsCompanyFavorite';
import DrawerRowTitle from '@/components/Drawers/Commons/DrawerRowTitle';
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';
import ContentViewSettingsImageCover from '@/components/Drawers/ContentViewSettingDrawer/ContentViewSettingsComponents/ContentViewSettingsImageCover';

export default {
  name: 'content-view-setting-access',
  components: {
    ContentViewSettingsGroups,
    ContentViewSettingsPublic,
    ContentViewSettingsLink,
    ContentViewSettingsRadio,
    ContentViewSettingsCallout,
    ContentViewSettingsEmbedDesk,
    ContentViewSettingsCompanyFavorite,
    DrawerRowTitle,
    NotAllowedTooltip,
    ContentViewSettingsImageCover,
  },
  props: {
    groupIds: {
      type: Array,
      required: false,
    },
    userGroups: {
      type: Array,
      required: false,
    },
    publicData: {
      type: Object,
      required: false,
    },
    isPublished: {
      type: Boolean,
      required: true,
    },
    cover: {
      type: String,
      required: false,
    },
    knowledgeIsPublic: {
      type: Boolean,
      default: false,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
    isCompanyFavorite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accessType: '',
      loading: false,
      radio: '',
      localPublicData: null,
      FOR_LATER: false,
    };
  },
  mounted() {
    this.initContentAccess();
  },
  computed: {
    hasCompanyFavoritePreference() {
      return (
        this.companyAllowCompanyFavoritesPreference &&
        !!this.companyAllowCompanyFavoritesPreference.type
      );
    },
    ...mapGetters([
      'isGranularAccessRestrictions',
      'companyAllowCompanyFavoritesPreference',
    ]),
    ...mapGetters('adminModule', ['companyHostname']),
    ...mapGetters('adminUserDirectoryModule', ['companyFavoriteLoading']),
  },
  methods: {
    initContentAccess() {
      this.localPublicData = this.publicData;
      if (this.publicData.isShareable) {
        this.accessType = 'PUBLIC';
      } else {
        this.accessType = 'PRIVATE';
      }
    },
    async handleAccessType(accessType) {
      if (accessType === this.accessType) return;

      this.loading = true;
      const content = await this.setContentAccess(accessType);

      if (!content) return this.handleError();
      this.accessType = accessType;

      this.$message({
        message: this.$t(
          'knowledge.drawers.content-view-setting.settings.public.success',
        ),
        type: 'success',
      });
      this.localPublicData = content.publicData;
      this.loading = false;
    },
    async updatePublicDescription({ description,  metaLabel}) {
      this.loading = true;

      const content = await this.setPublicDescription({description, metaLabel});

      if (!content) return this.handleError();

      this.localPublicData = content.publicData;
      this.loading = false;
    },
    async addToken(payload) {
      this.loading = true;
      const { name, expirationDate } = payload;
      const content = await this.addAccessToken({
        name,
        expirationDate,
      });
      if (!content) return this.handleError();

      this.localPublicData = content.publicData;
      this.loading = false;
    },
    async revokeToken(payload) {
      this.loading = true;
      const { accessToken } = payload;
      const content = await this.revokeAccessToken({
        accessToken,
      });
      if (!content) return this.handleError();
      this.localPublicData = content.publicData;
      this.loading = false;
    },
    handleError() {
      return this.$message({
        message: this.$t(
          'knowledge.drawers.content-view-setting.settings.access.error',
        ),
        type: 'error',
      });
    },
    ...mapActions('knowledgeModule', [
      'setContentAccess',
      'setPublicDescription',
      'addAccessToken',
      'revokeAccessToken',
    ]),
  },
};
</script>

<style lang="scss" scoped></style>
