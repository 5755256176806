<template>
  <div class="d-flex flex-column">
    <task-focus-details-row
      v-if="currentTaskAttributes.type"
      :title="$t('task-view.task-focus-modal.details.type')"
      class="detail-row pb-1"
      @click.native="displayPublicIdInURL(task.publicId)"
    >
      <template slot="detail-row-content">
        <div class="d-flex flex-row align-items-center detail-row-content">
          <div class="d-flex flex-row align-items-center">
            <div
              :class="[
                'd-flex align-items-center justify-content-center',
                `type-icon-${currentTaskTypeConfiguration.classTag}-wrapper`,
              ]"
            >
              <font-awesome-icon
                :icon="['fad', taskIcon]"
                :class="[`type-icon-${currentTaskTypeConfiguration.classTag}`]"
              />
            </div>
            <div class="detail-content">
              {{ displayedType }}
            </div>
          </div>
        </div></template
      ></task-focus-details-row
    >

    <task-focus-details-row
      v-if="currentTaskAttributes.status"
      :title="$t('task-view.task-focus-modal.details.status')"
      class="detail-row"
    >
      <template slot="detail-row-content">
        <el-select
          class="label-select"
          v-model="statusInput"
          :placeholder="status.name"
          size="mini"
          @change="
            $emit('handle-update-status', {
              $event,
              taskId: task.id,
              oldStatusId: status.id,
            })
          "
        >
          <el-option
            v-for="item in getOrderedCompanyTaskStatus"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            size="mini"
          ></el-option> </el-select></template
    ></task-focus-details-row>

    <task-focus-details-row
      v-if="currentTaskAttributes.date"
      :title="$t('task-view.task-focus-modal.details.date')"
      class="detail-row"
    >
      <template slot="detail-row-content">
        <div class="d-flex flex-row align-items-center detail-row-content">
          <font-awesome-icon
            :icon="['fal', 'calendar-week']"
            class="icon-details mr-2"
          />
          <div class="detail-content">
            {{ task.createdAt }}
          </div>
        </div>
      </template></task-focus-details-row
    >

    <task-focus-details-row
      v-if="currentTaskAttributes.author"
      :title="$t('task-view.task-focus-modal.details.author')"
      class="detail-row"
    >
      <template slot="detail-row-content">
        <div class="d-flex flex-row align-items-center detail-row-content">
          <img
            v-if="isInfo || isObsolete"
            src="@/assets/mayday-logo.png"
            alt="mayday-logo-rounded"
            class="logo-admin mr-2 mb-1"
          />
          <user-avatar
            v-else
            :username="authorUsername"
            size="xsmall"
            class="mr-2"
          ></user-avatar>
          <el-tooltip :content="bodyAuthorName" effect="light">
            <div class="detail-content author">
              {{ bodyAuthorName }}
            </div>
          </el-tooltip>
        </div>
      </template></task-focus-details-row
    >
    <task-focus-details-row
      v-if="currentTaskAttributes.group"
      :title="$t('task-view.task-focus-modal.details.group')"
      class="detail-row"
    >
      <template slot="detail-row-content">
        <div class="d-flex flex-row align-items-center detail-row-content">
          <font-awesome-icon
            :icon="['fad', 'users']"
            class="icon-details mr-2"
          />
          <el-tooltip
            :content="
              authorHasGroup
                ? task.author.groups[0].name
                : $t('task-view.task-focus-modal.empty-group')
            "
            effect="light"
          >
            <div
              :class="
                authorHasGroup ? 'detail-content group' : 'details-empty-state'
              "
            >
              {{
                authorHasGroup
                  ? task.author.groups[0].name
                  : $t('task-view.task-focus-modal.empty-group')
              }}
            </div>
          </el-tooltip>
        </div>
      </template></task-focus-details-row
    >
    <task-focus-details-row
      v-if="authorHasLabels && currentTaskAttributes.labels"
      :title="$t('task-view.task-focus-modal.details.label')"
      class="detail-row"
    >
      <template v-if="!isInfo && !isObsolete" slot="detail-row-content">
        <div class="d-flex flex-row align-items-center detail-row-content">
          <div
            :class="
              authorHasLabels ? 'detail-content labels' : 'details-empty-state'
            "
          >
            <div v-if="!authorHasLabels">
              <font-awesome-icon
                :icon="['fad', 'tags']"
                class="icon-details mr-2"
              />
              {{ $t('task-view.task-focus-modal.empty-label') }}
            </div>
            <div v-else class="pb-1 d-flex flex-row align-items-center">
              <LabelTag
                class="label-tag mr-1"
                :key="task.author.labels[0].id"
                :name="task.author.labels[0].name"
                :color="
                  categorySettingsLookup[
                    task.author.labels[0].companyUserLabelCategoryId
                  ].color
                "
                :icon="
                  categorySettingsLookup[
                    task.author.labels[0].companyUserLabelCategoryId
                  ].icon
                "
              />
              <el-tooltip effect="light">
                <template slot="content">
                  <LabelTag
                    class="label-tag-tooltip mr-1"
                    v-for="label in task.author.labels"
                    :key="label.id"
                    :name="label.name"
                    :color="
                      categorySettingsLookup[label.companyUserLabelCategoryId]
                        .color
                    "
                    :icon="
                      categorySettingsLookup[label.companyUserLabelCategoryId]
                        .icon
                    "
                  />
                </template>
                <div
                  v-if="task.author.labels.length > 1"
                  class="d-flex flex-row align-items-center label-count-wrapper"
                >
                  <div class="label-count">
                    + {{ task.author.labels.length - 1 }}
                  </div>
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </template></task-focus-details-row
    >
    <task-focus-details-row
      v-if="currentTaskAttributes.assignee"
      :title="$t('task-view.task-focus-modal.details.assignee')"
      class="detail-row"
    >
      <template slot="detail-row-content">
        <div class="d-flex flex-row align-items-center detail-row-content">
          <div class="">
            <el-select
              class="assignee-select"
              v-model="assignee"
              :placeholder="
                task.assignee
                  ? task.assignee.username
                  : $t('task-view.task-focus-modal.empty-assignee')
              "
              size="mini"
              @change="handleUpdateAssignee($event, task.id)"
            >
              <el-option
                v-for="item in assignableUsers"
                :key="item.id"
                :label="item.username"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div> </template
    ></task-focus-details-row>
    <task-focus-details-row
      v-if="task.initialReceiver"
      :title="$t('task-view.task-focus-modal.details.initial-assignee')"
      class="detail-row"
    >
      <template slot="detail-row-content">
        <div class="d-flex flex-row align-items-center detail-row-content">
          <font-awesome-icon
            v-if="task.initialReceiver.id === 'null'"
            :icon="['fal', 'user-slash']"
            class="icon-details mr-2"
          />
          <user-avatar
            v-else-if="task.initialReceiver.username"
            :username="task.initialReceiver.username"
            size="xsmall"
            class="mr-2"
          ></user-avatar>
          <font-awesome-icon
            v-else
            :icon="['fad', 'users']"
            class="icon-details mr-2"
          />
          <el-tooltip :content="initialReceiverName" effect="light">
            <div class="detail-content author">
              {{ initialReceiverName }}
            </div>
          </el-tooltip>
        </div>
      </template></task-focus-details-row
    >
    <task-focus-details-row
      v-if="currentTaskAttributes.source"
      :title="$t('task-view.task-focus-modal.details.source')"
      class="detail-row"
    >
      <template slot="detail-row-content">
        <div class="d-flex flex-row align-items-center detail-row-content">
          <font-awesome-icon
            :icon="['fad', 'users']"
            class="icon-details mr-2"
          />
          <div
            :class="
              taskHasSource ? 'detail-content lowercase' : 'details-empty-state'
            "
          >
            {{
              taskHasSource
                ? source
                : $t('task-view.task-focus-modal.empty-source')
            }}
          </div>
        </div>
      </template></task-focus-details-row
    >
    <task-focus-details-row
      v-if="currentTaskAttributes.linkedContent"
      :title="$t('task-view.task-focus-modal.details.linked-content')"
      class="detail-row pb-1"
    >
      <template slot="detail-row-content">
        <div class="d-flex flex-row align-items-center detail-row-content">
          <font-awesome-icon
            :icon="['fal', 'link']"
            class="icon-details mr-2"
          />
          <div v-if="hasLinkedContent">
            <el-button
              class="consult-button"
              size="mini"
              type="primary"
              plain
              @click.stop="handleRedirect"
              >{{ $t('task-view.task-focus-modal.consult') }}
            </el-button>
          </div>
          <div v-else class="details-empty-state">
            {{ $t('task-view.task-focus-modal.empty-link') }}
          </div>
        </div>
      </template></task-focus-details-row
    >
    <task-focus-details-row
      v-if="currentTaskAttributes.trustContent && !task.isArchive"
      :title="$t('task-view.task-focus-modal.details.trustContent')"
      class="detail-row pb-1"
    >
      <template slot="detail-row-content">
        <div class="d-flex flex-row align-items-center detail-row-content">
          <font-awesome-icon
            :icon="['fal', 'times-circle']"
            class="icon-details mr-2"
          />
          <el-popconfirm
            class="popconfirm-verify"
            :confirm-button-text="$t('notification-center.actions.confirm')"
            confirm-button-type="plain"
            :cancel-button-text="$t('notification-center.actions.cancel')"
            :title="$t('task-view.task-focus-modal.details.trust-confirm')"
            @confirm="verifyContent"
          >
            <el-button
              slot="reference"
              class="verify-button"
              size="mini"
              type="danger"
              plain
            >
              {{ $t('task-view.task-focus-modal.details.trust-button') }}
            </el-button>
          </el-popconfirm>
        </div>
      </template></task-focus-details-row
    >
    <task-focus-details-row
      v-if="currentTaskAttributes.reaction"
      :title="ratingLabel"
      class="detail-row"
      :loading="ratingLoading"
    >
      <template slot="detail-row-content">
        <div class="d-flex flex-row align-items-center">
          <div>
            <RatingInput
              :value="task.rating ? task.rating.currentUserRating : 0"
              @update-rating="handleUpdateRating"
              @delete-rating="handleDeleteRating"
            />
          </div>
        </div>
      </template>
    </task-focus-details-row>
    <task-focus-details-row
      v-if="currentTaskAttributes.reaction"
      :title="$t('task-view.task-focus-modal.details.reactions')"
      class="detail-row"
    >
      <template slot="detail-row-content">
        <el-tooltip>
          <template slot="content">
            <div v-if="taskHasUpvotes">
              <div v-for="upvoter in task.upvotes" :key="upvoter.id">
                {{ upvoter.username }}
              </div>
            </div>
            <div v-else>
              {{ $t('task-view.empty-upvote-tooltip') }}
            </div>
          </template>
          <div class="d-flex flex-row align-items-center detail-row-content">
            <div class="d-flex flex-row align-items-center">
              <font-awesome-icon
                :icon="[taskHasUpvotes ? 'fas' : 'fal', 'heart']"
                :class="[
                  taskHasUpvotes ? 'with-reactions' : 'without-reaction',
                  'icon-details upvote-icon mr-2',
                ]"
                @click="handleUpvote"
              />
              <div v-if="taskHasUpvotes" class="detail-content">
                {{ task.upvoteCount }}
              </div>
            </div>
          </div>
        </el-tooltip>
      </template></task-focus-details-row
    >
  </div>
</template>

<script>
import LabelTag from '../../../components/Settings/LabelTag';
import TaskFocusDetailsRow from './TaskFocusDetailsRow.vue';
import UserAvatar from '../../../components/UserAvatar.vue';
import { taskTypeConfiguration, getTaskAttributes } from './taskTypeDisplay';

import {
  mapGetters,
  mapState,
  createNamespacedHelpers,
  mapActions,
} from 'vuex';
import RatingInput from 'components/RatingInput/RatingInput.vue';
const { mapGetters: adminMapGetters } = createNamespacedHelpers('adminModule');

export default {
  name: 'task-focus-details',
  components: {
    RatingInput,
    LabelTag,
    TaskFocusDetailsRow,
    UserAvatar,
  },
  props: {
    task: Object,
  },
  data() {
    return {
      statusInput: null,
      assignee: null,
      rating: null,
      ratingLoading: false,
    };
  },
  async created() {},
  methods: {
    displayPublicIdInURL(publicId) {
      this.$router.replace({
        query: {
          modalTaskId: this.$route.query.modalTaskId,
          publicId,
        },
      });
    },
    async handleRedirect() {
      const { entityId, entityType, lang, knowledgeId } = this.task;

      if (entityType === 'Slide' || entityType === 'PublishedModule')
        this.goToTranslatedAcademyEntity({
          slideId: entityId,
          publishedModuleId: knowledgeId,
          entityType,
          lang,
        });
      else
        this.goToTranslatedEntity({
          entityId,
          entityType,
          lang,
          query: { from: 'hub-modal' },
          open: true,
        });
    },
    verifyContent() {
      this.$emit('update-content-status', {
        contentIds: [this.task.entityId],
        key: 'isOutdated',
        value: false,
      });
      this.$emit('handle-is-archive', {
        taskId: this.task.id,
        isArchive: true,
      });
      this.$emit('close');
    },
    handleUpdateAssignee(e, taskId) {
      const assignee = this.assignableUsers.find((el) => el.id === e);
      this.$emit('handle-update-assignee', {
        taskId,
        statusId: this.status.id,
        assignee,
      });
    },
    ...mapActions('knowledgeModule', ['goToTranslatedEntity']),
    ...mapActions('academyModule', ['goToTranslatedAcademyEntity']),
    handleUpvote() {
      if (this.upvotersId.includes(this.userId))
        this.$emit('remove-upvote', {
          taskId: this.task.id,
          statusId: this.status.id,
        });
      else
        this.$emit('add-upvote', {
          taskId: this.task.id,
          statusId: this.status.id,
        });
    },
    handleUpdateRating(rating) {
      this.ratingLoading = true;
      this.$emit('update-rating', {
        taskId: this.task.id,
        statusId: this.status.id,
        rating,
      });
    },
    handleDeleteRating() {
      this.ratingLoading = true;
      this.$emit('delete-rating', {
        taskId: this.task.id,
        statusId: this.status.id,
      });
    },
  },

  computed: {
    status() {
      return this.getOrderedCompanyTaskStatus.find(
        (el) => el.id === this.task.companyTaskStatusId,
      );
    },
    authorUsername() {
      const authorHasUsername = this.task.author && this.task.author.username;
      if (!authorHasUsername) return this.$t('task-view.deleted-user');
      if (this.task.author.trad) return this.$t(this.task.author.username);

      return this.task.author.username;
    },
    authorHasUsername() {
      return this.task.author && this.task.author.username;
    },
    bodyAuthorName() {
      if (this.isInfo || this.isObsolete) return this.$t('task-view.admin');
      return this.authorUsername;
    },
    authorHasGroup() {
      if (this.task.author && this.task.author.groups.length > 0) return true;
      return false;
    },
    authorHasLabels() {
      if (this.task.author && this.task.author.labels.length > 0) return true;
      return false;
    },
    taskHasSource() {
      if (this.task.source) return true;
      return false;
    },
    taskHasUpvotes() {
      return this.task.upvoteCount > 0;
    },
    upvotersId() {
      return this.task.upvotes.map((upvoter) => upvoter.id);
    },
    source() {
      return this.task.source.replaceAll('_', ' ').toLowerCase();
    },
    isInfo() {
      return this.task.type === 'INFORMATION';
    },
    isObsolete() {
      return this.task.type === 'CONTENT_OBSOLETE';
    },
    currentTaskTypeConfiguration() {
      return taskTypeConfiguration[this.task.type];
    },
    currentTaskAttributes() {
      return getTaskAttributes(this.task.type);
    },
    initialReceiverName() {
      if (this.task.initialReceiver.id !== 'null') {
        return (
          this.task.initialReceiver.username || this.task.initialReceiver.name
        );
      }
      return this.$t('task-view.deleted-user');
    },
    ratingLabel() {
      return `${this.$t('task-view.task-focus-modal.details.rating.title')} ${
        this.task.rating ? this.task.rating.average + '/5' : ''
      }`;
    },
    hasLinkedContent() {
      return this.task.entityId || this.task.knowledgeId;
    },
    isCustomFeedback() {
      return this.task.type === 'CUSTOM_FEEDBACK';
    },
    taskIcon() {
      return this.isCustomFeedback
        ? this.task.customIcon
        : this.currentTaskTypeConfiguration.icon;
    },
    displayedType() {
      return this.isCustomFeedback
        ? this.task.customName
        : this.$t(this.currentTaskTypeConfiguration.displayedType);
    },
    ...mapGetters('taskModule', ['getOrderedCompanyTaskStatus']),
    ...adminMapGetters(['categorySettingsLookup']),
    ...mapState('taskModule', ['assignableUsers']),
    ...mapState(['userId']),
    ...mapGetters('adminModule', ['reviewers']),
  },
  watch: {
    task: {
      handler(newVal, oldVal) {
        if (newVal.rating !== oldVal.rating) this.ratingLoading = false;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.task-details {
  width: 30%;
  background-color: $grey-1-mayday;
  border-right: 1px solid $grey-4-mayday;
  padding: 18px 14px;
}

.detail-row {
  border-bottom: 1px solid $grey-2-mayday;
  margin-top: 8px;
}

.detail-row:last-child {
  border: none;
}
.detail-title {
  font-size: 12px;
  font-weight: 600;
  color: $grey-6-mayday;
}

.type-icon-feedback-wrapper {
  width: 24px;
  height: 24px;
  padding: 4px;
  background-color: rgba($blue-mayday, 0.1);
  margin-right: 4px;
  border-radius: 4px;
}

.type-icon-info-wrapper {
  width: 24px;
  height: 24px;
  padding: 4px;
  background-color: rgba($yellow-mayday, 0.1);
  margin-right: 4px;
  border-radius: 4px;
}

.type-icon-obsolete-wrapper {
  width: 24px;
  height: 24px;
  padding: 4px;
  background-color: rgba($red-mayday, 0.1);
  margin-right: 4px;
  border-radius: 4px;
}

.type-icon-info {
  color: $yellow-mayday;
  width: 14px;
  height: 14px;
}
.type-icon-feedback {
  color: $blue-mayday;
  width: 14px;
  height: 14px;
}
.type-icon-obsolete {
  color: $red-mayday;
  width: 14px;
  height: 14px;
}

.icon-details {
  width: 14px;
  height: 14px;
  color: $grey-6-mayday;
  transition: transform 200ms ease;
}

.select-icon:hover {
  color: $blue-mayday;
  cursor: pointer;
}

.details-empty-state {
  color: $grey-6-mayday;
  font-size: 14px;
}

.detail-content {
  font-size: 14px;
}

.detail-row-content {
  height: 24px;
}

.group {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 24px;
}

.lowercase {
  text-transform: capitalize;
}

.with-reactions {
  color: $blue-mayday;
}

.without-reactions {
  color: $grey-6-mayday;
}
:deep() .el-select .el-input .el-input__inner {
  height: 24px !important;
}

:deep() .el-select .el-input .el-input__inner::placeholder {
  color: black !important;
  font-size: 14px;
}

:deep() .el-select .el-input.is-focus .el-input__inner {
  border: none !important;
}

:deep() .el-select .el-input .el-input__inner:focus {
  border: none !important;
}

:deep() .el-select-dropdown.el-popper .el-select-dropdown__item.selected {
  color: $grey-6-mayday !important;
}

:deep() .el-select-dropdown__item.selected {
  color: $grey-6-mayday !important;
  font-weight: 400 !important;
}

.label-select {
  :deep() .el-input__inner {
    border: none;
    background-color: $grey-1-mayday;
    color: inherit !important;
    padding-left: 0px !important;
    font-style: inherit;
    font-family: inherit;
  }
}

.assignee-select {
  :deep() .el-input__inner {
    border: none;
    background-color: $grey-1-mayday;
    color: inherit !important;
    padding-left: 0px !important;
    font-style: inherit;
    font-family: inherit;
  }
}

:deep().tag-container {
  padding: 0px 6px !important;
  height: 21px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 22px);
}

:deep() .el-button--primary.is-plain {
  height: 24px !important;
  padding: 0px 15px !important;
}

:deep() .el-button--danger.is-plain {
  height: 24px !important;
  padding: 0px 15px !important;
}

.upvote-icon:hover {
  cursor: pointer;
  transform: scale(1.15);
}

.logo-admin {
  width: 20px;
  height: 20px;
  background-color: $grey-1-mayday;
  box-shadow: 0px 4px 10px $shadow-4-mayday;
  border-radius: 50%;
  border: 1px solid white;
  padding: 2px;
}

.labels {
  width: 100%;
}

.label-count {
  color: $grey-6-mayday;
  font-size: xx-small;
  background-color: $grey-2-mayday;
  height: 18px;
  border-radius: 4px;
  padding: 1px 2px;
  display: flex;
  align-items: center;
}

.verify-button {
  background-color: rgba($red-mayday, 0.1);
  color: $red-mayday;
  border: 1px solid rgba($red-mayday, 0.2);
  font-size: 12px;
}
.author {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 28px);
}
.verify-button {
  background-color: rgba($red-mayday, 0.1);
  color: $red-mayday;
  border: 1px solid rgba($red-mayday, 0.2);
  font-size: 12px;
}
</style>
