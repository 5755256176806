<template>
  <div class="task-assignee-modal">
    <modal :show.sync="localDisplay">
      <div class="row d-flex mt-5 justify-content-center">
        <div class="col-11 text-center">
          <modal-header
            :style-name="{ backgroundColor: '#0081F91A' }"
            class="mb-3"
          >
            <div slot="icon" class="d-inline-flex">
              <font-awesome-icon
                class="icon blue-mayday"
                :icon="['fal', 'user']"
              />
            </div>
            <div class="mt-3">
              <div>
                <p class="mb-0 font-weight-bold">
                  {{ $t('task-view.task-assignee-modal.explaination') }}
                </p>
              </div>
            </div>
          </modal-header>
          <div class="flex-column d-flex justify-content-center subcontent">
            <p class="my-3">
              {{ $t('task-view.task-assignee-modal.action') }}
            </p>
            <div class="mb-4 text-center">
              <el-select
                class="label-select"
                v-model="recipientId"
                :placeholder="$t('task-view.task-assignee-modal.recipients')"
              >
                <el-option
                  v-for="item in assignableUsers"
                  :key="item.id"
                  :label="item.username"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row"></div>
      <template slot="footer">
        <base-button
          :disabled="!recipientId"
          type="primary"
          outline
          size="sm"
          @click.native="handleUpdateAssignee(recipientId)"
        >
          {{ $t('task-view.task-assignee-modal.confirm-button') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import ModalHeader from '@/components/Modals/ModalHeader';

import { mapState } from 'vuex';

export default {
  name: 'task-assignee-modal',
  components: {
    Modal,
    ModalHeader,
  },
  props: {
    display: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localDisplay: this.display,
      recipientId: null,
    };
  },
  computed: {
    ...mapState('taskModule', ['assignableUsers']),
  },
  methods: {
    handleUpdateAssignee(assigneeId) {
      const assignee = this.assignableUsers.find((el) => el.id === assigneeId);
      this.$emit('handle-update-assignee', { assignee });
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.label-modal-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.1);
  margin-bottom: 16px;
  .empty-label-icon {
    max-height: 60px;
    margin-top: -30px;
  }
  .explanation {
    word-break: break-word;
    margin-top: 8px;
  }
  .assign-label-subtitle {
    word-break: break-word;
  }
}

.label-select-container {
  padding: 16px;
  border: 1px solid $blue-mayday;
  border-radius: 4px;
}

.el-select {
  width: 90%;
}

.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 24px;
  height: 24px;
}

.subcontent {
  border: 1px solid $blue-mayday;
  border-radius: 2px;
}
</style>
