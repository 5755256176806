var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.commentDeletion)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.commentLoading),expression:"commentLoading"}],staticClass:"d-flex flex-column comment",class:_vm.comment.isInternal ? 'internal-comment' : 'external-comment'},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('user-avatar',{staticClass:"avatar",attrs:{"username":_vm.author.username,"size":"xsmall"}}),_c('div',{staticClass:"d-flex flex-row mb-0 align-items-center w-100 justify-content-between"},[_c('div',{staticClass:"d-flex flex-row mb-0 align-items-center w-100 comment-title"},[_c('div',{staticClass:"author"},[_vm._v(" "+_vm._s(_vm.author.username)+" ")]),(_vm.isEdited)?_c('div',{staticClass:"ml-1 comment-is-edited"},[_vm._v(" ("+_vm._s(_vm.$t('task-view.task-focus-modal.edited'))+") ")]):_vm._e(),_c('time-ago',{staticClass:"ml-2",attrs:{"date":_vm.comment.createdAt,"reset":_vm.timeAgoReset}})],1),(_vm.displayActions)?_c('div',{staticClass:"task-comment-action d-flex flex-row",class:{ 'task-comment-action__is-actionning': _vm.commentEdition }},[(_vm.commentEdition)?_c('AddAttachment',{staticClass:"thread-action icon-action-wrapper",class:_vm.attachmentLoading || _vm.fileInputDisable
                ? 'icon-action-wrapper__disable'
                : 'icon-action-wrapper__enable',attrs:{"attachment-loading":_vm.attachmentLoading,"disabled":_vm.fileInputDisable},on:{"disable-click":function($event){return _vm.showDisableFileUpload(
                'task-view.task-focus-modal.upload-error.disable-file-upload',
              )},"new-attachment":function($event){_vm.handleFileChange($event), (_vm.attachmentIsChanged = true)}}},[_c('font-awesome-icon',{staticClass:"icon-action",attrs:{"slot":"slot-button","icon":['fal', 'paperclip']},slot:"slot-button"})],1):_vm._e(),(!_vm.editionLoading)?_c('div',{staticClass:"icon-action-wrapper",class:_vm.leftDisable
                ? 'icon-action-wrapper__disable'
                : 'icon-action-wrapper__enable',on:{"click":_vm.handleLeftButton}},[_c('font-awesome-icon',{staticClass:"icon-action",attrs:{"icon":['fal', _vm.leftIcon]}})],1):_vm._e(),_c('div',{staticClass:"icon-action-wrapper",class:_vm.rightDisable
                ? 'icon-action-wrapper__disable'
                : 'icon-action-wrapper__enable',on:{"click":_vm.handleRightButton}},[_c('font-awesome-icon',{staticClass:"icon-action",attrs:{"icon":['fal', _vm.rightIcon],"spin":_vm.editionLoading}})],1)],1):_vm._e()])],1),_c('div',{staticClass:"d-flex flex-column justify-content-start comment-content py-1 pb-2",class:{ 'no-border': _vm.noBorder() }},[(_vm.commentEdition)?_c('MessageEditor',{key:`${_vm.comment.id}-edit`,attrs:{"body":_vm.comment.comment,"editable":true,"autofocus":true},on:{"input":function($event){_vm.updatedComment = $event}}}):_vm._e(),(!_vm.commentEdition && _vm.comment.comment)?_c('MessageEditor',{key:`${_vm.comment.id}-read`,attrs:{"body":_vm.comment.comment,"editable":false}}):_vm._e(),_c('div',{staticClass:"d-flex flex-row justify-content-between w-100"},[(_vm.hasAttachments)?_c('AttachmentList',{staticClass:"mt-1 pb-1 w-100",attrs:{"attachments":_vm.attachments,"isInput":_vm.commentEdition,"display-disable-file-upload":_vm.displayDisableFileUpload,"error-text":_vm.errorText},on:{"delete-attachment":function($event){_vm.handleDeleteAttachment($event), (_vm.attachmentIsChanged = true)},"disable-click":function($event){return _vm.showDisableFileUpload()}}}):_vm._e()],1)],1)]):_c('div',{staticClass:"internal-comment d-flex flex-column text-center"},[_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-center task-comment-deleted"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fal', 'check']}}),_vm._v(" "+_vm._s(_vm.$t('task-view.task-focus-modal.deleted'))+" ")],1),_c('el-button',{staticClass:"py-1",attrs:{"type":"text"},on:{"click":_vm.cancelDeletion}},[_vm._v(" "+_vm._s(_vm.$t('task-view.task-focus-modal.cancel'))+" ")])],1),(_vm.comment.isInternal && !_vm.isLast)?_c('el-divider',{attrs:{"direction":"vertical"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }