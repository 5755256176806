<template>
  <div
    :class="[
      'progressbar',
      'd-flex',
      'align-items-center',
      getProgressbarClass,
    ]"
  >
    <span class="d-flex align-items-center" :style="getPercentageStyle">
      <span class="progressbar-percentage">{{ percentage }}%</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'MdProgress',
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    getBackgroundColor() {
      if (this.percentage < 10) return '#FD0044';
      else if (this.percentage < 75) return '#FCBF49';
      else return '#AFE084';
    },
    getProgressbarClass() {
      if (this.percentage < 10) return 'red-progressbar';
      else if (this.percentage < 75) return 'orange-progressbar';
      else return 'green-progressbar';
    },
    getPercentageStyle() {
      return {
        border: '1px solid transparent',
        background: this.percentage > 0 ? this.getBackgroundColor : 'none',
        height: '17px',
        'border-radius': '10px',
        width: `${this.percentage}%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.progressbar {
  width: 100%;
  height: 17px;
  border-radius: 10px;
}
.progressbar-percentage {
  padding-left: 5px;
  color: white;
  font-size: 12px;
  margin-left: auto;
  margin-right: 5px;
}
.red-progressbar {
  border: 1px solid $red-mayday;
  background-color: rgba($red-mayday, 0.2);
}
.orange-progressbar {
  border: 1px solid $yellow-mayday;
  background-color: rgba($yellow-mayday, 0.2);
}
.green-progressbar {
  border: 1px solid $green-mayday;
  background-color: rgba($green-mayday, 0.2);
}
</style>
