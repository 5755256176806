<template>
  <el-popover
    v-resize="resize"
    :placement="source === 'admin' ? 'top-start' : 'bottom'"
    trigger="click"
    :popper-class="
      'notification-popover-' + (source.includes('web') ? 'web' : 'admin')
    "
    @show="fixBackground(true)"
    @hide="fixBackground(false)"
    v-model="visible"
  >
    <!-- TRIGGER -->
    <NotificationAdminTrigger
      slot="reference"
      v-if="source === 'admin'"
      :visible="visible"
      :unread-count="unreadCount"
    />
    <NotificationWebTrigger
      slot="reference"
      v-else
      :visible="visible"
      :unread-count="unreadCount"
    />
    <!-- BODY -->
    <div class="notification-view__wrapper">
      <notification-view-ce
        :source="source"
        :access-token="accessToken"
        :visible="visible"
      />
      <NotificationFooter v-if="unreadCount > 0 && source === 'web'" />
    </div>
  </el-popover>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import NotificationFooter from '@/components/Notifications/NotificationFooter';
import NotificationAdminTrigger from '@/components/Notifications/NotificationAdminTrigger';
import NotificationWebTrigger from '@/components/Notifications/NotificationWebTrigger';

export default {
  name: 'notification-popover',
  data() {
    return {
      visible: false,
      width: document.documentElement.clientWidth,
      unreadCount: '',
    };
  },
  components: {
    NotificationFooter,
    NotificationAdminTrigger,
    NotificationWebTrigger,
  },
  props: {
    source: {
      type: String,
      default: 'web',
    },
    height: {
      type: String,
      default: '60',
    },
  },
  created() {
    window.___MAYDAY_CE___.userId = this.userId;
    window.addEventListener('md-goToContent', this.goToContent);
    window.addEventListener('md-goToUrl', this.goToUrl);
    window.addEventListener(
      'md-unreadCountUpdate',
      this.handleUnreadCountUpdate,
    );
  },
  destroyed() {
    window.removeEventListener('md-goToContent', this.goToContent);
    window.removeEventListener('md-goToUrl', this.goToUrl);
    window.removeEventListener(
      'md-unreadCountUpdate',
      this.handleUnreadCountUpdate,
    );
  },
  computed: {
    isMobile() {
      return this.width <= 991;
    },
    ...mapGetters(['userId']),
    ...mapState(['accessToken']),
  },
  methods: {
    handleUnreadCountUpdate({ detail }) {
      this.unreadCount = detail;
    },
    fixBackground(val) {
      if (val && !this.isMobile) return;
      const documentClasses = document.body.classList;
      val
        ? documentClasses.add('modal-open')
        : documentClasses.remove('modal-open');
    },
    async goToContent({ detail }) {
      const { id, lang, type, blockId, threadId, messageId } = detail;
      let { entityId, entityType } = detail;
      entityId = id || entityId;
      entityType = type || entityType;

      if (entityType === 'Module') return this.goToModule(entityId, lang);

      if (entityType === 'Program') return this.goToProgram(entityId, lang);

      if (entityType === 'Task') return this.goToTask(entityId);

      const payload = {
        entityId,
        entityType,
        lang,
        hash: blockId ? `#${blockId}` : '',
        query: {
          from: 'notification',
          ...(threadId ? { threadId } : {}),
          ...(messageId ? { messageId } : {}),
        },
      };

      this.$route.name.includes('mayday-web')
        ? this.goToWebTranslatedEntity(payload)
        : this.goToTranslatedEntity(payload);
    },
    goToTask(taskId) {
      this.$router.push({
        path: '/tasks/board',
        query: {
          modalTaskId: taskId,
        },
      });
      this.visible = false;
    },
    goToModule(moduleId, lang) {
      const url = `/desk/academy/modules/${lang}/${moduleId}`;

      window.open(url, '_blank');
      this.visible = false;
    },
    goToProgram(programId, lang) {
      const url = `/desk/academy/programs/${lang}/${programId}`;

      window.open(url, '_blank');
      this.visible = false;
    },
    goToUrl({ detail }) {
      this.$router.push({
        path: detail,
        query: {
          invitation: 'expired',
        },
      });
      this.visible = false;
    },
    resize({ width }) {
      this.width = width;
    },
    ...mapActions('knowledgeModule', ['goToTranslatedEntity']),
    ...mapActions('webKnowledgeModule', ['goToWebTranslatedEntity']),
  },
  watch: {
    visible() {
      if (this.visible) {
        window.dispatchEvent(new CustomEvent('md-getNotifications'));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 991px) {
  .notification-view__wrapper {
    width: 350px;
  }
}
.notification-view__wrapper {
  min-width: 350px;
  max-height: 80vh;
}

:deep() * {
  word-break: normal;
}
</style>
