<template>
  <div class="sticky-top pt-2">
    <div v-for="section in sections" :key="section.title">
      <h3
        class="menu-section-title"
        @click="changeSelection(section)"
        :class="[
          'menu-section-title',
          { 'selected-section': section.selected },
        ]"
      >
        <span class="menu-section-title-icon">
          <font-awesome-icon :icon="section.icon"></font-awesome-icon>
        </span>
        {{ section.title.toUpperCase() }}
      </h3>
      <div v-if="section.title === 'Notifications'" class="menu-section-items">
        <NotificationCenterFilterItem
          :title="$t('notification-center.notif-page-select-all')"
          :groups="groups"
          :checkList="targetGroups"
          @update="updateFilters"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NotificationCenterFilterItem from './NotificationCenterFilterItem';
import { mapGetters } from 'vuex';

export default {
  name: 'NotificationCenterMenu',
  components: {
    NotificationCenterFilterItem,
  },
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
    targetGroups: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('adminModule', ['groups']),
  },
  methods: {
    changeSelection(section) {
      let selectedSection = this.sections.filter((v) => {
        return v.title == section.title;
      })[0];
      this.$emit('click', selectedSection.title);
    },
    updateFilters(filters) {
      this.$emit('filters-update', { field: 'targetGroups', value: filters });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-section-title {
  text-transform: uppercase;
  color: $grey-5-mayday;
  cursor: pointer;
}
.menu-section-title-icon {
  margin-right: 10px;
}

.selected-section {
  color: $blue-mayday;
}
</style>
