<template>
  <div class="mb-3">
    <div class="mb-1">
      <div class="element-title">
        {{ $t(title) }}
      </div>
      <div class="element-description" v-if="description">
        {{ $t(description) }}
      </div>
    </div>
    <div class="d-flex flex-row align-items-center">
      <el-input
        v-if="isModifying"
        :placeholder="$t(placeholder)"
        :class="checkPassword"
        type="password"
        v-model="password"
        size="mini"
        class="text-input mr-2"
        :disabled="!isModifying"
        :style="`width: ${width}`"
        @keyup.native.esc="isModifying = false"
      ></el-input>
      <el-input
        v-else
        :placeholder="$t(placeholder)"
        type="password"
        v-model="value"
        size="mini"
        class="text-input mr-2"
        :disabled="!isModifying"
        :style="`width: ${width}`"
      ></el-input>
      <el-button
        v-if="!isModifying"
        size="mini"
        @click="isModifying = true"
        type="primary"
      >
        {{ $t('public-knowledge.configuration.items.domain.modify-button') }}
      </el-button>
    </div>
    <div class="d-flex flex-row align-items-center mt-2" v-if="isModifying">
      <el-input
        :placeholder="$t(placeholder)"
        :class="passwordMatch"
        type="password"
        v-model="repeatPassword"
        size="mini"
        class="text-input mr-2"
        :disabled="!isModifying"
        :style="`width: ${width}`"
        @keyup.native.esc="isModifying = false"
      ></el-input>
      <el-button size="mini" :disabled="error" @click="newValue" type="primary">
        {{ $t('public-knowledge.configuration.items.domain.save-button') }}
      </el-button>
      <el-button size="mini" @click="isModifying = false" type="danger">
        {{ $t('generic.cancel') }}
      </el-button>
    </div>

    <div class="mt-2 justify-content-end element-description">
      <div v-if="$v.password.$invalid && password.length > 1">
        <font-awesome-icon :icon="['fad', 'lock-alt']" class="mr-1" />
        {{ $t(instructions) }}
      </div>
      <div v-if="$v.repeatPassword.$invalid && repeatPassword.length > 3">
        <font-awesome-icon :icon="['fad', 'lock-alt']" class="mr-1" />
        {{ $t(match) }}
      </div>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'settings-password-input',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '40%',
    },
    instructions: {
      type: String,
      default: '',
    },
    match: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      password: '',
      repeatPassword: '',
      isModifying: false,
    };
  },
  computed: {
    isEmpty() {
      return !!this.value;
    },
    checkPassword() {
      return {
        error: this.$v.password.$invalid && this.password.length > 1,
      };
    },
    passwordMatch() {
      return {
        error:
          this.$v.repeatPassword.$invalid && this.repeatPassword.length > 3,
      };
    },
    error() {
      return this.$v.password.$invalid || this.$v.repeatPassword.$invalid;
    },
  },
  validations: {
    password: {
      required,
      goodPassword: (password) => {
        //a custom validator!
        return (
          password.length >= 8 &&
          /[a-z]/.test(password) &&
          /[A-Z]/.test(password) &&
          /[0-9]/.test(password) &&
          /\W|_/.test(password)
        );
      },
    },
    repeatPassword: {
      required,
      sameAsPassword: sameAs('password'),
    },
  },
  methods: {
    newValue(value) {
      if (this.isEmpty) {
        return;
      }
      this.$emit('update', value);
      this.isModifying = false;
    },
    notify(done) {
      this.$message({
        message: this.$t(
          `${
            done
              ? 'public-knowledge.registered-confirmation-message'
              : 'public-knowledge.not-registered-confirmation-message'
          }`,
        ),
        type: `${done ? 'success' : 'fail'}`,
      });
    },
  },
  watch: {
    value(newVal) {
      this.textValue = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.text-input {
  font-size: 12px;
}
:deep() .el-input.is-disabled .el-input__inner {
  color: $grey-7-mayday;
}

.error {
  :deep(.el-input__inner) {
    border: 1px solid $red-mayday;
  }
}
</style>
