<template>
  <div class="rating-input">
    <div class="rating-input__label">
      <span>{{
        $t('task-view.task-focus-modal.details.rating.description')
      }}</span>
    </div>
    <div class="rating-input__interactive-elements">
      <div :class="['rating-input__stars', { edit: editMode }]">
        <div
          class="rating-input__star"
          v-for="i in starsCount"
          :key="i"
          @click="selectStar(starsCount - i + 1)"
          @mouseover="handleMouseOver(starsCount - i + 1)"
          @mouseleave="handleMouseOver(0)"
        >
          <font-awesome-icon
            :icon="['fas', 'star']"
            :class="[
              'rating-input__star--selected',
              { edit: editMode },
              { delete: hoverDelete },
            ]"
            v-if="value >= starsCount - i + 1"
          />
          <font-awesome-icon
            :icon="['fal', 'star']"
            :class="[
              'rating-input__star--hovered',
              {
                selected: isLightStarSelected(starsCount - i + 1),
              },
              { delete: hoverDelete },
            ]"
          />
        </div>
      </div>
      <div :class="['rating-input__buttons', { edit: editMode }]">
        <el-tooltip
          v-if="editMode && selectedValue"
          :content="
            $t('task-view.task-focus-modal.details.rating.tooltips.confirm')
          "
        >
          <div class="rating-input__icon-wrapper" @click="sendRating">
            <font-awesome-icon
              :icon="['fal', 'check']"
              class="rating-input__icon"
            />
          </div>
        </el-tooltip>
        <el-tooltip
          v-if="editMode && value"
          :content="
            $t('task-view.task-focus-modal.details.rating.tooltips.cancel')
          "
        >
          <div class="rating-input__icon-wrapper" @click="resetRating">
            <font-awesome-icon
              :icon="['fal', 'times']"
              class="rating-input__icon"
            />
          </div>
        </el-tooltip>
        <el-tooltip
          v-if="editMode && value"
          :content="
            $t('task-view.task-focus-modal.details.rating.tooltips.delete')
          "
        >
          <div
            class="rating-input__icon-wrapper"
            @click="deleteRating"
            @mouseover="hoverDelete = true"
            @mouseleave="hoverDelete = false"
          >
            <font-awesome-icon
              :icon="['fal', 'trash']"
              class="rating-input__icon"
            />
          </div>
        </el-tooltip>
        <el-tooltip
          v-if="!editMode"
          :content="
            $t('task-view.task-focus-modal.details.rating.tooltips.edit')
          "
        >
          <div class="rating-input__icon-wrapper" @click="editRating">
            <font-awesome-icon
              :icon="['fal', 'pen']"
              class="rating-input__icon"
            />
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="rating-input__label">
      <span>{{ actionPrompt }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'rating-input',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    starsCount: {
      type: Number,
      default: 5,
    },
  },
  data: () => ({
    hoveredStar: 0,
    editMode: false,
    selectedValue: 0,
    actionPrompt: '',
    hoverDelete: false,
  }),
  created() {
    this.editMode = this.value <= 0;
  },
  methods: {
    selectStar(star) {
      this.selectedValue = star;
      if (!this.value) this.sendRating();
    },
    resetRating() {
      this.selectedValue = this.value;
      this.editMode = false;
    },
    deleteRating() {
      this.$emit('delete-rating');
      this.selectedValue = 0;
      this.actionPrompt = this.$t(
        'task-view.task-focus-modal.details.rating.confirm-delete',
      );
    },
    sendRating() {
      this.actionPrompt = this.$t(
        'task-view.task-focus-modal.details.rating.confirm-update',
      );
      this.$emit('update-rating', this.selectedValue);
      this.editMode = false;
    },
    editRating() {
      this.selectedValue = this.value;
      this.editMode = true;
    },
    handleMouseOver(index) {
      this.hoveredStar = index;
    },
    isLightStarSelected(index) {
      return this.editMode
        ? (this.hoveredStar === 0 && this.selectedValue >= index) ||
            this.hoveredStar >= index
        : this.value >= index;
    },
  },
  watch: {
    value(newVal) {
      this.editMode = !newVal;
    },
  },
};
</script>

<style scoped lang="scss">
.rating-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.rating-input__interactive-elements {
  display: flex;
  align-items: center;
  gap: 8px;
}

.rating-input__label {
  font-family: Lato, sans-serif;
  font-size: 10px;
  color: $grey-8-mayday;
  line-height: 12px;
}

.rating-input__stars {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: $grey-4-mayday;
  cursor: pointer;
  height: 16px;
  pointer-events: none;

  &.edit {
    pointer-events: auto;
  }
}

.rating-input__star {
  padding-right: 4px;
  transition: padding 0.2s, transform 0.5s;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 100%;

  &:hover ~ .rating-input__star .rating-input__star--hovered,
  &:hover .rating-input__star--hovered {
    color: $blue-mayday;
  }
}

.rating-input__star:last-child {
  padding-left: 0;
}

.rating-input__star svg {
  width: 12px;
  height: 12px;
  transition: width 0.2s, height 0.2s;
}

.rating-input__star--selected {
  color: $blue-mayday;
  transition: opacity 0.5s;

  &.edit {
    opacity: 50%;
    &.delete {
      color: $red-mayday;
    }
  }
}

.rating-input__star--hovered {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate(0, 2px);

  &.selected {
    color: $blue-mayday;
    &.delete {
      color: $red-mayday;
    }
  }
}

.rating-input__buttons {
  display: flex;
  gap: 2px;

  .rating-input__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    padding: 4px;
    color: $grey-6-mayday;
    cursor: pointer;
    transition: color 0.2s;
    border-radius: 2px;
    &:hover {
      color: $blue-mayday;
      background-color: $grey-2-mayday;
    }

    .rating-input__icon {
      width: 8px;
      height: 8px;
    }
  }
}
</style>
