<template>
  <div class="update-user-label-modal" @keydown.esc="handleExitNewComment">
    <modal :show.sync="localDisplay" @close="$emit('close')">
      <div v-loading="loader" class="d-flex flex-row wrapper">
        <div class="d-flex flex-column task-details">
          <task-focus-details
            :task="task"
            class="task-details-component"
            v-if="task.type"
            @handle-update-assignee="$emit('handle-update-assignee', $event)"
            @handle-update-status="$emit('handle-update-status', $event)"
            @add-upvote="$emit('add-upvote', $event)"
            @remove-upvote="$emit('remove-upvote', $event)"
            @handle-is-archive="$emit('handle-is-archive', $event)"
            @update-content-status="bulkUpdateStatus($event)"
            @close="$emit('close')"
            @update-rating="$emit('update-rating', $event)"
            @delete-rating="$emit('delete-rating', $event)"
          />
          <div class="task-archive" v-if="!task.isArchive">
            <el-button
              class="archive-button"
              type="primary"
              @click="
                $emit('handle-is-archive', { taskId: task.id, isArchive: true })
              "
              >{{ $t('task-view.task-focus-modal.delete') }}
            </el-button>
          </div>
          <div class="task-archive" v-else>
            <el-button
              class="archive-button"
              type="primary"
              @click="
                $emit('handle-is-archive', {
                  taskId: task.id,
                  isArchive: false,
                })
              "
              >{{ $t('task-view.task-focus-modal.restore') }}
            </el-button>
          </div>
        </div>
        <div v-if="!loader" class="d-flex flex-column task-comments">
          <div
            class="d-flex flex-row justify-content-center align-items-center"
          >
            <div class="task-date mb-2">
              <font-awesome-icon
                :icon="['fal', 'calendar-week']"
                class="icon-calendar mr-2"
              />
              <span class="sm">{{ task.createdAt }}</span>
            </div>
          </div>

          <div class="d-flex flex-column px-3 first-comment">
            <div
              class="d-flex flex-row align-items-top justify-content-between mr-2"
            >
              <div class="user-wrapper">
                <img
                  v-if="isInfo || isObsolete"
                  src="@/assets/mayday-logo.png"
                  alt="mayday-logo-rounded"
                  class="logo-admin"
                />
                <user-avatar
                  v-else
                  :username="authorUsername"
                  size="xsmall"
                  class="avatar"
                ></user-avatar>
              </div>
              <div class="d-flex flex-column thread">
                <div :class="['author d-flex flex-row align-items-center']">
                  <div>
                    {{ bodyAuthorName }}
                  </div>
                </div>
                <MaydayFeedbackEditor
                  class="d-flex flex-row body"
                  :message="
                    isObsolete
                      ? $t(this.task.body.path, {
                          title: this.task.body.title,
                          dueDate: this.task.body.dueDate,
                        })
                      : task.body
                  "
                  :editable="false"
                />
                <AttachmentList
                  v-if="task.attachments"
                  :attachments="task.attachments"
                  :isInput="false"
                  class="mb-1"
                ></AttachmentList>
                <div class="d-flex flex-row align-items-center mb-2">
                  <div
                    class="d-flex flex-row align-items-center"
                    v-if="task.source !== 'SELFCARE'"
                  >
                    <el-tooltip>
                      <template slot="content">
                        <div v-if="taskHasUpvotes">
                          <div
                            v-for="upvoter in task.upvotes"
                            :key="upvoter.id"
                          >
                            {{ upvoter.username }}
                          </div>
                        </div>
                        <div v-else>
                          {{ $t('task-view.empty-upvote-tooltip') }}
                        </div>
                      </template>
                      <div
                        class="d-flex flex-row align-items-center upvote-badge mr-3"
                      >
                        <font-awesome-icon
                          :icon="[taskHasUpvotes ? 'fas' : 'fal', 'heart']"
                          :class="[
                            taskHasUpvotes
                              ? 'with-reactions'
                              : 'without-reaction',
                            'upvote-icon',
                          ]"
                          @click="handleUpvote"
                        />
                        <div v-if="taskHasUpvotes" class="upvote-count ml-2">
                          {{ task.upvoteCount }}
                        </div>
                      </div>
                    </el-tooltip>
                  </div>
                  <el-button
                    v-if="
                      !EditNewComment &&
                      !taskHasComments &&
                      currentTaskAttributes.answer &&
                      task.source !== 'SELFCARE'
                    "
                    type="primary"
                    plain
                    size="mini"
                    @click.stop="handleEditNewComment(false)"
                    class="response-button"
                  >
                    {{ $t('task-view.task-focus-modal.answer') }}
                  </el-button>
                  <el-button
                    v-if="
                      !EditNewComment &&
                      !taskHasComments &&
                      currentTaskAttributes.answer &&
                      task.source !== 'SELFCARE'
                    "
                    @click.stop="handleEditNewComment(true)"
                    class="response-button"
                    type="info"
                    plain
                    size="mini"
                  >
                    {{ $t('task-view.task-focus-modal.add-note') }}
                  </el-button>
                </div>
              </div>
            </div>
            <task-focus-thread
              v-if="!loader"
              :comments="task.comment"
              :isEditing="EditNewComment"
              class="thread-component"
              @delete-comment="handleDeleteComment"
            />
            <div
              class="d-flex align-items-center align-response"
              v-if="
                !EditNewComment &&
                taskHasComments &&
                currentTaskAttributes.answer
              "
            >
              <el-button
                type="primary"
                plain
                size="mini"
                @click.stop="handleEditNewComment(false)"
                class="response-button"
              >
                {{ $t('task-view.task-focus-modal.answer') }}
              </el-button>
              <el-button
                @click.stop="handleEditNewComment(true)"
                class="response-button"
                type="info"
                plain
                size="mini"
              >
                {{ $t('task-view.task-focus-modal.add-note') }}
              </el-button>
            </div>

            <div
              v-if="EditNewComment"
              class="d-flex flex-column align-items-left editor-wrapper"
            >
              <el-divider
                v-if="taskHasComments"
                direction="vertical"
              ></el-divider>
              <div
                class="editor-under-wrapper"
                :class="{
                  'editor-under-wrapper__is-internal': newCommentIsInternal,
                }"
              >
                <div class="avatar-column">
                  <div class="avatar-wrapper">
                    <user-avatar
                      :username="username"
                      size="xsmall"
                      class="avatar mr-2"
                    ></user-avatar>
                  </div>
                </div>
                <div class="w-100">
                  <InputEditor
                    :reset="resetInput"
                    :editable="true"
                    :showAttachments="true"
                    class="editor-input"
                    @handle-new-comment="handleNewComment"
                  />
                  <div class="echap-info">
                    {{ $t('task-view.task-focus-modal.press-esc') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import TaskFocusDetails from './TaskFocusDetails.vue';
import TaskFocusThread from './TaskFocusThread.vue';
import MaydayFeedbackEditor from '@/components/Editors/MaydayFeedbackEditor';
import UserAvatar from '../../../components/UserAvatar.vue';
import { mapState, mapActions } from 'vuex';
import InputEditor from './InputEditor.vue';
import { getTaskAttributes } from './taskTypeDisplay.js';
import AttachmentList from '@/components/Attachments/AttachmentList';

export default {
  name: 'task-focus-modal',
  components: {
    Modal,
    TaskFocusDetails,
    TaskFocusThread,
    MaydayFeedbackEditor,
    UserAvatar,
    InputEditor,
    AttachmentList,
  },
  props: {
    display: {
      type: Boolean,
      required: true,
    },
    loader: {
      type: Boolean,
    },
    task: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localDisplay: this.display,
      EditNewComment: false,
      newCommentIsInternal: false,
      resetInput: false,
    };
  },
  computed: {
    authorUsername() {
      const authorHasUsername = this.task.author && this.task.author.username;
      if (!authorHasUsername) return this.$t('task-view.deleted-user');
      if (this.task.author.trad) return this.$t(this.task.author.username);

      return this.task.author.username;
    },
    bodyAuthorName() {
      if (this.isInfo || this.isObsolete) return this.$t('task-view.admin');
      return this.authorUsername;
    },
    isInfo() {
      return this.task.type === 'INFORMATION';
    },
    isObsolete() {
      return this.task.type === 'CONTENT_OBSOLETE';
    },
    upvotersId() {
      return this.task.upvotes.map((upvoter) => upvoter.id);
    },
    taskHasUpvotes() {
      return this.task.upvoteCount > 0;
    },
    taskHasComments() {
      return this.task.commentCount > 0;
    },
    currentTaskAttributes() {
      return getTaskAttributes(this.task.type);
    },
    ...mapState(['userId', 'username']),
  },
  methods: {
    handleEditNewComment(isInternal) {
      this.EditNewComment = true;
      this.newCommentIsInternal = isInternal;
      this.resetInput = !this.resetInput;
    },
    handleNewComment(e) {
      this.$emit('handle-new-comment', {
        ...e,
        taskId: this.task.id,
        isInternal: this.newCommentIsInternal,
      });
      this.resetInput = !this.resetInput;
      this.EditNewComment = false;
    },
    handleDeleteComment(e) {
      this.$emit('delete-comment', e);
    },
    handleExitNewComment(e) {
      if (e.target.closest('.tippy-box')) return;
      this.resetInput = !this.resetInput;
      this.EditNewComment = false;
    },
    handleUpvote() {
      if (this.upvotersId.includes(this.userId))
        this.$emit('remove-upvote', { taskId: this.task.id });
      else this.$emit('add-upvote', { taskId: this.task.id });
    },
    ...mapActions('knowledgeModule', ['bulkUpdateStatus']),
  },
};
</script>

<style lang="scss" scoped>
:deep() code {
  line-height: 20px !important;
  font-size: 12px !important;
}

:deep() .modal-body {
  padding: 0px !important;
  height: 75vh;
}

:deep() .modal-dialog {
  max-width: 670px !important;
  max-height: 75vh !important;
}

.wrapper {
  height: 100%;
}

.task-comments {
  width: 70%;
  max-height: 75vh;
  overflow: auto;
  padding-bottom: 12px;
}

.task-details {
  width: 30%;
  background-color: $grey-1-mayday;
  border-right: 1px solid $grey-4-mayday;
  justify-content: space-between;
  max-height: 75vh;
}

.task-details-component {
  max-height: 90%;
  overflow: auto;
  padding: 18px 14px;
}

.task-archive {
  padding: 9px 15px;
  display: flex;
  justify-content: center;
  border-top: 1px solid $grey-4-mayday;
}

.archive-button {
  width: 90%;
  justify-content: center;
}
.thread {
  overflow: auto;
  width: 100%;
  margin-left: 7px;
}

.first-comment .body {
  text-align: justify;
}

.first-comment .author {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  height: 24px;
  display: flex;
  align-items: center;
}

.avatar-column {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
}
.avatar-wrapper {
  height: 28px;
  display: flex;
  align-items: center;
}

.avatar {
  width: 22px !important;
  height: 22px !important;
}

.user-wrapper {
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
}

.logo-admin {
  width: 20px;
  height: 20px;
  background-color: #f0f3f7;
  box-shadow: 0px 4px 10px rgba(29, 76, 139, 0.25);
  border-radius: 50%;
  border: 1px solid white;
  padding: 2px;
}

.mayday-feedback-editor {
  padding: 0px;
  :deep() .velmld-parent {
    position: unset !important;
  }

  :deep() .mayday-editor {
    padding: 0px;
  }
}

:deep() .mayday-editor__content__ProseMirror p {
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.task-date {
  background-color: $grey-1-mayday;
  color: $grey-6-mayday;
  border-radius: 10px;
  padding: 3px 6px;
  font-weight: 700;
  font-size: 12px;
  margin-top: 13px;
}
.editor-wrapper {
  width: 100%;
  margin-left: 11px;
}

.editor-under-wrapper {
  padding-left: 17px;
  padding-bottom: 4px;
  display: flex;
  width: 97%;
  border-radius: 4px;
  &__is-internal {
    background-color: $grey-2-mayday;
    padding-top: 4px;
    padding-right: 4px;
    :deep() .attachment {
      border: 1px solid $grey-4-mayday;
    }
  }
}

:deep() .el-divider--vertical {
  width: 2px;
  color: $grey-2-mayday;
  background-color: $grey-2-mayday;
  height: 12px;
  margin-left: 27px;
  display: block;
}

.editor-input {
  width: 100%;
}

.upvote-icon {
  width: 10px;
  height: 10px;
  transition: transform 200ms ease;
  cursor: pointer;
  &:hover {
    transform: scale(1.15);
  }
}

.with-reactions {
  color: $blue-mayday;
}

.without-reactions {
  color: $grey-6-mayday;
}

.upvote-count {
  font-size: 12px;
}

.upvote-badge {
  background-color: $grey-1-mayday;
  padding: 1px 4px;
  border-radius: 4px;
}

.response-button {
  font-size: 12px !important;
  padding: 4px 8px !important;
  width: fit-content !important;
  height: 22px !important;
}

.add-note-button {
  font-size: 12px !important;
  padding: 4px 8px !important;
  width: fit-content !important;
}

.align-response {
  margin-left: 53px;
  margin-top: 8px;
}

.thread-component {
  margin-left: 11px;

  :deep() code {
    line-height: 16px !important;
    font-size: 12px !important;
  }
}
.echap-info {
  font-size: 10px;
  color: $grey-5-mayday;
}
</style>
