<template>
  <div class="hub-settings">
    <div class="hub-settings-header">
      <div class="d-flex justify-content-between">
        <div class="element-title">
          {{ $t('settings.general.collaborative-hub.title') }}
        </div>
        <el-button size="mini" type="primary" @click="handleOpenView(null)">{{
          $t('settings.general.collaborative-hub.cta')
        }}</el-button>
      </div>
      <div class="element-description">
        {{ $t('settings.general.collaborative-hub.description') }}
      </div>
    </div>
    <div class="hub-settings-content">
      <div
        v-loading="isLoading"
        :class="{ 'fill-height': pages > 1 }"
        class="hub-settings-container"
      >
        <SettingsDropdown
          v-for="view in views"
          :key="view.id"
          class="hub-dropdown"
        >
          <div slot="title" class="hub-dropdown-title">
            <div class="hub-view-title-container">
              <div class="hub-icon-wrapper">
                <font-awesome-icon :icon="['fal', 'sliders-h']" />
              </div>
              <div class="hub-view-ellipsis">
                <TextEllipsis
                  :text="view.name"
                  class="hub-view-title"
                  font-size="16px"
                />
                <TextEllipsis
                  v-if="view.description"
                  :text="view.description"
                  font-size="14px"
                />
              </div>
            </div>
            <el-popover popper-class="hub-popover">
              <button slot="reference" class="popover-button">
                <font-awesome-icon
                  :icon="['fal', 'ellipsis-h']"
                  class="popover-icon"
                />
              </button>
              <div slot="default">
                <div class="filter-option" @click="handleOpenView(view.id)">
                  <font-awesome-icon
                    :icon="['fal', 'pen']"
                    class="button-icon"
                  />
                  <div>
                    {{
                      $t('settings.general.collaborative-hub.rows.tooltip.edit')
                    }}
                  </div>
                </div>
                <div
                  class="filter-option delete"
                  @click="handleDeleteView(view.id)"
                >
                  <font-awesome-icon
                    :icon="['fal', 'trash']"
                    class="button-icon"
                  />
                  <div>
                    {{
                      $t(
                        'settings.general.collaborative-hub.rows.tooltip.delete',
                      )
                    }}
                  </div>
                </div>
              </div>
            </el-popover>
          </div>
          <div slot="dropdown-content" class="hub-dropdown-content">
            <HubSettingsFilters v-bind="parsedViewFilters(view.filters)" />
          </div>
        </SettingsDropdown>
      </div>
      <el-pagination
        :current-page="page"
        :disabled="isLoading"
        :page-count="pages"
        background
        hide-on-single-page
        layout="prev, pager, next"
        @current-change="page = $event"
      />
    </div>
    <HubSettingsDrawer
      :show="drawerOpened"
      :view-id="focusedViewId"
      @close="handleCloseView"
      @new-view="fetchViews"
    />
  </div>
</template>

<script>
import SettingsDropdown from 'components/Settings/SettingsDropdown.vue';
import { mapActions } from 'vuex';
import qs from 'qs';
import HubSettingsFilters from 'views/Settings/GeneralSettings/HubSettings/HubSettingsFilters.vue';
import HubSettingsDrawer from 'views/Settings/GeneralSettings/HubSettings/HubSettingsDrawer.vue';
import TextEllipsis from 'components/TextEllipsis.vue';

export default {
  name: 'HubSettings',
  components: {
    TextEllipsis,
    HubSettingsDrawer,
    HubSettingsFilters,
    SettingsDropdown,
  },
  data() {
    return {
      views: [],
      page: 1,
      pages: 1,
      itemsPerPage: 5,
      isLoading: true,
      focusedViewId: null,
      drawerOpened: false,
      listCategories: ['type', 'task'],
    };
  },
  methods: {
    async fetchViews() {
      this.isLoading = true;
      try {
        const response = await this.getSettingsTaskViews({
          page: this.page,
        });
        this.views = response.results;
        this.pages = response.pages;
        this.parsedViewFilters(response.results[0].filters);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    parsedViewFilters(filters) {
      const rawFilters = qs.parse(filters);
      const parsedFilters = {};
      Object.keys(rawFilters).forEach((key) => {
        if (this.listCategories.includes(key)) {
          Object.keys(rawFilters[key]).forEach((subKey) => {
            if (subKey !== '$Op') {
              parsedFilters[subKey] = {
                operator: rawFilters[key].$Op,
                values:
                  rawFilters[key][subKey][
                    Object.keys(rawFilters[key][subKey])[0]
                  ].split(','),
              };
            }
          });
        } else {
          const tempFilter = {
            operator: rawFilters[key].$Op,
          };
          Object.keys(rawFilters[key]).forEach((subKey) => {
            if (subKey !== '$Op') {
              tempFilter[subKey] = {
                operator: Object.keys(rawFilters[key][subKey])[0],
                values:
                  rawFilters[key][subKey][
                    Object.keys(rawFilters[key][subKey])[0]
                  ].split(','),
              };
            }
          });
          parsedFilters[key] = { ...tempFilter };
        }
      });
      return parsedFilters;
    },
    handleOpenView(id) {
      this.focusedViewId = id;
      this.drawerOpened = true;
    },
    handleCloseView() {
      this.focusedViewId = null;
      this.drawerOpened = false;
    },
    async handleDeleteView(id) {
      try {
        await this.deleteTaskView(id);
        if (this.views.length - 1 === 0) {
          this.page = this.page - 1 || 1;
        }
        this.fetchViews();
      } catch (error) {
        console.error(error);
      }
    },
    ...mapActions('taskModule', [
      'getSettingsTaskViews',
      'deleteTaskView',
      'updateTaskView',
    ]),
  },
  watch: {
    page: {
      handler() {
        this.fetchViews();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.hub-settings,
.hub-settings-header,
.hub-settings-content {
  width: 100%;
}

.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  width: 100%;
  max-width: calc(100% - 32px);
  overflow: hidden;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.hub-settings-container {
  width: 100%;
  overflow: hidden;
  &.fill-height {
    min-height: calc(5 * 58px);
  }
  .main-container {
    width: 100%;
    :deep(.content-container) {
      width: calc(100% - 32px);
    }
  }
}

.hub-dropdown-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  height: 40px;
}

.hub-view-title-container {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.hub-view-ellipsis {
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
  overflow: hidden;
  font-size: 14px;
  color: $grey-8-mayday;

  .hub-view-title {
    font-weight: 700;
    font-size: 16px;
    color: black;
  }
}

.hub-dropdown {
  margin: 0;
  :deep(.title-container) {
    margin: 0;
  }
}

.popover-button {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: $grey-1-mayday;
  }
}

.hub-icon-wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $grey-3-mayday;
  border-radius: 2px;
  color: $grey-8-mayday;
  margin-right: 8px;
}

.filter-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
  &:hover {
    background-color: #f5f5f5;
    color: $blue-5-mayday;
  }
  &.delete {
    border-top: 1px solid $grey-2-mayday;
    .button-icon,
    &:hover {
      color: $red-5-mayday;
    }
  }
  .button-icon {
    margin-right: 4px;
    height: 16px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $grey-8-mayday;
  }
}
.hub-dropdowns-container {
  min-height: 50px;
}

:deep(.el-pagination) {
  margin: 10px auto 0;
  width: fit-content;
}
</style>
