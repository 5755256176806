<template>
  <el-drawer
    :show-close="false"
    v-on="$listeners"
    :visible="show"
    :with-header="false"
    size="50%"
    :append-to-body="true"
    @close="$emit('close')"
  >
    <div class="unsaved-changes" v-if="confirmationModal">
      <div class="icon-container">
        <font-awesome-icon
          :icon="['fal', 'exclamation-triangle']"
          class="icon"
        />
      </div>
      <div class="description">
        <p class="font-bold mb-0">
          {{ $t('components.modals.ignore-plugin-changes-modal.title') }}
        </p>
        <small class="text-muted">{{
          $t('components.modals.ignore-plugin-changes-modal.description')
        }}</small>
      </div>
      <div>
        <base-button
          class="button"
          type="success"
          @click="$emit('confirm-close', true)"
          >{{
            $t('components.modals.ignore-plugin-changes-modal.save')
          }}</base-button
        >
        <base-button
          class="button"
          type="secondary"
          @click="$emit('confirm-close')"
          >{{
            $t('components.modals.ignore-plugin-changes-modal.confirm')
          }}</base-button
        >
      </div>
    </div>
    <!-- HEADER  -->
    <div v-else>
      <PluginAdvancedSettingsDrawerHeader
        :title="label"
        :description="shortDescription"
        :icon="icon"
        @save-plugin="$emit('save-plugin')"
        :hideSaveButton="hideSaveButton"
      />
      <div class="container p-0">
        <div class="side">
          <!-- SIDE BAR -->
          <PluginAdvancedSettingsDrawerSide
            :isActive="isActive"
            :currentPluginEntities="currentPluginEntities"
            @switched="$emit('switched', $event)"
          />
        </div>
        <!-- MAIN -->
        <div class="main">
          <PluginAdvancedSettingsDrawerMain
            name="Plugin"
            :currentPluginEntities="currentPluginEntities"
            :isPreferenceActive="isActive"
            :features="features"
            :pluginType="pluginType"
            :config="config"
            :errors="errors"
            @update-plugin-integration="
              ({ name, isActive }) =>
                updateCurrentPluginIntegration(name, isActive)
            "
            @update-plugin="
              ({ index, ...event }) => updatePluginEntity(index, event)
            "
            @add-plugin="addPluginEntity"
            @delete-plugin="deletePluginEntity"
            @update-access-restriction="
              $emit('update-access-restriction', $event)
            "
            @hide-save-button="updateSaveButtonVisibility"
          />
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { createSkeleton } from '../../../../utils/objectSkeleton';
import PluginAdvancedSettingsDrawerHeader from './PluginAdvancedSettingsDrawerHeader.vue';
import PluginAdvancedSettingsDrawerMain from './PluginAdvancedSettingsDrawerMain.vue';
import PluginAdvancedSettingsDrawerSide from './PluginAdvancedSettingsDrawerSide.vue';

export default {
  name: 'plugin-advanced-settings-drawer',
  components: {
    PluginAdvancedSettingsDrawerHeader,
    PluginAdvancedSettingsDrawerMain,
    PluginAdvancedSettingsDrawerSide,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    shortDescription: {
      type: String,
      required: true,
    },
    icon: {
      type: Array,
    },
    currentPluginEntities: {
      type: Array,
      required: true,
    },
    errors: {
      type: Array,
    },
    pluginType: {
      type: String,
      required: true,
    },
    confirmationModal: {
      type: Boolean,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hideSaveButton: false,
    };
  },
  methods: {
    updateCurrentPluginIntegration(integrationName, value) {
      const updatedIntegrations =
        this.currentPluginEntities[0].integrations.map(({ name, isActive }) =>
          name === integrationName
            ? { name, isActive: value }
            : { name, isActive },
        );
      const updatedEntities = this.currentPluginEntities.map(
        (pluginEntity) => ({
          ...pluginEntity,
          integrations: updatedIntegrations,
        }),
      );
      this.$emit('update-plugin', updatedEntities);
    },
    resetCurrentPluginEntitiesIntegrations() {
      const updatedEntities = this.currentPluginEntities.map(
        (currentPlugin) => {
          const resetedIntegrations = currentPlugin.integrations.map(
            (integration) => ({
              ...integration,
              isActive: false,
            }),
          );

          return {
            ...currentPlugin,
            integrations: resetedIntegrations,
          };
        },
      );
      this.$emit('update-plugin', updatedEntities);
    },
    addPluginEntity() {
      if (!this.currentPluginEntities.length) {
        return;
      }

      const { title, label, description, color, url, subtype } = createSkeleton(
        this.currentPluginEntities[0],
      );
      this.currentPluginEntities.push({
        title,
        description,
        color,
        url,
        subtype,
        label,
        type: this.pluginType,
        integrations: this.currentPluginEntities[0].integrations,
      });
      this.$emit('update-plugin', this.currentPluginEntities);
    },
    updatePluginEntity(index, payload) {
      const relatedPlugin = this.currentPluginEntities[index];
      this.currentPluginEntities[index] = {
        ...relatedPlugin,
        ...payload,
      };
      this.$emit('update-plugin', this.currentPluginEntities);
    },
    deletePluginEntity(index) {
      this.currentPluginEntities.splice(index, 1);
      this.$emit('update-plugin', this.currentPluginEntities);
    },
    updateSaveButtonVisibility(hide) {
      this.hideSaveButton = hide;
    },
  },
  computed: {
    features() {
      const { title, description, url, icon, integrations } = this.config;
      let features = [];
      if (title || description || url || icon) {
        features.push('general');
      }
      if (integrations) {
        features.push('activation', 'access');
      }
      return features;
    },
  },
  watch: {
    isActive(newValue) {
      if (newValue === false) {
        this.resetCurrentPluginEntitiesIntegrations();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
}
.side {
  padding-top: 6rem;
  width: 200px;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
}
.main {
  flex: 0 0 auto;
  width: calc(100% - 230px);
  :deep() .el-tabs__item {
    color: $grey-5-mayday;
  }
  :deep() .el-tabs__item.is-active {
    color: $blue-mayday;
  }
}

.unsaved-changes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  height: 100%;
  width: 50%;
  overflow: hidden;
  gap: 20px;

  .icon-container {
    display: flex;
    width: 42px;
    height: 42px;
    justify-content: center;
    align-items: center;
    color: $red-5-mayday;
    background-color: rgba(255, 182, 193, 0.5);
    border-radius: 50%;
    padding: 10px;
    font-size: 22px;
  }

  .description {
    text-align: center;
  }

  .button {
    display: flex;
    margin-bottom: 8px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
  }
}
</style>
