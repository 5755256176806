<template>
  <div class="col-12">
    <div class="row mx-0">
      <div :class="displayOnWebParametric" v-if="root.id">
        <div
          class="d-flex flex-row align-items-center pt-2 navigator col-8"
          v-if="!isCase"
        >
          <font-awesome-icon
            :icon="['fad', 'arrow-to-left']"
            class="navigator-icon"
            @click="backToArchive"
          />
          <p class="navigator-text pl-2 mb-0" @click="backToArchive">
            {{ $t('knowledge.archives.back-to-archive') }}
          </p>
        </div>
        <KnowledgeEditorMainTitle
          class="row"
          isArchive
          :content="root"
          :upper-title-text="upperTitleText"
          :editable="false"
          :archiveAuthor="archiveAuthor"
          :createdAt="createdAt"
        />
        <div class="row content-editor">
          <div class="col-12 content-col">
            <ArticleBuilder
              v-if="root.type === 'Article'"
              :editable="false"
              :is-content-editable="false"
              :content="root"
              :is-archive="true"
            />
            <DiagnosticBuilder
              v-if="showDiagnostic"
              :editable="false"
              :is-content-editable="false"
              :root="root"
              :step="step"
              :is-archive="true"
              @focus-node="focusNode"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

// COMPONENTS
import ArticleBuilder from './ParametricArticleBuilder';
import DiagnosticBuilder from './ParametricDiagnosticBuilder/ParametricDiagnosticBuilder';
import KnowledgeEditorMainTitle from './ParametricContentMainTitle/KnowledgeEditorMainTitle';

import KnowledgeMixin from 'mixins/KnowledgeMixin/KnowledgeMixin';

import { appendChildren } from '@/store/knowledgeModule/utils';

export default {
  name: 'parametric-content-archive-builder',
  mixins: [KnowledgeMixin],
  components: {
    KnowledgeEditorMainTitle,
    ArticleBuilder,
    DiagnosticBuilder,
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    displayOnWebParametric() {
      return this.$route.name.includes('web-parametric')
        ? 'col-12'
        : 'container';
    },
    step() {
      return this.content ? this.content : this.root;
    },
    showDiagnostic() {
      const { type } = this.root;
      return ['Diagnostic', 'Step', 'keyStep'].includes(type);
    },
    upperTitleText() {
      return this.formerMainAncestor
        ? `Étape supprimée du diagnostic : ${this.formerMainAncestor.label}`
        : null;
    },
    root() {
      if (this.archiveContent)
        return appendChildren(this.archiveContent, this.archiveDescendents);
      return {};
    },
    isCase() {
      return this.$route.path.includes('case');
    },
    ...mapGetters('knowledgeModule', [
      'archiveContent',
      'archiveDescendents',
      'archiveFormerMainAncestor',
      'editingLanguage',
      'focusKnowledgeId',
    ]),
  },
  watch: {
    editingLanguage() {
      this.getArchive();
    },
  },
  data() {
    return {
      isLoading: false,
      content: {},
      descendents: [],
      formerMainAncestor: null,
      unknownArchive: false,
      archiveAuthor: null,
      createdAt: '',
    };
  },
  created() {
    this.getArchive();
  },
  methods: {
    async getArchive() {
      this.isLoading = true;
      const { caseArchiveId, archiveId } = this.$route.params;
      const { target } = this.$route.query;

      const archive = await this.loadFocusArchiveById({
        archiveId,
      });

      if (archive) {
        this.updateDiagPath([]);
        this.archiveAuthor = archive.author.isDeleted
          ? this.$t('knowledge.contributors.deleted-user')
          : archive.author.username;
        this.createdAt = new Date(archive.createdAt).toLocaleDateString(
          'en-GB',
        );
        this.content = Object.assign(
          {},
          appendChildren(archive.content, archive.descendants),
        );

        this.$emit('change-route-type', archive.content.type);

        if (target) {
          const { caseArchiveParent } = archive;
          if (caseArchiveParent) {
            await this.loadFocusCaseArchiveById({
              caseArchiveId,
              isMain: true,
              target: caseArchiveParent.id,
            });
          }
        }
      }

      this.isLoading = false;
    },
    // UTILS
    focusNode(contentId) {
      const content = this.archiveDescendents.find(
        ({ id }) => contentId === id,
      );
      this.content = appendChildren(
        content || this.archiveContent,
        this.archiveDescendents,
      );
    },
    backToArchive() {
      window.location.href = `/archives/${this.editingLanguage}/${this.focusKnowledgeId}`;
    },
    ...mapActions('kbStore', ['updateDiagPath']),
    ...mapActions('knowledgeModule', [
      'loadFocusArchiveById',
      'loadFocusCaseArchiveById',
      'resetFocusArchive',
    ]),
  },
  beforeDestroy() {
    this.updateDiagPath([]);
    this.resetFocusArchive();
  },
};
</script>

<style lang="scss" scoped>
.navigator {
  cursor: pointer;
  color: $grey-5-mayday;
  margin-left: 14%;
  &:hover {
    color: $blue-mayday;
  }
}
</style>
