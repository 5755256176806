<template>
  <div>
    <div class="settings__public-item">
      <div class="label mb-1">
        {{ $t('knowledge.drawers.content-view-setting.settings.public.cover') }}
        <el-tooltip
          :content="
            $t(
              `knowledge.drawers.content-view-setting.settings.${
                isPublic ? 'public' : 'private'
              }.cover-tooltip`,
            )
          "
          placement="top"
        >
          <font-awesome-icon
            :icon="['fad', 'info-circle']"
            class="info-icon mx-2"
          />
        </el-tooltip>
      </div>
      <!-- IMAGE PLACEHOLDER -->

      <not-allowed-tooltip
        :user-permissions="userPermissions"
        permission-key="canUpdateContent"
      >
        <template v-slot:main-content="{ hasPermissions }">
          <div v-if="!cover" class="w-100">
            <div
              class="img-placeholder-wrapper-empty"
              @click="hasPermissions ? handleModifyCover() : ''"
              @mouseover="hover = true"
              @mouseleave="hover = false"
            >
              <img
                v-if="hover"
                :src="require('assets/upload-placeholder.gif')"
                class="img-placeholder-hover"
              />
              <img
                v-else
                :src="require('assets/upload-placeholder.svg')"
                class="img-placeholder"
              />
            </div>
          </div>
          <div v-else>
            <div class="img-placeholder-wrapper-preview">
              <img :src="cover" class="img-placeholder-hover" />
            </div>
            <div class="settings__public-cta">
              <el-button
                @click="handleDeleteCover"
                :disabled="!hasPermissions"
                >{{
                  $t(
                    'knowledge.drawers.content-view-setting.settings.public.cover-delete',
                  )
                }}</el-button
              >
              <el-button
                @click="handleModifyCover"
                type="primary"
                :disabled="!hasPermissions"
                >{{
                  $t(
                    'knowledge.drawers.content-view-setting.settings.public.cover-modify',
                  )
                }}</el-button
              >
            </div>
          </div>
        </template>
      </not-allowed-tooltip>
    </div>
  </div>
</template>

<script>
import NotAllowedTooltip from '@/components/Commons/NotAllowedTooltip';

export default {
  name: 'content-view-settings-image-cover',
  components: {
    NotAllowedTooltip,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: true,
    },
    cover: {
      type: String,
      required: false,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    handleModifyCover() {
      this.$emit('open-cover-modal');
    },
    handleDeleteCover() {
      this.$emit('delete-cover');
    },
  },
};
</script>

<style lang="scss" scoped>
.settings__public-item {
  margin-bottom: 12px;
}

.label {
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.settings__public-cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}

:deep() .el-button {
  padding: 4px 8px;
  font-size: 12px;
}

.img-placeholder-wrapper-empty {
  height: 100px;
  background-color: $grey-1-mayday;
  border: 1px $grey-4-mayday dashed;
  justify-content: center;
  &:hover {
    border: 1px $blue-mayday dashed;
    background-color: $grey-1-mayday;
    filter: drop-shadow(0px 15px 15px rgba(112, 144, 176, 0.2));
  }
}

.img-placeholder {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 12px 0;
  margin-left: -6px;
}

.img-placeholder-hover {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.img-placeholder-wrapper-preview {
  max-width: 100%;
  display: flex;
  align-items: center;
}
</style>
