export const INTEGRATION_SETTINGS = [
  {
    name: 'Chrome',
    label: 'Mayday Knowledge Widget',
    value: 'chrome',
    description: 'chrome',
    hash: '#chrome',
    type: 'browser',
    logo: ['file', '/img/brand/chrome-logo.svg'],
    active: '',
    features: ['APP', 'PREFERENCES'],
    links: [
      {
        name: 'Extension Chrome',
        href: 'https://chrome.google.com/webstore/detail/mayday/eloemnahbeeiekllcfeombpkokhinkpn',
      },
    ],
  },
  {
    name: 'MaydayWeb',
    label: 'Mayday Desk',
    value: 'mayday-web-preferences',
    description: 'mayday-web',
    hash: '#mayday',
    type: 'knowledge',
    logo: ['file', '/img/brand/mayday-logo-rounded.svg'],
    active: true,
    features: ['APP', 'PREFERENCES'],
    links: [
      {
        name: 'Mayday Web',
        href: 'https://chrome.google.com/webstore/detail/mayday/eloemnahbeeiekllcfeombpkokhinkpn',
      },
    ],
  },
  {
    name: 'Zendesk',
    label: 'Zendesk',
    value: 'zendesk',
    description: 'zendesk',
    hash: '#zendesk',
    type: 'ticketing',
    logo: ['file', '/img/brand/zendesk_200.png'],
    active: '',
    features: ['APP', 'AUTOMATION', 'IMPORT', 'PREFERENCES', 'ATTRIBUTES'],
    links: [
      {
        name: 'Documentation',
        href: 'https://documentation.mayday.fr/parametres/integrations/zendesk',
      },
    ],
  },
  {
    name: 'Intercom',
    label: 'Intercom',
    value: 'intercom',
    description: 'intercom',
    hash: '#intercom',
    type: 'ticketing',
    logo: ['file', '/img/brand/intercom_200.png'],
    active: '',
    features: ['AUTOMATION', 'ATTRIBUTES'],
    links: [],
  },
  {
    name: 'Salesforce',
    label: 'Salesforce',
    value: 'salesforce',
    description: 'salesforce',
    hash: '#salesforce',
    type: 'ticketing',
    logo: ['file', '/img/brand/salesforce-logo.png'],
    active: '',
    features: ['AUTOMATION', 'IMPORT', 'ATTRIBUTES'],
    links: [],
  },
  {
    name: 'Salesforce 2',
    label: 'Salesforce 2',
    value: 'salesforce_2',
    description: 'salesforce',
    hash: '#salesforce_2',
    type: 'Ticketing',
    logo: ['file', '/img/brand/salesforce-logo.png'],
    active: '',
    features: ['AUTOMATION', 'IMPORT', 'ATTRIBUTES'],
    links: [],
  },
  {
    name: 'Iadvize',
    label: 'iAdvize',
    description: 'iadvize',
    value: 'iadvize',
    hash: '#iadvize',
    type: 'ticketing',
    logo: ['url', 'https://www.iadvize.com/hubfs/iAdvize_icon.webp'],
    active: '',
    features: ['AUTOMATION', 'ATTRIBUTES', 'PREFERENCES'],
    links: [],
  },
  {
    name: 'Notion',
    label: 'Notion (Beta)',
    description: 'notion',
    value: 'notion',
    hash: '#notion',
    type: 'knowledge',
    logo: ['file', '/img/brand/notion-logo.png'],
    active: '',
    features: ['IMPORT'],
    links: [],
  },
  {
    name: 'Slack',
    label: 'Slack (Beta)',
    description: 'slack',
    value: 'slack',
    hash: '#slack',
    type: 'messaging',
    logo: [
      'url',
      'https://cdn.bfldr.com/5H442O3W/at/pl546j-7le8zk-6gwiyo/Slack_Mark.svg?auto=webp&format=svg',
    ],
    active: '',
    features: ['APP', 'USERS'],
    links: [],
  },
  {
    name: 'Custom APIs',
    label: 'Custom APIs',
    description: 'custom-apis',
    value: 'custom-apis',
    hash: '#custom-apis',
    type: 'api',
    logo: ['file', '/img/brand/custom-apis.png'],
    active: true,
    features: ['API'],
    links: [],
  },
];
