<template>
  <div class="d-flex flex-column justify-content-start relative">
    <div v-if="loading" class="loader">
      <loader-line :loading="loading" />
    </div>
    <div class="detail-title">
      {{ title }}
    </div>
    <slot name="detail-row-content"></slot>
  </div>
</template>

<script>
import LoaderLine from 'components/Loaders/LoaderLine.vue';

export default {
  name: 'task-focus-details-row',
  components: { LoaderLine },
  props: {
    title: String,
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-title {
  font-size: 12px;
  font-weight: 600;
  color: $grey-6-mayday;
}

.loader {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}
</style>
