<template>
  <div class="content-view-setting-drawer__container" @close="closeDrawer">
    <!-- MAIN -->
    <div class="content-view-setting-drawer__wrapper d-flex flex-column">
      <DrawerHeader
        :title="$t('knowledge.drawers.content-view-setting.main-title')"
        :desc="$t('knowledge.drawers.content-view-setting.main-desc')"
        @close="closeDrawer"
      />
      <div class="content-view-setting-drawer__content d-flex flex-column px-4">
        <!-- --------------- HEADER --------------- -->

        <ContentViewSettingsHeader :content="content" />

        <ContentViewSettingsMenu :tab="tab" @update-tab="tab = $event" />

        <!-- -------------- GENERAL --------------- -->

        <ContentViewSettingsGeneral
          v-show="tab === 'general'"
          :content="content"
        />

        <!-- --------------- POSITION --------------- -->

        <ContentViewSettingsPosition
          v-show="tab === 'settings'"
          :content-settings="contentViewSetting"
          :knowledge="chosenKnowledge"
          :user-permissions="content.userPermissions"
          @update-product-filters="updateProductFilters"
          @update-knowledge="resetKnowledge"
          @update-case="updateCaseParameters"
          @update-labels="updateLabels"
          @open-modal="modal = true"
          class="content-view-setting-drawer__section"
        />

        <!-- -------------- ACCESS --------------- -->
        <ContentViewSettingsAccess
          v-show="tab === 'access'"
          :group-ids="contentViewSetting.accessRestrictions"
          :user-groups="userGroups"
          :publicData="content.publicData"
          :is-published="content.published"
          :cover="content.cover"
          :knowledge-is-public="focusKnowledgeIsPublic"
          :user-permissions="content.userPermissions"
          :isCompanyFavorite="contentViewSetting.isCompanyFavorite"
          @update-group-ids="updateGroupIds"
          @open-cover-modal="cover = true"
          @delete-cover="handleDeleteContentCover"
          @update-company-favorite="handleUpdateCompanyFavorite"
          class="content-view-setting-drawer__section"
        />

        <!-- -------------- PLUGINS --------------- -->
        <ContentViewSettingsPlugins
          v-show="tab === 'plugins'"
          :relatedContents="content.enrichedRelatedContents"
          :user-permissions="content.userPermissions"
          @update-related-contents="setRelatedContents($event)"
          class="content-view-setting-drawer__section"
        />
      </div>
    </div>
    <!-- PARAMETRIC MODAL -->
    <ContentViewSettingModal
      v-if="modal"
      :open="modal"
      :content="content"
      @update-content-view-setting="handleUpdateModal"
      @close="modal = false"
    />
    <!-- COVER MODAL -->
    <IconEditionModal
      v-if="cover"
      :display="cover"
      :aspect-ratio="4.5"
      :title="'knowledge.drawers.content-view-setting.settings.public.cover-modal-title'"
      :editionOptions="{ emoji: false, fa: false, upload: true, link: false }"
      @choice="handleUpdateContentCover($event)"
      @delete="deleteContentCover"
      @close="cover = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import DrawerHeader from '../DrawerHeader';
import ContentViewSettingsHeader from './ContentViewSettingsHeader';
import ContentViewSettingsMenu from './ContentViewSettingsMenu/ContentViewSettingsMenu';
import ContentViewSettingsGeneral from './ContentViewSettingsMenu/ContentViewSettingsGeneral';
import ContentViewSettingsPosition from './ContentViewSettingsMenu/ContentViewSettingsPosition';
import ContentViewSettingsAccess from './ContentViewSettingsMenu/ContentViewSettingsAccess';
import ContentViewSettingsPlugins from './ContentViewSettingsMenu/ContentViewSettingsPlugins';

import ContentViewSettingModal from '@/components/Modals/ContentViewSettingModal/ContentViewSettingModal.vue';
import IconEditionModal from '@/components/Modals/IconEditionModal/IconEditionModal.vue';

export default {
  name: 'content-view-setting-drawer',
  components: {
    DrawerHeader,
    ContentViewSettingsHeader,
    ContentViewSettingsMenu,
    ContentViewSettingsGeneral,
    ContentViewSettingsPosition,
    ContentViewSettingsAccess,
    ContentViewSettingsPlugins,
    ContentViewSettingModal,
    IconEditionModal,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    this.contentViewSetting.parameters = this.getParametersFromContent();
    this.contentViewSetting.caseParameters = this.content.caseParameters;
    this.contentViewSetting.labels = this.content.labels;
    this.contentViewSetting.accessRestrictions =
      this.content.accessRestrictions;
    this.contentViewSetting.isCompanyFavorite = await this.getIsCompanyFavorite(
      this.content.id,
    );
  },
  computed: {
    chosenKnowledge() {
      const knowledge = this.contentViewSetting.parameters.find(
        (c) => c.key === 'knowledge',
      );
      return knowledge ? knowledge.values[0] : null;
    },
    ...mapGetters(['isParametric']),
    ...mapGetters('knowledgeModule', ['focusKnowledgeIsPublic']),
    ...mapState(['userGroups']),
  },
  data() {
    return {
      contentViewSetting: {
        parameters: [],
        caseParameters: ['0'],
        labels: [],
        accessRestrictions: [],
        isCompanyFavorite: false,
      },
      cases: {},
      parametersLabels: [],
      parametersLabelCategories: [],
      tab: 'general',
      modal: false,
      cover: false,
    };
  },
  validations: {
    contentViewSetting: {
      title: {
        required,
      },
      message: {
        required,
      },
      accessRestrictions: {
        required,
      },
      caseParameters: {
        required,
      },
    },
  },
  methods: {
    closeDrawer() {
      this.$emit('close');
    },
    handleUpdateModal(payload) {
      this.$emit('update-content-view-setting', payload);
      this.$emit('close');
    },
    resetKnowledge() {
      this.contentViewSetting.caseParameters = [];
    },
    updateProductFilters(productFilters) {
      // reset when isParametric
      this.contentViewSetting.parameters = productFilters;
    },
    updateCaseParameters(caseParameters) {
      this.contentViewSetting.caseParameters = caseParameters;
    },
    async updateLabels(labels) {
      this.contentViewSetting.labels = labels;
      this.updateContentViewSetting();
    },
    updateGroupIds(groupIds) {
      this.contentViewSetting.accessRestrictions = groupIds;
    },
    async updateContentViewSetting() {
      const knowledgeSetting = this.contentViewSetting.parameters.find(
        (c) => c.key === 'knowledge',
      );
      const parameters = this.contentViewSetting.parameters.filter(
        (p) => p.key !== 'knowledge',
      );
      const knowledge = knowledgeSetting.values[0];
      const newSettings = {
        ...this.contentViewSetting,
        labels: this.contentViewSetting.labels.map((l) => l.id),
        knowledge,
        parameters,
      };
      await this.$emit('update-content-view-setting', newSettings);
    },
    getParametersFromContent() {
      return [
        { key: 'knowledge', values: [this.content.knowledgeId] },
        ...Object.keys(this.content.parameters).map((val) => ({
          key: val,
          values: this.content.parameters[val],
        })),
      ];
    },
    async handleUpdateContentCover(cover) {
      try {
        const res = await this.setContentCover({
          contentId: this.content.id,
          cover,
        });
        if (!res) throw new Error('UPLOAD_FAILED');
        this.$message({
          message: `Le contenu a été mis à jour`,
          type: 'success',
        });
        this.cover = false;
      } catch (e) {
        this.$message({
          message: `La mise a jour a rencontré un problème`,
          type: 'error',
        });
      }
    },
    async handleDeleteContentCover() {
      await this.setContentCover({
        contentId: this.content.id,
        cover: { value: null },
      });
    },

    handleUpdateCompanyFavorite(val) {
      this.updateCompanyFavorite({
        isCompanyFavorite: val,
        entityId: this.content.id,
      });
    },
    ...mapActions('knowledgeModule', ['setContentCover', 'setRelatedContents']),
    ...mapActions('adminUserDirectoryModule', [
      'updateCompanyFavorite',
      'getIsCompanyFavorite',
    ]),
  },
  watch: {
    content() {
      this.contentViewSetting.parameters = this.getParametersFromContent();
      this.contentViewSetting.caseParameters = this.content.caseParameters;
      this.contentViewSetting.labels = this.content.labels;
      this.contentViewSetting.accessRestrictions =
        this.content.accessRestrictions;
    },
  },
};
</script>

<style lang="scss" scoped>
// reset selecteur
:deep() .el-select__input {
  margin-left: 5px;
}
:deep() .el-select .el-select__tags .el-tag {
  padding: 0.5rem;
}
:deep() .el-select .el-tag__close.el-icon-close::before {
  margin-top: 1px;
}
:deep() .el-drawer__body {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-view-setting-drawer__container {
  background-color: white;
  width: 400px;
  height: 100%;
  border-left: 1px solid $grey-3-mayday;
  z-index: 10;
  box-shadow: 1px 1px 2px rgba(112, 144, 176, 0.25),
    -1px -1px 1px rgba(112, 144, 176, 0.25);
  overflow: auto;
  flex: none;
}

.content-view-setting-drawer__wrapper {
  overflow: auto;
  min-height: 80vh;
  max-height: 100vh;
  height: 100%;
}

.content-view-setting-drawer__footer {
  z-index: 1;
}

.content-view-setting-drawer__section {
  padding: 0;
}

:deep() .parameter-row {
  padding-bottom: 0.5rem;
}

.read-groups {
  border: 0.5px solid #ced4da;
  border-radius: 2px;
  background: $grey-1-mayday;
}
</style>
