<template>
  <div class="side-container">
    <div class="side-title">Permissions</div>

    <div class="side-item">
      <RolesDrawerSideOverviewItem
        v-for="permissionItem in filteredPermissionItems"
        :key="permissionItem.title"
        :permission-item="permissionItem"
        :role-permissions="rolePermissions"
        :company-preferences="companyPreferences"
      />
    </div>

    <div class="side-title">
      {{ $t('settings.permissions.role-drawer.side-bar.links') }}
    </div>

    <div class="side-item">
      <a
        href="https://documentation.mayday.fr/parametres/utilisateurs-and-permissions/roles"
        target="_blank"
      >
        {{ $t('settings.permissions.role-drawer.side-bar.documentation') }}
      </a>
    </div>
  </div>
</template>

<script>
import RolesDrawerSideOverviewItem from './RolesDrawerSideOverviewItem';
import { mapGetters } from 'vuex';

export default {
  name: 'roles-drawer-side',
  components: { RolesDrawerSideOverviewItem },
  props: {
    rolePermissions: {
      type: Array,
      default: () => [],
    },
    permissionItems: {
      type: Array,
      default: () => [],
    },
    companyPreferences: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['hasCompanyPreferenceWithValue']),
    filteredPermissionItems() {
      return this.permissionItems
        .filter((item) => {
          if (!item.companyPreferences) return true;
          return item.companyPreferences.every((preference) =>
            this.hasCompanyPreferenceWithValue(preference),
          );
        })
        .map((item) => {
          return {
            ...item,
            subItems: item.subItems.filter((subItem) => {
              if (!subItem.companyPreferences) return true;
              return subItem.companyPreferences.every((preference) =>
                this.hasCompanyPreferenceWithValue(preference),
              );
            }),
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.side-container {
  width: 100%;
  padding-top: 4.5rem;
  padding-inline: 30px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.side-title {
  font-size: 16px;
  font-weight: bold;
  padding: 8px 0;
}

.side-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.side-item {
  font-size: 12px;
  font-weight: normal;
  padding: 4px 0;
  span {
    margin-left: 10px;
  }
}
</style>
