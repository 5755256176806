var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editor-with-attachments w-100",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.handleExitNewComment.apply(null, arguments)},"!keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.handleNewComment.apply(null, arguments)}}},[_c('div',{staticClass:"editor-new-comment flex-row"},[_c('div',{staticClass:"input-editor w-100"},[_c('MessageEditor',{ref:"message-editor",attrs:{"body":_vm.body,"editable":_vm.editable},on:{"input":function($event){_vm.body = $event}}})],1),_c('div',{staticClass:"flex align-items-center"},[_c('button',{staticClass:"editor-send-wrapper editor-send-wrapper__enable mr-1",on:{"click":_vm.insertMentions}},[_c('font-awesome-icon',{staticClass:"editor-send editor-send__enable",attrs:{"icon":['fas', 'at']}})],1),(_vm.showAttachments)?_c('AddAttachment',{ref:"add-attachment",staticClass:"editor-send-wrapper mr-1",attrs:{"disabled":_vm.fileInputDisable,"attachment-loading":_vm.attachmentLoading},on:{"new-attachment":_vm.handleFileChange,"disable-click":function($event){return _vm.showDisableFileUpload(
            'task-view.task-focus-modal.upload-error.disable-file-upload',
          )}},scopedSlots:_vm._u([{key:"slot-button",fn:function(){return [_c('div',{staticClass:"editor-send-wrapper",class:_vm.attachmentLoading
                ? 'editor-send-wrapper__disable'
                : 'editor-send-wrapper__enable'},[_c('font-awesome-icon',{staticClass:"editor-send",class:_vm.attachmentLoading
                  ? 'editor-send__disable'
                  : 'editor-send__enable',attrs:{"icon":['fas', 'paperclip']}})],1)]},proxy:true}],null,false,1946596344)}):_vm._e(),_c('div',{staticClass:"editor-send-wrapper",class:_vm.isSendable
            ? 'editor-send-wrapper__enable'
            : 'editor-send-wrapper__disable'},[_c('font-awesome-icon',{staticClass:"editor-send",class:_vm.isSendable ? 'editor-send__enable' : 'editor-send__disable',attrs:{"icon":['fas', 'paper-plane']},on:{"click":_vm.handleNewComment}})],1)],1)]),_c('AttachmentList',{attrs:{"attachments":_vm.attachments,"isInput":true,"displayDisableFileUpload":_vm.displayDisableFileUpload,"errorText":_vm.errorText},on:{"disable-click":function($event){return _vm.showDisableFileUpload()},"delete-attachment":_vm.handleDeleteAttachment}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }