<template>
  <div>
    <div class="element-title">
      {{ $t('settings-layout.menu-data.mayday-account') }}
    </div>
    <div class="element-description mb-3">
      {{ $t('settings-layout.menu-data.mayday-account-subtitle') }}
    </div>
    <hr class="col-auto my-2" />
    <!-- LOGO -->
    <SettingsImage
      v-bind="logoInput"
      :value="logo"
      @update="updateLogo($event)"
      @delete="deleteLogo()"
    />
    <!-- COLORS -->
    <SettingsColor
      :value="color || '#0081F9'"
      class="mr-3"
      v-bind="colorInput"
      @update="updateCompanyColor($event, 'PRIMARY_COLOR')"
    />
    <!-- USER -->
    <hr class="col-auto my-2" />
    <!-- USER INPUTS -->
    <SettingsInput
      v-for="input in inputs"
      :key="input.path"
      v-bind="input"
      :value="model[input.path]"
      @update="handleUpdate(input.path, $event)"
    />

    <!-- SUB-SECTION PASSWORD-->
    <SettingsPasswordInput
      v-bind="password"
      @update="handleUpdate(input.path, $event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import SettingsInput from '@/components/Settings/SettingsInput';
import SettingsColor from '@/components/Settings/SettingsColor';
import SettingsImage from '@/components/Settings/SettingsImage';
import SettingsPasswordInput from '@/components/Settings/SettingsPasswordInput';

export default {
  name: 'settingsCompany',
  components: {
    SettingsInput,
    SettingsPasswordInput,
    SettingsColor,
    SettingsImage,
  },
  data() {
    return {
      inputs: [
        {
          path: 'username',
          description: '',
          title: 'settings.company.user-settings.username',
          placeholder: 'settings.company.user-settings.username',
        },
        {
          path: 'email',
          description: '',
          title: 'settings.company.user-settings.email',
          placeholder: 'settings.company.user-settings.email',
          editable: false,
        },
        {
          path: 'jobtitle',
          description: '',
          title: 'settings.company.user-settings.jobtitle',
          placeholder: 'settings.company.user-settings.jobtitle',
        },
      ],
      password: {
        path: 'password',
        description: '',
        value: '*************',
        title: 'settings.company.user-settings.password',
        instructions: 'settings.company.user-settings.password-instructions',
        match: 'settings.company.user-settings.passwords-do-not-match',
        repeatLabel: 'settings.company.user-settings.password',
        placeholder: 'settings.company.user-settings.password',
        repeatPlaceholder: 'settings.company.user-settings.password',
      },
      colorInput: {
        path: 'color',
        title: 'settings.company.company-settings.color',
      },
      logoInput: {
        path: 'logo',
        title: 'settings.company.company-settings.logo',
        description: 'settings.company.company-settings.logo-description',
      },
      model: {
        username: '',
        email: '',
        jobtitle: '',
        password: '',
      },
      logo: '',
      color: null,
    };
  },
  computed: {
    ...mapGetters(['companyPrimaryColorPreference']),
  },
  async created() {
    const { username, email, jobtitle, company } = await this.getMe();
    this.logo = company.logo;
    this.model = Object.assign(this.model, { username, email, jobtitle });
  },
  mounted() {
    this.color = this.companyPrimaryColorPreference
      ? this.companyPrimaryColorPreference.value
      : null;
  },
  methods: {
    handleUpdate(path, value) {
      this.model = Object.assign(this.model, {
        [path]: value,
      });
      return this.updateSettings();
    },
    async updateSettings() {
      const res = await this.updateUserSettings(this.model);

      if (!res)
        return this.$message({
          type: 'error',
          message: this.$t('settings.company.error'),
        });

      const { username, email, jobtitle } = res;
      this.model = Object.assign(this.model, {
        username,
        email,
        jobtitle,
      });

      return this.$message({
        type: 'success',
        message: this.$t('settings.company.success'),
      });
    },
    async deleteLogo() {
      this.logo = await this.deleteCompanyLogo();
    },
    async updateLogo(url) {
      if (url) {
        this.logo = await this.updateCompanyLogo(url);
      }
    },
    updateCompanyColor(val) {
      if (this.companyPrimaryColorPreference) {
        if (val === null) {
          this.deleteCompanyPreferences({
            preferenceIds: [this.companyPrimaryColorPreference.id],
          });
        } else {
          this.updateCompanyPreferencesValue({
            preferenceId: this.companyPrimaryColorPreference.id,
            preferenceValue: String(val),
          });
        }
      } else {
        this.addCompanyPreferences({
          preferenceType: 'PRIMARY_COLOR',
          preferenceValue: val,
        });
      }
    },
    ...mapActions('adminModule', [
      'getMe',
      'updateUserSettings',
      'updateCompanyLogo',
      'deleteCompanyLogo',
    ]),
    ...mapActions([
      'addCompanyPreferences',
      'updateCompanyPreferencesValue',
      'deleteCompanyPreferences',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
</style>
