<template>
  <div class="side-container">
    <div class="side-toggle">
      <el-tooltip
        :content="
          this.$t(
            'settings-layout.menu-data.plugins.advanced-settings.drawer.side-bar.toggle-active',
          )
        "
        effect="light"
        :visible-arrow="false"
        :disabled="!isActive"
        placement="right-end"
      >
        <el-switch
          v-model="localSwitchValue"
          @change="$emit('switched', $event)"
        ></el-switch>
      </el-tooltip>
    </div>
    <div v-if="integrations.length > 0">
      <div class="side-title">
        {{
          $t(
            'settings-layout.menu-data.plugins.advanced-settings.drawer.side-bar.visibility',
          )
        }}
      </div>
      <div
        class="side-item"
        v-for="integration in integrations"
        :key="integration.name"
      >
        <font-awesome-icon
          :icon="['fas', 'check']"
          :color="integration.isActive ? '#8CB369' : '#ced4da'"
        />
        <span> {{ integration.name }}</span>
      </div>
    </div>
    <div v-if="links.length > 0">
      <div class="side-title">
        {{
          $t(
            'settings-layout.menu-data.plugins.advanced-settings.drawer.side-bar.links',
          )
        }}
      </div>

      <div class="side-item" v-for="link in links" :key="link.name">
        <a :href="link.href" target="_blank">{{ link.name }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'plugin-advanced-settings-drawer-side',
  components: {},
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    currentPluginEntities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localSwitchValue: this.isActive,
      links: [
        {
          name: 'Documentation',
          href: 'https://documentation.mayday.fr/contents/Configurer-un-plugin-personnalise-Avam7zvO',
        },
      ],
    };
  },
  methods: {
    handleChange() {
      this.localSwitchValue = !this.localSwitchValue;
      this.$emit('switched', this.localSwitchValue);
    },
  },
  computed: {
    integrations() {
      return this.currentPluginEntities[0].integrations;
    },
  },
};
</script>

<style lang="scss" scoped>
.side-container {
  width: max-content;
}

.side-title {
  font-size: 16px;
  font-weight: bold;
  padding: 8px 0;
}

.side-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.side-item {
  font-size: 12px;
  font-weight: normal;
  padding: 4px 0;

  span {
    margin-left: 10px;
  }
}
</style>
