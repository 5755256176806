var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update-user-label-modal",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.handleExitNewComment.apply(null, arguments)}}},[_c('modal',{attrs:{"show":_vm.localDisplay},on:{"update:show":function($event){_vm.localDisplay=$event},"close":function($event){return _vm.$emit('close')}}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loader),expression:"loader"}],staticClass:"d-flex flex-row wrapper"},[_c('div',{staticClass:"d-flex flex-column task-details"},[(_vm.task.type)?_c('task-focus-details',{staticClass:"task-details-component",attrs:{"task":_vm.task},on:{"handle-update-assignee":function($event){return _vm.$emit('handle-update-assignee', $event)},"handle-update-status":function($event){return _vm.$emit('handle-update-status', $event)},"add-upvote":function($event){return _vm.$emit('add-upvote', $event)},"remove-upvote":function($event){return _vm.$emit('remove-upvote', $event)},"handle-is-archive":function($event){return _vm.$emit('handle-is-archive', $event)},"update-content-status":function($event){return _vm.bulkUpdateStatus($event)},"close":function($event){return _vm.$emit('close')},"update-rating":function($event){return _vm.$emit('update-rating', $event)},"delete-rating":function($event){return _vm.$emit('delete-rating', $event)}}}):_vm._e(),(!_vm.task.isArchive)?_c('div',{staticClass:"task-archive"},[_c('el-button',{staticClass:"archive-button",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$emit('handle-is-archive', { taskId: _vm.task.id, isArchive: true })}}},[_vm._v(_vm._s(_vm.$t('task-view.task-focus-modal.delete'))+" ")])],1):_c('div',{staticClass:"task-archive"},[_c('el-button',{staticClass:"archive-button",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$emit('handle-is-archive', {
                taskId: _vm.task.id,
                isArchive: false,
              })}}},[_vm._v(_vm._s(_vm.$t('task-view.task-focus-modal.restore'))+" ")])],1)],1),(!_vm.loader)?_c('div',{staticClass:"d-flex flex-column task-comments"},[_c('div',{staticClass:"d-flex flex-row justify-content-center align-items-center"},[_c('div',{staticClass:"task-date mb-2"},[_c('font-awesome-icon',{staticClass:"icon-calendar mr-2",attrs:{"icon":['fal', 'calendar-week']}}),_c('span',{staticClass:"sm"},[_vm._v(_vm._s(_vm.task.createdAt))])],1)]),_c('div',{staticClass:"d-flex flex-column px-3 first-comment"},[_c('div',{staticClass:"d-flex flex-row align-items-top justify-content-between mr-2"},[_c('div',{staticClass:"user-wrapper"},[(_vm.isInfo || _vm.isObsolete)?_c('img',{staticClass:"logo-admin",attrs:{"src":require("@/assets/mayday-logo.png"),"alt":"mayday-logo-rounded"}}):_c('user-avatar',{staticClass:"avatar",attrs:{"username":_vm.authorUsername,"size":"xsmall"}})],1),_c('div',{staticClass:"d-flex flex-column thread"},[_c('div',{class:['author d-flex flex-row align-items-center']},[_c('div',[_vm._v(" "+_vm._s(_vm.bodyAuthorName)+" ")])]),_c('MaydayFeedbackEditor',{staticClass:"d-flex flex-row body",attrs:{"message":_vm.isObsolete
                    ? _vm.$t(this.task.body.path, {
                        title: this.task.body.title,
                        dueDate: this.task.body.dueDate,
                      })
                    : _vm.task.body,"editable":false}}),(_vm.task.attachments)?_c('AttachmentList',{staticClass:"mb-1",attrs:{"attachments":_vm.task.attachments,"isInput":false}}):_vm._e(),_c('div',{staticClass:"d-flex flex-row align-items-center mb-2"},[(_vm.task.source !== 'SELFCARE')?_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('el-tooltip',[_c('template',{slot:"content"},[(_vm.taskHasUpvotes)?_c('div',_vm._l((_vm.task.upvotes),function(upvoter){return _c('div',{key:upvoter.id},[_vm._v(" "+_vm._s(upvoter.username)+" ")])}),0):_c('div',[_vm._v(" "+_vm._s(_vm.$t('task-view.empty-upvote-tooltip'))+" ")])]),_c('div',{staticClass:"d-flex flex-row align-items-center upvote-badge mr-3"},[_c('font-awesome-icon',{class:[
                          _vm.taskHasUpvotes
                            ? 'with-reactions'
                            : 'without-reaction',
                          'upvote-icon',
                        ],attrs:{"icon":[_vm.taskHasUpvotes ? 'fas' : 'fal', 'heart']},on:{"click":_vm.handleUpvote}}),(_vm.taskHasUpvotes)?_c('div',{staticClass:"upvote-count ml-2"},[_vm._v(" "+_vm._s(_vm.task.upvoteCount)+" ")]):_vm._e()],1)],2)],1):_vm._e(),(
                    !_vm.EditNewComment &&
                    !_vm.taskHasComments &&
                    _vm.currentTaskAttributes.answer &&
                    _vm.task.source !== 'SELFCARE'
                  )?_c('el-button',{staticClass:"response-button",attrs:{"type":"primary","plain":"","size":"mini"},on:{"click":function($event){$event.stopPropagation();return _vm.handleEditNewComment(false)}}},[_vm._v(" "+_vm._s(_vm.$t('task-view.task-focus-modal.answer'))+" ")]):_vm._e(),(
                    !_vm.EditNewComment &&
                    !_vm.taskHasComments &&
                    _vm.currentTaskAttributes.answer &&
                    _vm.task.source !== 'SELFCARE'
                  )?_c('el-button',{staticClass:"response-button",attrs:{"type":"info","plain":"","size":"mini"},on:{"click":function($event){$event.stopPropagation();return _vm.handleEditNewComment(true)}}},[_vm._v(" "+_vm._s(_vm.$t('task-view.task-focus-modal.add-note'))+" ")]):_vm._e()],1)],1)]),(!_vm.loader)?_c('task-focus-thread',{staticClass:"thread-component",attrs:{"comments":_vm.task.comment,"isEditing":_vm.EditNewComment},on:{"delete-comment":_vm.handleDeleteComment}}):_vm._e(),(
              !_vm.EditNewComment &&
              _vm.taskHasComments &&
              _vm.currentTaskAttributes.answer
            )?_c('div',{staticClass:"d-flex align-items-center align-response"},[_c('el-button',{staticClass:"response-button",attrs:{"type":"primary","plain":"","size":"mini"},on:{"click":function($event){$event.stopPropagation();return _vm.handleEditNewComment(false)}}},[_vm._v(" "+_vm._s(_vm.$t('task-view.task-focus-modal.answer'))+" ")]),_c('el-button',{staticClass:"response-button",attrs:{"type":"info","plain":"","size":"mini"},on:{"click":function($event){$event.stopPropagation();return _vm.handleEditNewComment(true)}}},[_vm._v(" "+_vm._s(_vm.$t('task-view.task-focus-modal.add-note'))+" ")])],1):_vm._e(),(_vm.EditNewComment)?_c('div',{staticClass:"d-flex flex-column align-items-left editor-wrapper"},[(_vm.taskHasComments)?_c('el-divider',{attrs:{"direction":"vertical"}}):_vm._e(),_c('div',{staticClass:"editor-under-wrapper",class:{
                'editor-under-wrapper__is-internal': _vm.newCommentIsInternal,
              }},[_c('div',{staticClass:"avatar-column"},[_c('div',{staticClass:"avatar-wrapper"},[_c('user-avatar',{staticClass:"avatar mr-2",attrs:{"username":_vm.username,"size":"xsmall"}})],1)]),_c('div',{staticClass:"w-100"},[_c('InputEditor',{staticClass:"editor-input",attrs:{"reset":_vm.resetInput,"editable":true,"showAttachments":true},on:{"handle-new-comment":_vm.handleNewComment}}),_c('div',{staticClass:"echap-info"},[_vm._v(" "+_vm._s(_vm.$t('task-view.task-focus-modal.press-esc'))+" ")])],1)])],1):_vm._e()],1)]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }