<template>
  <div class="subitem-container">
    <div
      v-for="subItem in filteredSubItems"
      :key="subItem.title"
      class="subitem-title"
      :class="{ 'subitem-title-hidden': subItem.hidden }"
    >
      <div
        class="state-indicator"
        :class="computeState(subItem.relatedPermissions)"
      />
      {{ $t(subItem.title) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'role-permissions-state',
  props: {
    subItems: {
      type: Array,
      default: () => [],
    },
    rolePermissions: {
      type: Array,
      default: () => [],
    },
    companyPreferences: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    computeState(relatedPermissions) {
      const length = this.rolePermissions.filter((permission) =>
        relatedPermissions.includes(permission.rolePermission),
      ).length;
      if (length === 0) return 'none';
      if (length === relatedPermissions.length) return 'full';
      return 'partial';
    },
  },
  computed: {
    filteredSubItems() {
      return this.subItems.filter((subItem) => {
        if (!subItem.companyPreferences) return true;

        const everyPreferences = subItem.companyPreferences.every(
          (preference) =>
            this.companyPreferences.find((p) => p.type === preference),
        );

        return everyPreferences;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.subitem-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.state-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $grey-4-mayday;
  display: inline-block;
  &.full {
    background-color: $green-mayday;
  }
  &.partial {
    border: 1px solid $green-mayday;
  }
  margin-right: 2px;
}
.subitem-title {
  font-size: 14px;
}

.subitem-title-hidden {
  filter: grayscale(1);
  opacity: 0.5;
}
</style>
