<template>
  <translation-dropdown
    :languages="orderedSetLanguages"
    :label-translations="labelTranslations"
    :status-translations="editable ? statusTranslations : {}"
    :current-language="editingLanguage"
    :editable="editable"
    :disabled="setLanguages.length === 1 && !editable"
    :right-direction-dropdown="rightDirectionDropdown"
    :loading-languages="loadingLanguages"
    @change-language="switchEditingLanguage($event)"
    @unset-language="$emit('unset-language', $event)"
    @open-translation-dropdown="$emit('open-translation-dropdown')"
  >
    <div v-if="editable">
      <TranslationItem
        class="set-language-item"
        data-testid="set-language-item"
        v-for="language in availableLanguages"
        :key="language"
        :language="language"
        :itemLabel="$t('translation.set-content')"
        :status="itemStatus"
        editing-action="set-language"
        @click.native.prevent.stop="$emit('set-new-language', language)"
        @set-language="$emit('set-new-language', $event)"
      />
    </div>
  </translation-dropdown>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TranslationDropdown from '@/components/TranslationDropdown';
import TranslationItem from '@/components/TranslationItem.vue';

export default {
  name: 'translation-dropdown-wrapper',
  components: {
    TranslationDropdown,
    TranslationItem,
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    setLanguages: {
      type: Array,
      default: () => [],
    },
    labelTranslations: {
      type: Object,
      default: () => ({}),
    },
    statusTranslations: {
      type: Object,
      default: () => ({}),
    },
    rightDirectionDropdown: {
      type: Boolean,
      default: false,
    },
    loadingLanguages: {
      type: Array,
      default: () => [],
    },
    translationDropdownLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    availableLanguages() {
      return this.focusKnowledgeLanguages.filter(
        (lang) =>
          !this.setLanguages.includes(lang) &&
          !this.loadingLanguages.includes(lang),
      );
    },
    orderedSetLanguages() {
      return this.focusKnowledgeLanguages.reduce((acc, lang) => {
        if (
          this.setLanguages.includes(lang) ||
          this.loadingLanguages.includes(lang)
        )
          acc.push(lang);
        return acc;
      }, []);
    },
    itemStatus() {
      return Object.keys(this.statusTranslations).length ? 'no-status' : null;
    },
    ...mapGetters('knowledgeModule', [
      'focusKnowledgeLanguages',
      'editingLanguage',
    ]),
  },
  methods: {
    ...mapActions('knowledgeModule', ['switchEditingLanguage']),
  },
};
</script>

<style lang="scss" scoped>
.set-language-item {
  color: $grey-6-mayday;
  background-color: $grey-2-mayday;

  &:not(:last-child) {
    border-bottom: 1px solid $grey-6-mayday;
  }

  &:hover {
    color: $black-mayday;
    background-color: $grey-3-mayday;
  }

  .item-label {
    color: $grey-7-mayday;
  }
}
</style>
