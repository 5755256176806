<template>
  <el-collapse
    v-model="activeCollapseItems"
    class="notification-center-collapse"
  >
    <el-collapse-item
      v-for="item in items"
      :key="item.id"
      :name="item.id"
      class="collapse-item"
    >
      <template slot="title" class="row">
        <div class="col-8 nopadding title-col">
          <strong class="mr-2">{{
            formatTitleString(item.metadata.label)
          }}</strong>

          <span v-if="item.metadata.urgent" class="pr-2">
            <el-tooltip
              :content="
                $t(
                  item.metadata.isVisible
                    ? 'notification-center.main.row.urgent'
                    : 'notification-center.main.row.urgent-not-visible',
                )
              "
              placement="top"
            >
              <font-awesome-icon
                class="urgent-icon"
                :icon="['fa', 'exclamation-circle']"
                :class="item.metadata.isVisible ? '' : 'not-visible'"
              ></font-awesome-icon>
            </el-tooltip>
          </span>

          <span class="mr-2 vertical-divider"></span>
          <span class="collapse-title-text nopadding">
            <small>
              <font-awesome-icon
                :icon="['fa', 'user-edit']"
                class="mr-1"
              ></font-awesome-icon>
              {{ item.author }}
            </small>
          </span>
        </div>
        <div class="col-3 nopadding info-col text-right collapse-title-text">
          <small>
            <font-awesome-icon
              :icon="['fa', 'calendar-day']"
              class="mr-1"
            ></font-awesome-icon>
            {{ applyDateParse(item.createdAt) }}
          </small>
        </div>
      </template>

      <div class="mb-2" v-if="activeCollapseItems.includes(item.id)">
        <MaydayNotificationEditor
          class="notification__viewer"
          :message="item.metadata.body"
          :editable="false"
        />
      </div>

      <div v-if="item.groups.length > 0" class="my-1">
        <span>
          <strong>{{
            $t('notification-center.notif-page-user-group-labels')
          }}</strong>
        </span>
        <div class="row ml-1">
          <span
            class="text-grey mr-4"
            v-for="group in item.groups"
            :key="group.id"
          >
            <font-awesome-icon :icon="['fad', 'users']"></font-awesome-icon>
            {{ group.name }}
          </span>
        </div>
      </div>

      <div v-if="item.metadata.publicationStart" class="my-1">
        <span>
          <strong>{{
            $t('notification-center.notif-page-publication-start')
          }}</strong>
        </span>
        <div class="row ml-1 publication-date">
          {{
            $t('notification-center.notif-page-publication-date', {
              date: formatTimestamp(
                new Date(item.metadata.publicationStart.date).getTime(),
              ).date,
              hour: formatTimestamp(
                new Date(item.metadata.publicationStart.date).getTime(),
              ).hour,
            })
          }}
        </div>
      </div>

      <div v-if="item.metadata.publicationEnd" class="my-1">
        <span>
          <strong>{{
            $t('notification-center.notif-page-publication-end')
          }}</strong>
        </span>
        <div class="row ml-1 publication-date">
          {{
            $t('notification-center.notif-page-publication-date', {
              date: formatTimestamp(
                new Date(item.metadata.publicationEnd.date).getTime(),
              ).date,
              hour: formatTimestamp(
                new Date(item.metadata.publicationEnd.date).getTime(),
              ).hour,
            })
          }}
        </div>
      </div>

      <span>
        <strong>{{ $t('notification-center.notif-page-reading-rate') }}</strong>
      </span>

      <div class="row" v-if="item.metadata.urgent">
        <div class="col-10 mt-1">
          <small>{{
            $t('notification-center.main.row.urgent-progress')
          }}</small>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-10 mt-1">
          <md-progress
            :percentage="Math.round(item.agentReadersPercentage * 100) / 100"
          ></md-progress>
        </div>
        <div class="col-2">
          <span class="details-button" @click="$emit('select-details', item)">
            <font-awesome-icon
              :icon="['fa', 'info-square']"
              class="mr-1"
            ></font-awesome-icon
            >{{ $t('notification-center.notif-page-detail-read') }}
          </span>
        </div>
      </div>

      <hr class="col-12 my-2" />
      <div class="row mx-0 mt-2 justify-content-end align-items-center">
        <p class="mb-0 mr-2">
          <small>{{
            $t('notification-center.notif-page-visible-switch')
          }}</small>
        </p>
        <el-switch
          class="mr-2"
          :value="item.metadata.isVisible"
          @change="
            $emit('update-notification', {
              notificationId: item.id,
              metadata: { isVisible: !item.metadata.isVisible },
            })
          "
        ></el-switch>
        <el-popconfirm
          class="indicator-popconfirm"
          :confirm-button-text="$t('notification-center.actions.confirm')"
          confirm-button-type="plain"
          :cancel-button-text="$t('notification-center.actions.cancel')"
          icon="el-icon-info"
          icon-color="red"
          :title="$t('notification-center.actions.delete')"
          @confirm="$emit('delete-notification', item.id)"
        >
          <el-button
            slot="reference"
            class="mr-4"
            plain
            size="mini"
            type="danger"
            :loading="isDeletingAgentNotification"
          >
            {{
              $t(
                isDeletingAgentNotification
                  ? 'notification-center.actions.loading'
                  : 'notification-center.actions.delete-btn',
              )
            }}
          </el-button>
        </el-popconfirm>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters } from 'vuex';
import MaydayNotificationEditor from '@/components/Editors/MaydayNotificationEditor';
import MdProgress from 'components/MdProgress';
import formatTimestamp from '@/utils/formatTimestamp';

export default {
  name: 'NotificationCenterMainRow',
  components: {
    MaydayNotificationEditor,
    MdProgress,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      deleteLoadings: [],
      activeCollapseItems: [],
      formatTimestamp,
    };
  },
  computed: {
    ...mapGetters('notificationModule', ['isDeletingAgentNotification']),
  },
  methods: {
    getPercentageColor(percentage) {
      if (percentage < 10) return '#FD0044';
      else if (percentage < 75) return '#FCBF49';
      else return '#AFE084';
    },
    formatTitleString(title) {
      if (title.length > 59) {
        let tmp = title.substring(0, 59);
        tmp = tmp + '...';
        return tmp;
      } else return title;
    },
    applyDateParse(date) {
      let options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      let formatDate = new Date(date).toLocaleDateString(
        this.$i18n.locale,
        options,
      );
      return formatDate.charAt(0).toUpperCase() + formatDate.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.publication-date {
  color: $grey-5-mayday;
  font-size: 14px;
}

.not-visible {
  opacity: 0.5;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.urgent-icon {
  color: $red-mayday;
}
.collapse-title-text {
  font-weight: bold;
  color: $grey-4-mayday;
}
.text-blue {
  color: $blue-mayday;
}
.vertical-divider {
  border-left: 1px solid $grey-5-mayday;
  width: 1px;
}

.details-button {
  cursor: pointer;
  color: #ced4da;
}

.details-button:hover {
  color: $blue-mayday;
}

.title-col {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-col {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.notification__viewer {
  border-left: 4px solid $grey-4-mayday;
  background-color: $grey-1-mayday;
  border-radius: 2px;

  :deep() p {
    font-size: 0.9rem !important;
    margin: 4px 0 !important;
  }

  :deep() li {
    margin-bottom: 6px !important;
  }
}
</style>
