<template>
  <el-popover
    placement="bottom"
    width="300"
    trigger="click"
    popper-class="popover-container"
  >
    <!--FILTERS -->
    <div class="collapse-wrapper">
      <el-collapse accordion @change="resetSearch">
        <el-collapse-item v-for="filter in currentFilters" :key="filter.key">
          <!-- TITLE -->
          <template slot="title">
            <div class="collapse-title">
              <font-awesome-icon
                v-if="filter.icon"
                :icon="filter.icon"
                class="filter-icon"
              />
              {{ filter.label }}
              <el-badge
                :hidden="getFilterCount(filter) < 1"
                :value="getFilterCount(filter)"
                class="collapse-badge"
              ></el-badge>
            </div>
          </template>
          <div v-if="filter.grouped">
            <div v-if="filter.searchable">
              <el-input
                :placeholder="$t('task-view.filter.search')"
                class="mr-2 search"
                @input="handleSearch(filter.key, $event)"
                @clear="resetSearch"
                :value="search[1]"
                clearable
              >
                <i slot="prefix" class="el-input__icon" v-if="!search[1]"
                  ><font-awesome-icon
                    :icon="['fal', 'search']"
                    class="search-icon"
                  ></font-awesome-icon
                ></i>
              </el-input>
              <p
                class="empty-message"
                v-if="search[1] && filter.options.length === 0"
              >
                {{ $t('task-view.filter.empty-search', { query: search[1] }) }}
              </p>
            </div>
            <div v-for="(group, i) in filter.options" :key="i">
              <div class="option-group-title">
                <ColoredIcon
                  class="option-icon"
                  :icon="group.icon"
                  :color="group.color"
                />
                {{ group.label }}
              </div>
              <div
                v-for="option in group.options"
                :key="option.value"
                class="checkbox-wrapper"
              >
                <label>
                  <input
                    :checked="filterValue(group.path, option.value)"
                    :type="filter.input || 'checkbox'"
                    @change="handleChangeFilter(option.value, group)"
                  />
                  <div>
                    <div
                      v-if="group.dataType === 'user'"
                      :style="colorStyle(option.label)"
                      class="initials-wrapper"
                    >
                      <div class="initials">
                        {{ getInitials(option.label) }}
                      </div>
                    </div>
                    <span> {{ option.label }}</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <!-- NOT GROUPED -->
          <div v-if="!filter.grouped && filter.input !== 'date'">
            <div v-if="filter.searchable">
              <el-input
                :placeholder="$t('task-view.filter.search')"
                class="mr-2 search"
                @input="handleSearch(filter.key, $event)"
                @clear="resetSearch"
                :value="search[1]"
                clearable
              >
                <i slot="prefix" class="el-input__icon" v-if="!search[1]"
                  ><font-awesome-icon
                    :icon="['fal', 'search']"
                    class="search-icon"
                  ></font-awesome-icon
                ></i>
              </el-input>
              <p
                class="empty-message"
                v-if="search[1] && filter.options.length === 0"
              >
                {{ $t('task-view.filter.empty-search', { query: search[1] }) }}
              </p>
            </div>
            <div
              v-for="option in filter.options"
              :key="option.value"
              class="checkbox-wrapper"
            >
              <label v-if="filter.input !== 'switch'">
                <input
                  :checked="filterValue(filter.path, option.value)"
                  :type="filter.input"
                  @change="handleChangeFilter(option.value, filter)"
                />
                <div>
                  <div
                    v-if="filter.dataType === 'user'"
                    :style="colorStyle(option.label)"
                    class="initials-wrapper"
                  >
                    <div class="initials">
                      {{ getInitials(option.label) }}
                    </div>
                  </div>
                  <span> {{ option.label }}</span>
                </div>
              </label>
              <!-- BOOLEAN -->
              <el-switch
                v-else
                :inactive-text="option.label"
                :value="filterValue(filter.path, true)"
                @change="handleChangeFilter($event, filter)"
              />
            </div>
          </div>
          <!-- DATE PICKER -->
          <div v-if="filter.input === 'date'" class="calendar-container">
            <el-date-picker
              v-model="filter.values"
              type="daterange"
              range-separator="-"
              :start-placeholder="$t('task-view.filter.start-date')"
              :end-placeholder="$t('task-view.filter.end-date')"
              @change="handleChangeFilter($event, filter)"
              data-test-id="date-picker"
              :clearable="false"
              :picker-options="pickerOptions"
              value-format="timestamp"
            />
            <font-awesome-icon
              :icon="['fal', 'window-close']"
              class="calendar-clear-button"
              v-if="filter.values.length > 0"
              @click="resetCalendar(filter)"
            />
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- RESET BUTTON -->
    <el-button
      :disabled="filtersTotal < 1 && search[1] === ''"
      class="reset-button"
      @click="handleReset"
      data-test-id="reset-button"
    >
      <font-awesome-icon :icon="['fal', 'sync']" />
      <span class="reset-button-label">{{ $t('task-view.filter.reset') }}</span>
    </el-button>
    <!-- OPEN BUTTON -->
    <el-button
      ref="openFiltersButton"
      class="filters-open-link"
      slot="reference"
      :disabled="loading"
    >
      <font-awesome-icon
        :icon="['fal', 'sliders-v']"
        class="dashboard-filters-icon"
      />
      {{ $t('task-view.filter.cta') }}
      <el-badge :hidden="filtersTotal < 1" :value="filtersTotal" />
    </el-button>
  </el-popover>
</template>

<script>
import ColoredIcon from '@/components/Icons/ColoredIcon.vue';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'filters-popover',
  components: { ColoredIcon },

  inheritAttrs: false,

  props: {
    filters: {
      type: Object,
      required: true,
    },
    selectedFilters: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      currentFilters: {},
      search: ['', ''],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },

  async created() {
    await this.parseFilters();
  },

  watch: {
    filters: {
      handler() {
        this.parseFilters();
      },
      deep: true,
    },
    search(newSearch, oldSearch) {
      if (newSearch[1].length > 2) {
        if (this.currentFilters[newSearch[0]].grouped) {
          let newOptions = this.currentFilters[newSearch[0]].options.map(
            (group) => {
              return {
                ...group,
                options: group.options.filter((option) =>
                  option.label
                    .toLowerCase()
                    .includes(newSearch[1].toLowerCase()),
                ),
              };
            },
          );
          newOptions = newOptions.filter((group) => group.options.length);
          this.currentFilters[newSearch[0]].options = newOptions;
        } else {
          this.currentFilters[newSearch[0]].options = this.currentFilters[
            newSearch[0]
          ].options.filter((option) => {
            return option.label
              .toLowerCase()
              .includes(this.search[1].toLowerCase());
          });
        }
      } else if (oldSearch && oldSearch[1].length > 2) {
        this.currentFilters[oldSearch[0]].options =
          this.filters[oldSearch[0]].options;
      }
    },
  },

  methods: {
    handleChangeFilter(values, filter) {
      if (filter.input === 'date') {
        if (!values[0] || !values[1]) values = undefined;
        else if (values[0] === values[1]) values[1] += 86399999;
      }
      if (filter.type === 'Array' && this.selectedFilters[filter.path]) {
        if (this.selectedFilters[filter.path].includes(values)) {
          values = this.selectedFilters[filter.path].filter(
            (value) => value !== values,
          );
        } else {
          values = [...this.selectedFilters[filter.path], values];
        }
      }
      this.$emit('update-filter', {
        path: filter.path || filter.key,
        values: values,
      });
    },
    handleSearch(key, $event) {
      this.search = [key, $event];
    },
    handleReset() {
      this.resetSearch();
      this.$emit('empty-filters-search');
    },
    async parseFilters() {
      this.currentFilters = cloneDeep(this.filters);
      Object.keys(this.currentFilters).forEach((key) => {
        this.currentFilters[key].options = this.currentFilters[
          key
        ].options.sort((a, b) => {
          if (!a.label || !b.label) return 0;
          return a.label.localeCompare(b.label);
        });
        if (this.currentFilters[key].grouped) {
          this.currentFilters[key].options.forEach((group) => {
            group.options = group.options.sort((a, b) => {
              if (!a.label || !b.label) return 0;
              return a.label.localeCompare(b.label);
            });
          });
        }
      });
    },
    resetSearch() {
      if (this.search[1] !== '') {
        this.currentFilters[this.search[0]].options =
          this.filters[this.search[0]].options;
        this.search = ['', ''];
      }
    },
    resetCalendar(filter) {
      this.currentFilters[filter.key].values = [];
      this.handleChangeFilter([], filter);
    },
    getFilterCount(filter) {
      if (!filter.grouped) {
        if (!this.selectedFilters[filter.path]) return 0;
        if (Array.isArray(this.selectedFilters[filter.path]))
          return this.selectedFilters[filter.path].length;
        return 1;
      } else {
        return filter.options.reduce((acc, option) => {
          if (!this.selectedFilters[option.path]) return acc;
          return acc + this.selectedFilters[option.path].length;
        }, 0);
      }
    },
    colorStyle(username) {
      return {
        background:
          username !== null && username !== '?'
            ? this.userColor(username, 70, 50)
            : '#0081f9',
        color: 'white',
      };
    },
    userColor(str, s, l) {
      let hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      const h = hash % 360;
      return `hsl(${h},${s}%,${l}%)`;
    },
    getInitials(username) {
      if (!username) return '';

      const fullName = username.split(' ');
      let initials;
      if (fullName.length >= 2)
        initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      else initials = fullName.shift().charAt(0);
      return initials.toUpperCase();
    },
  },
  computed: {
    filtersTotal() {
      return Object.keys(this.selectedFilters).reduce((acc, filter) => {
        if (Array.isArray(this.selectedFilters[filter]))
          return acc + this.selectedFilters[filter].length;
        return acc + this.selectedFilters[filter] ? 1 : 0;
      }, 0);
    },
    filterValue() {
      return (filter, value) => {
        if (this.selectedFilters[filter] === undefined) return false;
        if (Array.isArray(this.selectedFilters[filter])) {
          return this.selectedFilters[filter].includes(value.toString());
        }
        return this.selectedFilters[filter] === value;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.popover-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filters-open-link {
  color: $blue-mayday;
  height: 34px;
  display: inline-flex;
  gap: 4px;
  padding: 10px 15px;
  align-items: center;
  justify-content: center;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 4px;
  border: 1px solid $blue-mayday;
  transition: min-width 0.2s ease-in-out;
  font-size: 14px;

  .dashboard-filters-icon {
    margin-right: 4px;
  }

  &:hover {
    background-color: $blue-mayday;
    color: white;
    cursor: pointer;
  }

  &:disabled {
    background-color: rgba($grey-8-mayday, 0.1);
    color: $grey-8-mayday;
    border: none;
    cursor: progress;
  }

  :deep() .el-badge__content {
    background-color: white;
    color: $blue-mayday;
    border-color: $blue-mayday;
    display: flex;
    top: 0;
    align-items: center;
    justify-content: center;
  }
}

.option-group-title {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
}

.collapse-wrapper :deep() {
  .el-collapse {
    border: none;
  }
  .el-collapse-item__arrow {
    color: black;
  }
  .el-collapse-item__header {
    border: none;
    padding-left: 8px;

    .collapse-title {
      font-family: 'Lato', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.02em;
      align-items: center;
      display: inline-flex;
      gap: 8px;
    }

    .collapse-badge .el-badge__content {
      background-color: $blue-mayday;
      color: white;
      display: flex;
      top: 0;
      align-items: center;
      justify-content: center;
    }
  }

  .el-collapse-item__content {
    float: right;
    width: 100%;
    padding-left: 12px;
    padding-bottom: 0px !important;
  }

  .el-collapse-item__wrap {
    border: none;
  }

  .el-switch {
    width: 100%;
    justify-content: space-between;
    .el-switch__label.is-active {
      color: $grey-8-mayday;
    }
    .el-switch__label {
      color: $blue-mayday;
    }
  }
}

.initials-wrapper {
  width: 20px;
  height: 20px;
  flex: none;
  margin-right: 4px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.checkbox-wrapper {
  display: block;
  width: 100%;
  border-bottom: 1px solid $grey-2-mayday;
  &:last-of-type {
    border-bottom: none;
  }
}

.reset-button {
  color: $grey-7-mayday;
  border: none;
  float: right;
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  padding-right: 12px;
  padding-left: 12px;
  letter-spacing: 0.02em;

  .reset-button-label {
    margin-left: 8px;
  }

  &:disabled {
    color: $grey-4-mayday;
    cursor: not-allowed;
  }
}

.filter-icon {
  width: 16px;
}

.search-icon:hover {
  cursor: pointer;
  color: $blue-mayday;
}

.search {
  height: 34px;
  width: 100%;
  margin-bottom: 8px;
  :deep() .el-input__inner {
    height: 100%;
  }
}

.empty-message {
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: $grey-7-mayday;
  margin-top: 8px;
  margin-bottom: 8px;
}

:deep() .el-input__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-container {
  position: relative;
  :deep() .el-date-editor {
    width: 100%;
  }
  .calendar-clear-button {
    position: absolute;
    right: 8px;
    top: 10px;
    width: 20px;
    height: 20px;
    color: $grey-7-mayday;
    z-index: 10;
    cursor: pointer;
    &:hover {
      color: $blue-mayday;
    }
  }
}

label {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 8px;
  cursor: pointer;
  margin: 0;
  &:hover {
    background-color: $grey-1-mayday;
  }
  input:checked + span {
    color: $blue-mayday;
  }

  :last-child {
    display: flex;
    align-items: center;
  }
}
</style>
