<template>
  <el-card shadow="never" class="mb-3">
    <!-- SECTION TITLE -->
    <div class="d-flex">
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
      ></el-checkbox>
      <p class="title mb-0 ml-2">
        <small>{{ title }}</small>
      </p>
    </div>
    <!-- SECTION CHECKBOXES -->
    <el-checkbox-group
      class="mt-2"
      v-model="localCheckList"
      @change="updateCheckList"
    >
      <el-checkbox
        class="checkbox-item"
        v-for="item in groups"
        :key="item.id"
        :label="item.id"
      >
        <small>{{ item.name }}</small>
      </el-checkbox>
    </el-checkbox-group>
  </el-card>
</template>

<script>
export default {
  name: 'NotificationCenterFilterItem',
  props: {
    title: {
      type: String,
      default: null,
    },
    groups: {
      type: Array,
      default: () => [],
    },
    checkList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkAll: false,
      localCheckList: [],
    };
  },
  computed: {
    isIndeterminate() {
      return this.localCheckList.length > 0 && !this.checkAll;
    },
  },
  methods: {
    updateCheckList(selectedGroupIds) {
      const selectedGroups = selectedGroupIds.map((groupId) =>
        this.groups.find((g) => g.id === groupId),
      );
      const notCheckedArray = this.groups.reduce((acc, group) => {
        if (!selectedGroupIds.includes(group.id)) acc.push(group);
        return acc;
      }, []);
      this.checkAll = notCheckedArray.length === 0;
      this.$emit('update', selectedGroups);
    },

    handleCheckAllChange(val) {
      this.localCheckList = val ? this.groups.map((group) => group.id) : [];
      this.checkAll = val;
      const selectedGroups = this.localCheckList.map((groupId) =>
        this.groups.find((g) => g.id === groupId),
      );
      this.$emit('update', selectedGroups);
    },
  },
  watch: {
    checkList: {
      handler(newValue) {
        if (!newValue || !newValue.length) return;
        const selectedGroupIds = newValue.map((val) => val.id);
        const notCheckedArray = this.groups.reduce((acc, group) => {
          if (!selectedGroupIds.includes(group.id)) acc.push(group);
          return acc;
        }, []);
        this.checkAll = notCheckedArray.length === 0;
        this.localCheckList = selectedGroupIds;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-item {
  word-break: break-all;
}

title {
  padding-left: 10px;
  color: $grey-5-mayday;
}

.menu-filter-item-collapse {
  max-width: 250px;
}
.checkbox-item {
  width: 100%;
}
</style>
