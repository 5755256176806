<template>
  <div>
    <div
      class="d-flex flex-column comment"
      :class="comment.isInternal ? 'internal-comment' : 'external-comment'"
      v-if="!commentDeletion"
      v-loading="commentLoading"
    >
      <div class="d-flex flex-row align-items-center">
        <user-avatar
          :username="author.username"
          size="xsmall"
          class="avatar"
        ></user-avatar>
        <div
          class="d-flex flex-row mb-0 align-items-center w-100 justify-content-between"
        >
          <div
            class="d-flex flex-row mb-0 align-items-center w-100 comment-title"
          >
            <div class="author">
              {{ author.username }}
            </div>
            <div v-if="isEdited" class="ml-1 comment-is-edited">
              ({{ $t('task-view.task-focus-modal.edited') }})
            </div>
            <time-ago
              :date="comment.createdAt"
              :reset="timeAgoReset"
              class="ml-2"
            ></time-ago>
          </div>
          <div
            class="task-comment-action d-flex flex-row"
            :class="{ 'task-comment-action__is-actionning': commentEdition }"
            v-if="displayActions"
          >
            <AddAttachment
              v-if="commentEdition"
              :attachment-loading="attachmentLoading"
              class="thread-action icon-action-wrapper"
              :disabled="fileInputDisable"
              :class="
                attachmentLoading || fileInputDisable
                  ? 'icon-action-wrapper__disable'
                  : 'icon-action-wrapper__enable'
              "
              @disable-click="
                showDisableFileUpload(
                  'task-view.task-focus-modal.upload-error.disable-file-upload',
                )
              "
              @new-attachment="
                handleFileChange($event), (attachmentIsChanged = true)
              "
            >
              <font-awesome-icon
                slot="slot-button"
                class="icon-action"
                :icon="['fal', 'paperclip']"
              />
            </AddAttachment>
            <div
              class="icon-action-wrapper"
              v-if="!editionLoading"
              @click="handleLeftButton"
              :class="
                leftDisable
                  ? 'icon-action-wrapper__disable'
                  : 'icon-action-wrapper__enable'
              "
            >
              <font-awesome-icon
                class="icon-action"
                :icon="['fal', leftIcon]"
              ></font-awesome-icon>
            </div>
            <div
              class="icon-action-wrapper"
              @click="handleRightButton"
              :class="
                rightDisable
                  ? 'icon-action-wrapper__disable'
                  : 'icon-action-wrapper__enable'
              "
            >
              <font-awesome-icon
                class="icon-action"
                :icon="['fal', rightIcon]"
                :spin="editionLoading"
              ></font-awesome-icon>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column justify-content-start comment-content py-1 pb-2"
        :class="{ 'no-border': noBorder() }"
      >
        <MessageEditor
          :key="`${comment.id}-edit`"
          v-if="commentEdition"
          :body="comment.comment"
          :editable="true"
          :autofocus="true"
          @input="updatedComment = $event"
        />
        <MessageEditor
          :key="`${comment.id}-read`"
          v-if="!commentEdition && comment.comment"
          :body="comment.comment"
          :editable="false"
        />
        <div class="d-flex flex-row justify-content-between w-100">
          <AttachmentList
            v-if="hasAttachments"
            :attachments="attachments"
            :isInput="commentEdition"
            class="mt-1 pb-1 w-100"
            :display-disable-file-upload="displayDisableFileUpload"
            :error-text="errorText"
            @delete-attachment="
              handleDeleteAttachment($event), (attachmentIsChanged = true)
            "
            @disable-click="showDisableFileUpload()"
          ></AttachmentList>
        </div>
      </div>
    </div>
    <div v-else class="internal-comment d-flex flex-column text-center">
      <div
        class="d-flex flex-row align-items-center justify-content-center task-comment-deleted"
      >
        <font-awesome-icon
          :icon="['fal', 'check']"
          class="mr-2"
        ></font-awesome-icon>
        {{ $t('task-view.task-focus-modal.deleted') }}
      </div>

      <el-button type="text" @click="cancelDeletion" class="py-1">
        {{ $t('task-view.task-focus-modal.cancel') }}
      </el-button>
    </div>
    <el-divider
      direction="vertical"
      v-if="comment.isInternal && !isLast"
    ></el-divider>
  </div>
</template>

<script>
import UserAvatar from '../../../components/UserAvatar.vue';
import TimeAgo from '@/components/Commons/TimeAgo.vue';
import AttachmentList from '@/components/Attachments/AttachmentList';
import AddAttachment from '@/components/Attachments/AddAttachment';
import AttachmentsMixin from '@/mixins/AttachmentsMixin';
import MessageEditor from '@/components/Editors/MessageEditor.vue';
import resolveFilesNames from '@/utils/resolveFilesNames';
import { parseMentions } from 'utils/parseMentions';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'task-focus-thread-item',
  components: {
    UserAvatar,
    TimeAgo,
    AttachmentList,
    AddAttachment,
    MessageEditor,
  },
  mixins: [AttachmentsMixin()],
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    timeAgoReset: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      commentEdition: false,
      editionLoading: false,
      commentDeletion: false,
      commentLoading: false,
      updatedComment: '',
      attachmentIsChanged: false,
    };
  },
  created() {
    this.attachments = this.comment.attachments || [];
    this.updatedComment = this.comment.comment;
  },
  beforeDestroy() {
    if (this.commentDeletion) this.flushCommentDeletion();
  },
  computed: {
    hasAttachments() {
      return (
        (this.comment.attachments && this.comment.attachments.length) ||
        (this.attachments && this.attachments.length)
      );
    },

    leftIcon() {
      return this.commentEdition ? 'check' : 'pen';
    },
    rightIcon() {
      return this.commentEdition
        ? this.editionLoading
          ? 'spinner-third'
          : 'times'
        : 'trash';
    },
    leftDisable() {
      if (this.commentEdition)
        return !this.bodyIsChanged && !this.attachmentIsChanged;
      return false;
    },
    rightDisable() {
      return false;
    },
    bodyIsChanged() {
      return (
        this.updatedComment !== '' &&
        this.updatedComment !== this.comment.comment
      );
    },
    isMine() {
      return this.userId === this.author.id;
    },
    displayActions() {
      return this.isMine;
    },
    isEdited() {
      return (
        this.comment.updatedAt &&
        this.comment.createdAt !== this.comment.updatedAt
      );
    },
    author() {
      const commentAuthor = this.comment.author;
      return commentAuthor && commentAuthor.id && commentAuthor.username
        ? commentAuthor
        : { id: '', username: this.$t('knowledge.contributors.deleted-user') };
    },
    ...mapGetters(['userId']),
  },
  methods: {
    noBorder() {
      return this.isLast && !this.isEditing;
    },
    flushCommentDeletion() {
      this.$emit('confirm-delete');
    },
    handleRightButton() {
      if (!this.commentEdition) {
        this.commentLoading = true;
        setTimeout(() => {
          this.commentLoading = false;
          this.commentDeletion = true;
        }, 2000);
        this.$emit('init-delete', { id: this.comment.id });
        setTimeout(this.flushCommentDeletion, 5000);
      } else {
        this.commentEdition = false;
      }
    },
    async handleLeftButton() {
      if (!this.commentEdition) {
        this.commentEdition = true;
      } else if (!this.leftDisable) {
        this.editionLoading = true;
        await this.updateTaskComment({
          id: this.comment.id,
          comment: this.updatedComment,
          attachments: this.attachments.map((at) => at.url),
          mentions: parseMentions(this.updatedComment),
        });
        this.editionLoading = false;
        this.commentEdition = false;
      }
    },
    cancelDeletion() {
      this.$emit('cancel-delete');
      this.commentDeletion = false;
    },
    ...mapActions('taskModule', ['updateTaskComment']),
  },
  watch: {
    commentEdition(newValue) {
      if (newValue) {
        this.attachments = resolveFilesNames(this.comment.attachments);
      } else {
        this.attachments = this.comment.attachments;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.author {
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: center;
}

.avatar {
  width: 22px !important;
  height: 22px !important;
}

.mayday-feedback-editor {
  padding: 0px;
  :deep() .velmld-parent {
    position: unset !important;
  }

  :deep() .mayday-editor {
    padding: 0px;
  }
}

.comment {
  padding-left: 17px;
}

.internal-comment {
  background-color: $grey-2-mayday;
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  :deep() .attachment {
    border: 1px solid $grey-4-mayday;
  }
}

.comment-title {
  height: 22px;
  margin-left: 7px;
}

.comment-is-edited {
  color: $grey-6-mayday;
  font-size: 10px;
  font-weight: 500;
}

.comment-content {
  width: calc(100% - 30px);
  margin-left: 10px;
  padding-left: 15px;
  border-left: 2px solid $grey-2-mayday;
}

:deep() .el-divider--vertical {
  width: 2px;
  color: $grey-2-mayday;
  background-color: $grey-2-mayday;
  height: 18px;
  margin-left: 27px;
  display: block;
}

.no-border {
  border-left: none;
  padding-left: 17px;
}

.comment:hover .icon-action-wrapper {
  display: flex;
}

.task-comment-action {
  gap: 4px;
  margin-right: 8px;
}

.task-comment-action__is-actionning {
  .icon-action-wrapper {
    display: flex;
  }
}

.icon-action-wrapper {
  display: none;
  width: 22px;
  height: 22px;
  padding: 5px;
  border-radius: 4px;
}

.internal-comment {
  .icon-action-wrapper {
    &__disable {
      cursor: not-allowed;
      background-color: $grey-2-mayday;
    }
    &__enable {
      cursor: pointer;
      background-color: white;
    }
  }
}

.external-comment {
  .icon-action-wrapper {
    &__disable {
      cursor: not-allowed;
      background-color: $grey-2-mayday;
    }
    &__enable {
      cursor: pointer;
      background-color: white;
    }
  }
}

.icon-action-wrapper__enable:hover {
  .icon-action {
    color: $blue-mayday;
  }
}
.icon-action-wrapper__disable:hover {
  .icon-action {
    color: inherit;
  }
}

.icon-action {
  width: 100%;
  height: 100%;
}

.task-comment-deleted {
  font-size: 14px;
  font-weight: 700;
}
</style>
