<template>
  <!-- MENU -->
  <el-tabs v-model="activeTab" @tab-click="handleTabClick">
    <el-tab-pane
      v-if="features.includes('general')"
      class="settings-panel"
      :label="
        $t(
          'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.tabs.general',
        )
      "
      name="1"
    >
      <component
        v-if="generalTabComponent"
        :is="generalTabComponent"
        :currentPluginEntities="currentPluginEntities"
        :config="config"
        :errors="errors"
        @update-plugin="$emit('update-plugin', $event)"
        @add-plugin="$emit('add-plugin', $event)"
        @delete-plugin="$emit('delete-plugin', $event)"
      />
      <PluginAdvancedSettingsOptions
        v-else
        :currentPlugin="currentPluginEntities[0]"
        :index="0"
        :errors="errors.length ? errors[0] : {}"
        :config="config"
        @update-plugin="$emit('update-plugin', { index: 0, ...$event })"
      />
    </el-tab-pane>
    <el-tab-pane
      v-if="features.includes('activation')"
      class="settings-panel"
      :label="
        $t(
          'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.tabs.activation',
        )
      "
      name="3"
    >
      <div
        v-for="integration in integrations"
        :key="integration.name"
        class="item d-flex justify-between p-3"
      >
        <PluginActivationSettings
          :integration="integration"
          :pluginTranslationKey="pluginTranslationKey"
          :isPreferenceActive="isPreferenceActive"
        />
      </div>
    </el-tab-pane>
    <el-tab-pane
      v-if="features.includes('access')"
      class="settings-panel"
      :label="
        $t(
          'settings-layout.menu-data.plugins.advanced-settings.drawer.menu.tabs.access',
        )
      "
      name="4"
    >
      <SmartActionAccessSettings
        v-if="this.pluginType === 'SmartActionEntity'"
        :currentPluginEntities="currentPluginEntities"
        @update-access-restriction="$emit('update-access-restriction', $event)"
      />
      <PluginAccessSettings
        v-else
        :currentPlugin="currentPluginEntities[0]"
        @update-access-restriction="$emit('update-access-restriction', $event)"
      />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { defineComponent } from 'vue';
import PluginAdvancedSettingsOptions from './PluginAdvancedSettingsOptions.vue';
import SmartActionsList from './SmartActionsList.vue';
import TextEllipsis from 'components/TextEllipsis.vue';
import PluginActivationSettings from './PluginActivationSettings.vue';
import PluginAccessSettings from './PluginAccessSettings.vue';
import SmartActionAccessSettings from './SmartActionAccessSettings.vue';

export default defineComponent({
  name: 'plugin-advanced-settings-drawer-main',
  components: {
    PluginAdvancedSettingsOptions,
    TextEllipsis,
    SmartActionsList,
    PluginActivationSettings,
    PluginAccessSettings,
    SmartActionAccessSettings,
  },
  props: {
    currentPluginEntities: {
      type: Array,
      required: true,
    },
    features: {
      type: Array,
    },
    config: {
      type: Object,
    },
    isPreferenceActive: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: [],
    },
    pluginType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeTab: this.initFocusedTab(),
      integrations: null,
    };
  },
  computed: {
    pluginTranslationKey() {
      switch (this.pluginType) {
        case 'EmbeddedIframeEntity':
          return 'embedded-iframe';
        case 'TrendingContentEntity':
          return 'trending-content';
        case 'NewContentEntity':
          return 'new-content';
        case 'SmartActionEntity':
          return 'smart-actions';
        default:
          return '';
      }
    },
    generalTabComponent() {
      switch (this.pluginType) {
        case 'SmartActionEntity':
          return 'smart-actions-list';
        default:
          return;
      }
    },
  },
  methods: {
    initFocusedTab() {
      switch (this.features[0]) {
        case 'activation':
          return '3';
        case 'general':
        default:
          return '1';
      }
    },
    getIntegrations() {
      this.currentPluginEntities[0].integrations;
    },
    handleTabClick(tab) {
      if (tab._props.name && tab._props.name === '4') {
        this.$emit('hide-save-button', true);
      } else this.$emit('hide-save-button', false);
    },
  },
  watch: {
    currentPluginEntities: {
      handler: function (updatedPluginEntities) {
        this.integrations = updatedPluginEntities[0].integrations;
      },
      deep: true,
      immediate: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.settings-panel {
  max-height: 75vh;
  overflow: auto;
}

:deep() {
  .code-editor .el-textarea__inner {
    background-color: $grey-1-mayday;
    border: none;
    padding: 1rem;
    resize: none;
  }
}

.action-list-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.add-plugin-entity-button {
  padding: 10px;
  border: none;
  cursor: pointer;
}

.add-plugin-entity-button:hover {
  background-color: $grey-1-mayday;
}

.delete-plugin-entity-button {
  border: none;
  cursor: pointer;
}

.item {
  background-color: white;
  border: 1px $grey-4-mayday solid;
  border-radius: 4px;
  padding: 14px;
  margin-bottom: 8px;
  font-size: 14px;

  &__title {
    font-size: 18px;
    font-weight: 600;
  }
  &__description {
    color: $grey-6-mayday;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 14px;
    max-width: 390px;
  }

  &__status {
    display: flex;
    align-items: center;
  }

  &__icon {
    background: none;
    margin-right: 4px;
    color: $grey-6-mayday;
  }
}
.active {
  background: none !important;
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
}

.collapse-enter-active,
.collapse-leave-active {
  transition: all 0.5s ease;
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 100%;
}
.collapse-enter,
.collapse-leave-to {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
  overflow: hidden;
}

.delete-btn {
  width: 20px;
  cursor: pointer;
}
</style>
