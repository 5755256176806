<template>
  <div>
    <div v-if="emoji">
      {{ emojies[language] }}
    </div>
    <img v-else :src="flagPath" />
  </div>
</template>
<script>
const countries = [
  'all',
  'default',
  'none',
  'fr-fr',
  'en-gb',
  'en-us',
  'es-es',
  'it-it',
  'pt-pt',
  'de-de',
  'pl-pl',
  'nl-nl',
];
const emojies = {
  all: '🌍',
  default: '🌍',
  none: '',
  'fr-fr': '🇫🇷',
  'en-gb': '🇬🇧',
  'en-us': '🇺🇸',
  'es-es': '🇪🇸',
  'it-it': '🇮🇹',
  'pt-pt': '🇵🇹',
  'de-de': '🇩🇪',
  'pl-pl': '🇵🇱',
  'nl-nl': '🇳🇱',
};
export default {
  name: 'language-flag',
  data() {
    return {
      emojies,
    };
  },
  props: {
    language: {
      type: String,
      default: 'fr-fr',
      validator: (val) => countries.includes(val),
    },
    emoji: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    flagPath() {
      return require(`../assets/flags/${this.language}.png`);
    },
    flagEmoji() {
      return this.emojies[this.language];
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 18px;
  height: 16px;
}
</style>
