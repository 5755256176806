<template>
  <div class="toast">
    <div class="toast__header">
      <div class="toast__header__title">
        <font-awesome-icon :icon="['fal', 'globe-stand']" />
        {{ $t('translation.loading-toast.title') }}
        <AiBadge :icon="false"></AiBadge>
      </div>
      <font-awesome-icon
        v-if="!isLoadingState"
        @click="$emit('quit-translation-toast')"
        class="close"
        :icon="['fal', 'times']"
      />
    </div>
    <div class="toast__body" :class="`toast__body__${status}`">
      <div v-if="isLoadingState" class="toast__body__pending">
        <font-awesome-icon
          class="loading-icon"
          :icon="['fal', 'spinner-third']"
          spin
        />
        {{
          $t('translation.loading-toast.pending', {
            targetLanguage: $t(`knowledge.languages.${localTargetLanguage}`),
          })
        }}
      </div>
      <div v-if="status === 'done'" class="toast__body__done">
        {{
          $t('translation.loading-toast.done', {
            targetLanguage: $t(`knowledge.languages.${localTargetLanguage}`),
          })
        }}
        <div class="button-wrapper">
          <div class="button" @click="handleGoToNewlyCreatedVersion()">
            {{ $t('translation.loading-toast.done-button') }}
          </div>
        </div>
      </div>
      <div v-if="status === 'failed'" class="toast__body__failed">
        <div class="failed-title">
          {{ $t('translation.loading-toast.failed-title') }}
        </div>
        <div class="failed-description">
          {{
            $t('translation.loading-toast.failed-description', {
              targetLanguage: $t(`knowledge.languages.${localTargetLanguage}`),
            })
          }}
        </div>
        <div class="button-wrapper">
          <div
            class="button"
            @click="
              $emit('translation-try-again', {
                sourceLanguage: sourceLanguage,
                targetLanguage: localTargetLanguage,
              })
            "
          >
            {{ $t('translation.loading-toast.failed-button') }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="toast__footer"
      v-if="status === 'pending' || status === 'loading'"
    >
      <div class="pending-button" @click="$emit('quit-translation-toast')">
        {{ $t('translation.loading-toast.pending-button') }}
      </div>
    </div>
  </div>
</template>

<script>
import AiBadge from '@/components/AI/AiBadge.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'set-language-toast',
  components: {
    AiBadge,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    targetLanguage: {
      type: String,
      required: true,
    },
    sourceLanguage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localTargetLanguage: '',
    };
  },
  mounted() {
    this.localTargetLanguage = this.targetLanguage;
    document.addEventListener('keydown', this._keyListener, true);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener, true);
  },
  computed: {
    isLoadingState() {
      return this.status === 'pending' || this.status === 'loading';
    },
    ...mapGetters('knowledgeModule', ['editingLanguage']),
  },
  methods: {
    _keyListener(e) {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        this.$emit('quit-translation-toast');
      }
    },
    handleGoToNewlyCreatedVersion() {
      this.$emit('quit-translation-toast');
      if (this.localTargetLanguage === this.editingLanguage) {
        this.getFocusContentDetails(true);
      } else {
        this.switchEditingLanguage(this.localTargetLanguage);
      }
    },
    ...mapActions('knowledgeModule', [
      'switchEditingLanguage',
      'getFocusContentDetails',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.toast {
  position: absolute;
  width: 380px;
  font-size: 14px;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  z-index: 999;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  color: $purple-5-mayday;
  &__header {
    padding: 0px 8px 0px 8px;
    background: $grey-2-mayday;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    height: 40px;
    &__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;
      gap: 8px;
    }
  }
  &__body {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__done {
      color: $grey-8-mayday;
      height: 80px;
      flex-direction: column;
    }
    &__pending {
      gap: 8px;
    }
    &__failed {
      height: 100px;
      flex-direction: column;
    }
  }
  &__footer {
    justify-content: end;
    padding-right: 8px;
    height: 20px;
    display: flex;
    align-items: center;
  }
}
.pending-button {
  border-radius: 4px;
  font-size: 10px;
  height: 14px;
  width: 25px;
  background-color: $purple-3-mayday;
  color: $purple-5-mayday;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: white;
    border: 1px solid $purple-5-mayday;
    cursor: pointer;
  }
}
.button {
  border-radius: 4px;
  height: 100%;
  line-height: 20px;
  background-color: $purple-5-mayday;
  cursor: pointer;
  color: white;
  padding: 4px 8px 4px 8px;
  display: flex;
  align-items: center;
}
.close {
  color: $grey-8-mayday;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.loading-icon {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.failed-title {
  color: $red-mayday;
}
.button-wrapper {
  display: flex;
  justify-content: end;
}
</style>
