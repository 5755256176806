<template>
  <div>
    <div>
      <div class="element-description">
        {{ $t('settings.integration.description') }}
      </div>
    </div>
    <el-divider />
    <div
      v-for="(integration, index) in activeIntegrations"
      :key="integration.name"
    >
      <IntegrationSettingsCard
        :label="integration.label"
        :type="integration.type"
        :logo="integration.logo"
        :active="integration.active"
        @open="openDrawer(index)"
      />
    </div>

    <IntegrationSettingsDrawer
      :show="isOpen"
      :integration="currentIntegration"
      @close="closeDrawer()"
      @update-integration="activeIntegrations = $event"
    >
      <component :is="integrationComponent" :integration="currentIntegration" />
    </IntegrationSettingsDrawer>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { INTEGRATION_SETTINGS } from './utils';

import IntegrationSettingsCard from '@/components/Settings/IntegrationSettings/IntegrationSettingsCard.vue';
import IntegrationSettingsDrawer from '@/components/Settings/IntegrationSettings/IntegrationSettingsDrawer/IntegrationSettingsDrawer.vue';

export default {
  name: 'integration-settings-page',
  components: {
    IntegrationSettingsCard,
    IntegrationSettingsDrawer,
    IntegrationSettingsZendesk: () =>
      import('./Integrations/IntegrationSettingsZendesk'),
    IntegrationSettingsChrome: () =>
      import('./Integrations/IntegrationSettingsChrome'),
    IntegrationSettingsSalesforce: () =>
      import('./Integrations/IntegrationSettingsSalesforce'),
    IntegrationSettingsIntercom: () =>
      import('./Integrations/IntegrationSettingsIntercom'),
    IntegrationSettingsIadvize: () =>
      import('./Integrations/IntegrationSettingsIadvize'),
    IntegrationSettingsNotion: () =>
      import('./Integrations/IntegrationSettingsNotion'),
    IntegrationSettingsSlack: () =>
      import('./Integrations/IntegrationSettingsSlack'),
    IntegrationSettingsMaydayWeb: () =>
      import('./Integrations/IntegrationSettingsMaydayWeb'),
    IntegrationSettingsCustom: () =>
      import('./Integrations/IntegrationSettingsCustomApis'),
  },
  computed: {
    hasMultiSalesforce() {
      return !!this.getCompanyPreference('HAS_MULTI_SALESFORCE_ACCOUNT');
    },
    hasWorkflowActivated() {
      return !!this.getCompanyPreference('WORKFLOW_ACTIVATED');
    },
    activeIntegrations: {
      get() {
        let integrations = INTEGRATION_SETTINGS;
        if (!this.hasMultiSalesforce)
          integrations = integrations.filter(
            (el) => el.value !== 'salesforce_2',
          );
        if (!this.hasWorkflowActivated)
          integrations = integrations.filter(
            (el) => el.value !== 'custom-apis',
          );
        integrations = integrations.map((integration) => {
          const defaultActive = Boolean(integration.active);
          return {
            ...integration,
            active:
              this.integrations.includes(integration.name) || defaultActive,
            disabled: defaultActive,
          };
        });
        return integrations;
      },
      set({ source, value }) {
        this.updateCompanyIntegrations({
          integration: source,
          action: value ? 'add' : 'delete',
        });
      },
    },
    currentIntegration() {
      return this.currentIndex > -1
        ? this.activeIntegrations[this.currentIndex]
        : this.defaultIntegration;
    },
    integrationComponent() {
      return this.currentIntegration.name
        ? `IntegrationSettings${this.currentIntegration.name.split(' ')[0]}`
        : null;
    },
    ...mapState(['integrations']),
    ...mapGetters(['getCompanyPreference']),
  },
  data() {
    return {
      isOpen: false,
      defaultIntegration: {
        name: '',
        label: '',
        description: '',
        type: '',
        logo: ['', ''],
        active: '',
        features: [],
        links: [],
      },
      currentIndex: -1,
    };
  },
  methods: {
    ...mapActions(['updateCompanyIntegrations']),
    openDrawer(index) {
      if (index > -1) {
        const query = {
          menu: this.activeIntegrations[index].value,
        };
        this.$router.push({
          hash: '#catalog',
          query,
        });
        this.currentIndex = index;
        this.isOpen = true;
      }
    },
    initDrawer(index) {
      if (index > -1) {
        this.currentIndex = index;
        this.isOpen = true;
      }
    },
    closeDrawer() {
      this.$router.push({
        hash: '#catalog',
        query: {},
      });
      this.isOpen = false;
      this.currentIndex = -1;
    },
    hashIntegration(query) {
      const { menu } = query;
      if (!menu) return -1;
      return this.activeIntegrations.findIndex(
        (integration) => integration.value === menu,
      );
    },
  },
  mounted() {
    const { query } = this.$route;
    this.initDrawer(this.hashIntegration(query));
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-divider--horizontal) {
  margin: 16px 0;
}
.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}
</style>
