export const taskTypeConfiguration = {
  INFORMATION: {
    icon: 'info-circle',
    classTag: 'info',
    disableOptions: [
      'group',
      'labels',
      'source',
      'linkedContent',
      'trustContent',
    ],
    displayedType:
      'task-view.task-focus-modal.details.displayed-type.information',
  },
  CONTENT_OBSOLETE: {
    icon: 'times-circle',
    classTag: 'obsolete',
    disableOptions: ['reaction', 'group', 'labels', 'source', 'answer'],
    displayedType: 'task-view.task-focus-modal.details.displayed-type.obsolete',
  },
  CONTENT_FEEDBACK: {
    icon: 'megaphone',
    classTag: 'feedback',
    disableOptions: ['trustContent'],
    displayedType:
      'task-view.task-focus-modal.details.displayed-type.content-feedback',
  },
  FEEDBACK: {
    icon: 'megaphone',
    classTag: 'feedback',
    disableOptions: ['linkedContent', 'trustContent'],
    displayedType: 'task-view.task-focus-modal.details.displayed-type.feedback',
  },
  CONTENT_FEEDBACK_ACADEMY: {
    icon: 'graduation-cap',
    classTag: 'feedback',
    disableOptions: ['trustContent'],
    displayedType:
      'task-view.task-focus-modal.details.displayed-type.academy-feedback',
  },
  FEEDBACK_ACADEMY: {
    icon: 'graduation-cap',
    classTag: 'feedback',
    disableOptions: ['linkedContent', 'trustContent'],
    displayedType:
      'task-view.task-focus-modal.details.displayed-type.academy-feedback',
  },
  CUSTOM_FEEDBACK: {
    classTag: 'feedback',
    disableOptions: ['linkedContent', 'trustContent', 'source', 'reaction'],
  },
};

const taskAttributes = [
  'type',
  'status',
  'date',
  'author',
  'group',
  'labels',
  'assignee',
  'source',
  'linkedContent',
  'reaction',
  'answer',
  'trustContent',
  'initialReceiver',
];

export const getTaskAttributes = (taskType) => {
  if (!isTaskTypeValid(taskType)) throw new Error('UNKNOWN_TASK_TYPE');
  const currentTaskTypeConfiguration = taskTypeConfiguration[taskType];
  return taskAttributes.reduce((acc, val) => {
    acc[val] = !currentTaskTypeConfiguration.disableOptions.includes(val);
    return acc;
  }, {});
};

const isTaskTypeValid = (taskType) =>
  Object.keys(taskTypeConfiguration).includes(taskType);
